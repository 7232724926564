import { useFormContext } from "react-hook-form";

import styles from "@styles/components/Form/checkbox.module.scss";
import useFormError from "@components/Form/hooks/useFormError";

/**
 * FormCheckBox component for rendering a checkbox element with its label.
 *
 * @param {React.HTMLProps<HTMLInputElement>} props - Props for the input element.
 * @returns {JSX.Element} The rendered component.
 */
const FormCheckBox = ({ ...props }) => {
	const { register } = useFormContext();
	const { markupError } = useFormError(props.name);

	return (
		<div className={`${styles.customCheckboxWrapper} ${props.className ?? ""}`}>
			<label>
				<input
					type="checkbox"
					{...props}
					{...register(props.name)}
				/>
				<span className={props.disabled ? styles.disabled : undefined}>{props.label}</span>
			</label>

			{markupError}
		</div>
	);
};

export default FormCheckBox;
