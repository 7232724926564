import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

import GenericModal from "@components/GenericModal";
import Form from "@components/Form/Form";
import FormInput from "@components/Form/Input/FormInput";
import FormSelect from "@components/Form/Select/FormSelect";
import FormInputFile from "@components/Form/InputFile/FormInputFile";
import { UserContext } from "@contexts/UserContext";
import DocumentationType from "@constants/documentationType";
import { errorToast, successToast } from "@helpers/toastFunctions";
import instance from "@services/base";
import { API } from "@services/urlConstants";
import useHttpLoadingState from "@hooks/useHttpLoadingState";
import { uploadDocumentationSchema } from "@schema/uploadDocumentationSchema";
import { parseToRSOptions } from "@utils/mapOptions";
import { UPLOAD_FILES_DOCUMENT_TYPES } from "@constants/UploadFilesDocTypes";

const UploadComponent = ({
	uploadDocument,
	setUploadDocument,
	createVehicleDraft,
	vtcRoles,
	recall
}) => {
	const formRef = useRef(null);
	const { t, ready } = useTranslation("uploadComponent");
	const { rol } = useContext(UserContext);
	const [isUploading, handleOnUpload] = useHttpLoadingState();

	const [isFormRefSet, setIsFormRefSet] = useState(false);

	const [docTypesOptions, setDocTypesOptions] = useState(undefined);
	const [docFileWatch, setDocFileWatch] = useState(null);

	const onClose = () =>
		setUploadDocument({
			open: false,
			contractType: null,
			documentId: null,
			usedDocumentTypes: [],
			signStartDate: null
		});

	const getDocTypesOptions = useCallback(async () => {
		try {
			const response = await instance.get(
				API.GET_UPLOAD_FILES_DOCUMENT_TYPES(uploadDocument.contractType)
			);

			const docTypesOptions = parseToRSOptions(response.data, "description");
			const unusedDocTypesOptions = docTypesOptions.filter(
				(option) =>
					!(
						uploadDocument.signStartDate !== null &&
						option.value === UPLOAD_FILES_DOCUMENT_TYPES.SIGNED_CONTRACT
					) &&
					(option.value === UPLOAD_FILES_DOCUMENT_TYPES.OTHERS ||
						!uploadDocument.usedDocumentTypes.includes(option.value))
			);

			setDocTypesOptions(unusedDocTypesOptions);
		} catch (err) {
			errorToast(t("errors.getDocTypesOptions"));
		}
	}, [
		t,
		uploadDocument.contractType,
		uploadDocument.usedDocumentTypes,
		uploadDocument.signStartDate
	]);

	const onUpload = async (formData) => {
		const payload = new FormData();
		payload.append("id", uploadDocument.documentId);
		payload.append("idDocumentType", formData.docType.value);
		payload.append("fileName", `${formData.docName}.${formData.file.name.split(".").pop()}`);
		payload.append("file", formData.file, formData.file.name);

		try {
			await instance.post(API.UPLOAD_FILES, payload);
			await recall();
			successToast(t("uploadSuccess"));

			if (
				formData.docType.value === UPLOAD_FILES_DOCUMENT_TYPES.SIGNED_CONTRACT &&
				(uploadDocument.contractType === DocumentationType.COMPRA_DIRECTA ||
					uploadDocument.contractType === DocumentationType.GESTION_VENTA) &&
				!vtcRoles.some((r) => rol.includes(r))
			) {
				await createVehicleDraft({ id: uploadDocument.documentId });
			}

			onClose();
		} catch (err) {
			errorToast(t("errors.uploadDocument"));
		}
	};

	useEffect(() => {
		ready && getDocTypesOptions();
	}, [ready, getDocTypesOptions]);

	useEffect(() => {
		if (isFormRefSet) {
			const subscription = formRef.current.watch((value) => {
				setDocFileWatch(value.file);
			});

			return () => {
				subscription.unsubscribe();
			};
		}
	}, [isFormRefSet]);

	useEffect(() => {
		if (formRef.current) {
			const fileWithoutExtension = docFileWatch?.name.split(".").slice(0, -1).join(".");
			formRef.current.setValue("docName", fileWithoutExtension);
		}
	}, [docFileWatch]);

	return (
		<GenericModal
			isOpen={uploadDocument.open}
			setIsOpen={onClose}
			headContent={t("title")}
			bodyContent={
				<Form
					ref={(ref) => {
						formRef.current = ref;
						setIsFormRefSet(true);
					}}
					onSubmit={handleOnUpload(onUpload)}
					schema={uploadDocumentationSchema}
				>
					<div className={styles.form}>
						<FormInput
							name="docName"
							label={t("docName")}
							placeholder={t("docName")}
						/>
						<FormSelect
							name="docType"
							label={t("docType")}
							placeholder={t("docType")}
							options={docTypesOptions}
						/>
					</div>

					<FormInputFile name="file" />
				</Form>
			}
			footerContent={
				<div className={styles.actions}>
					<button
						className="btn btn-flexicar-inverse"
						onClick={onClose}
					>
						{t("actions.cancel")}
					</button>
					<button
						className="btn btn-flexicar-orange"
						onClick={() => formRef.current.triggerSubmit()}
						disabled={isUploading}
					>
						{t("actions.upload")}
					</button>
				</div>
			}
		/>
	);
};

export default UploadComponent;
