import React, { useState, useEffect } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  Label,
  Input,
  Toast,
  ToastBody,
  ToastHeader,
} from "reactstrap";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import { putStatus } from "@services/Vehicle/vehicle.service";
import ModalDelivery from "@components/ModalDelivery";
import errorCall from "@services/errorCall";
import {
  errorToast,
  successToast,
  warningToast,
} from "@helpers/toastFunctions";
import styles from "./index.module.scss";
import moment from "moment";
import DatePicker from "@components/DatePicker";
import { MdErrorOutline, MdClose } from "react-icons/md";
import { Col, Row } from "react-bootstrap";
import ModalWarningContract from "@components/ModalWarningContract/ModalWarningContract";
import { useTranslation } from "react-i18next";
import Select from "@components/Select";
import { getFilterBanks, getFilterCompanies } from "@services/Filtros/filtros";

const ModalCalendar = ({
  title,
  text,
  type,
  matricula,
  open,
  close,
  document,
  setDocumentsInfo,
  documentsInfo,
  insideVehicle,
  garantia,
  setGarantia,
  recallDocuments,
}) => {
  const { t } = useTranslation('modalCalendar');

  const [date, setDate] = useState();
  const [banco, setBanco] = useState();
  const [empresa, setEmpresa] = useState();
  const [empresaData, setEmpresaData] = useState();
  const [importeFin, setImporteFin] = useState();
  const [comisionFin, setComisionFin] = useState();
  const [comisionTien, setComisionTien] = useState();
  const [factura, setFactura] = useState();
  const [IVA, setIVA] = useState(false);
  const [contratosCompra, setContratosCompra] = useState([]);
  const [show] = useState(true);
  const [disabled] = useState(false);
  const [modalDelivery, setModalDelivery] = useState(false)
  const [modalUnsigned, setModalUnsigned] = useState(false)


  useEffect(() => {
   
    if (document) {
      checkFactura();
      const datos = document ? document.datos : null;
      if (type === "delivery") {
        setDate(
          document.fecha_entrega
            ? new Date(document.fecha_entrega.replace(" ", "T")).getTime()
            : null
        );
      }
      if (type === "bill") {
     
        setDate(
          document.fecha_facturacion
            ? new Date(document.fecha_facturacion.replace(" ", "T")).getTime()
            : null
        );
        if (datos.aplica_iva === 1) {
          setIVA(true);
        }
      }
      if (datos?.banco_code) {
        setBanco([{ value: datos?.banco_code, label: datos?.banco_name }]);
      } else {
        setBanco([{ value: 1, label: "Santander" }]);
      }

      setImporteFin(datos?.importe_financia);
      setComisionFin(datos?.comision_financia);
      setComisionTien(datos?.comision_tienda);
    }

    if (documentsInfo && document) {
      const cifEmpresa = documentsInfo.find(
        (e) => e.documentId === document.documentId
      ).datos?.empresa_CIF;
      getEmpresaDataByCIF(cifEmpresa);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [document]);

  const checkFactura = async () => {
    const idFactura = await post(API.GET_LAST_INVOICE, {
      document: document.documentId,
    });
    if (
      idFactura.res &&
      idFactura.res.empresa &&
      idFactura.res.local &&
      idFactura.res.numero
    ) {
      let numb = idFactura.res.numero.toString();
      while (numb.length < 3) {
        numb = "0" + numb;
      }
      setFactura({
        id: idFactura.res.empresa + "-" + idFactura.res.local + "-" + numb,
        fecha: idFactura.res.fecha,
      });
    } else {
      setFactura();
    }
  };

  const setBancoOrContado = (value) => {
    if (value[0].value === 8) {
      handleChange({ target: { value: 0 } }, "comisionFin");
      handleChange({ target: { value: 0 } }, "comisionTien");
      handleChange({ target: { value: 0 } }, "importeFin");
    }
    setBanco(value);
  };

  const getEmpresaDataByCIF = async (cif) => {
    const empresaData = await post(API.GET_EMPRESA_BY_CIF, { cif });
    setEmpresa([{ value: empresaData.res.id, label: empresaData.res.nombre }]);
    setEmpresaData({
      id: empresaData.res.id,
      nombre: empresaData.res.nombre,
      sello: empresaData.res.sello,
      cif: cif,
      direccion: empresaData.res.direccion,
    });
  };

  const getEmpresaDataById = async (value) => {
    setEmpresa(value);
    const id = value[0].value;
    const empresaData = await post(API.GET_EMPRESA_BY_ID, { id });
    setEmpresaData({
      id: empresaData.res.id,
      nombre: empresaData.res.nombre,
      sello: empresaData.res.sello,
      cif: empresaData.res.CIF,
      direccion: empresaData.res.direccion,
    });
  };

  const handleChange = (e, type) => {
    const { value } = e.target;
    switch (type) {
      case "importeFin":
        setImporteFin(value);
        break;
      case "comisionFin":
        setComisionFin(value);
        break;
      case "comisionTien":
        setComisionTien(value);
        break;
      default:
        break;
    }
  };

  const changeDate = async (cancel) => {

		try {
			if (type === "bill" && cancel) {
				await post(API.ADD_CAR_BILL_DATE, {
					date: null,
					id: document.documentId
				});
				recallDocuments();
				close();
			}
			if (type === "bill" && !cancel) {
				await post(API.ADD_CAR_BILL_DATE, {
					date: moment(date).format("YYYY/MM/DD  HH:mm:ss"),
					id: document.documentId
				});

				recallDocuments();
				close();
			}
			if (type === "delivery" && cancel) {
				if (document.vehicle_id_stock) {
					await putStatus(document.vehicle_id_stock, 1);
				}
				await post(API.ADD_CAR_DELIVERY_DATE, {
					date: null,
					id: document.documentId
				});
				if (insideVehicle) {
					let copyGarantia = garantia;
          copyGarantia.find((e) => e.contrato === document.documentId).caducidad = null;
					setGarantia(copyGarantia);
				}
				recallDocuments();

				close();
			}

			if (type === "delivery" && !cancel) {
				if (document.vehicle_id_stock) {
					let deliveryDate = moment(date).format("YYYY/MM/DD");
					let today = moment().format("YYYY/MM/DD");
					if (today < deliveryDate && document.status !== 3) {
						await putStatus(document.vehicle_id_stock, 3);
						await post(API.ADD_CAR_DELIVERY_DATE, {
							date: moment(date).format("YYYY/MM/DD HH:mm:ss"),
							id: document.documentId
						});

						successToast(<span>Fecha actualizada</span>);
					} 
          else if (today < deliveryDate && document.status === 3){
            await post(API.ADD_CAR_DELIVERY_DATE, {
							date: moment(date).format("YYYY/MM/DD HH:mm:ss"),
							id: document.documentId
						});
            	successToast(<span>Fecha actualizada</span>);
          }
          
          else if (today === deliveryDate) {
						if (!document.documentNameCloud) {
							return setModalUnsigned(true);
						}

						setModalDelivery({
							type: "today",
							document
						});
					} else if (today > deliveryDate && document.status === 3) {
					
						setModalDelivery({
							type: "past",
							document
						});

					
					} else {
            
						if (!document.documentNameCloud) {
							return setModalUnsigned(true);
						}

						setModalDelivery({
							type: "past",
							document
						});
					}
				} else {
					await post(API.ADD_CAR_DELIVERY_DATE, {
						date: moment(date).format("YYYY/MM/DD HH:mm:ss"),
						id: document.documentId
					});

					successToast(<span>Fecha actualizada</span>);
				}
				if (insideVehicle) {
					let copyGarantia = garantia;
					copyGarantia.find((e) => e.contrato === document.documentId).caducidad = moment(date)
						.add(1, "year")
						.format("YYYY-MM-DDTHH:mm:ss.SSS");
					setGarantia(copyGarantia);
				}

				recallDocuments();
				close();
			}
		} catch (e) {
			errorCall(e);
			errorToast(<span>{t("errors.changeDateError")}</span>);
		}
	};
  const changeData = async () => {
    const documentData = document.datos;
    const callData = {
      id: document.documentId,
      bancoName: banco && banco[0].label,
      bancoCode: banco && banco[0].value,
      importeFin: importeFin,
      comisionFin: comisionFin,
      comisionTien: comisionTien,
      empresaCIF: empresaData && empresaData.cif,
      empresaDireccion: empresaData && empresaData.direccion,
      empresaNombre: empresaData && empresaData.nombre,
      empresaSello: empresaData && empresaData.sello,
      aplica_iva: IVA ? 1 : 0,
      contratos_compra: contratosCompra ? true : false,

      matricula_venta: documentData.matricula,
    };

    if (
      !callData.empresaCIF ||
      !callData.empresaDireccion ||
      !callData.bancoName ||
      !callData.bancoCode
    ) {
      return;
    }
    try {
      await post(API.REBU_BANCO, callData);
      changeDate(!date);
      let copyDatos = document.datos;
      copyDatos.banco_name = banco && banco[0].label;
      copyDatos.banco_code = banco && banco[0].value;
      copyDatos.financia = importeFin && importeFin > 0 ? true : false;

      copyDatos.importe_financia = importeFin;
      copyDatos.comision_financia = comisionFin;
      copyDatos.comision_tienda = comisionTien;

      let copyDocumentsInfo = documentsInfo;
			copyDocumentsInfo.find((e) => e.documentId === document.documentId).datos =
				JSON.stringify(copyDatos);
			setDocumentsInfo((prev) => ({ ...prev, data: copyDocumentsInfo }));
      close();
      successToast(<span>Datos añadidos con exito</span>);
    } catch (e) {
      errorCall(e);
      errorToast(<span>{t("errors.changeDataError")}</span>);
    }
    if (factura) {
      warningToast(
        <span className={`${styles.flex}`}>
          <MdErrorOutline className={`md-36 ${styles.advice}`} />
          Recuerda que debes imprimir nuevamente la factura
        </span>
      );
    }
  };

  const switchIVA = () => {
    setIVA(!IVA);
  };
  const getContratosCompra = async () => {
    try {
      const response = await post(API.GET_CONTRACT_COMPRA, { matricula });
      if (response) {
        await setContratosCompra(response);
      }
    } catch {
      return errorToast(<span>{t("errors.loadConversationError")}</span>);
    }
  };
  useEffect(() => {
    if (type === "bill" && matricula) {
      getContratosCompra();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matricula]);

  let documentos;
  if (document) {
    documentos = document?.datos;
  }

  return (
    <div>
      <Modal size="large" centered isOpen={open}>
        <ModalHeader className={`${styles.modal_header}`}>
          <h3> {title}</h3>
          <p>{text}</p>
        </ModalHeader>
        <ModalBody>
          {contratosCompra?.length > 0 ? (
            <div className="bg-danger my-2 rounded">
              <Toast style={{ maxWidth: "100% " }} isOpen={show}>
                <ToastHeader>Coche como forma de pago</ToastHeader>
                <ToastBody>
                  <div
                    className="row justify-content-between "
                    style={{ marginTop: ".25rem" }}
                  >
                    <div className="col-md-12 col-sm-12">
                      {documentos?.forma_pago !== "Coche como forma de pago" ||
                      documentos?.forma_pago !== "Otros" ? (
                        <p>
                          Este coche tiene contratos de compra asociados como
                          forma de pago pero su forma de pago es{" "}
                          <strong>{documentos?.forma_pago}</strong>
                        </p>
                      ) : null}
                      <p>
                        <strong>Contrato de compra asociado: </strong>
                      </p>
                      {contratosCompra.map((elm) => (
                        <p>
                          <strong>Marca:</strong>
                          {elm.marca}
                          <strong> Modelo:</strong> {elm.modelo}
                          <strong> Matrícula:</strong> {elm.matricula}
                          <strong> Precio:</strong> {elm.importe_compra}€
                        </p>
                      ))}
                    </div>
                  </div>
                </ToastBody>
              </Toast>
            </div>
          ) : null}
          <Container>
            <Row>
              <Col>
                <DatePicker
                  date={date}
                  type={type}
                  setDate={setDate}
                  info={
                    type === "delivery"
                      ? "Fecha de entrega"
                      : type === "bill"
                      ? "Fecha de facturación"
                      : null
                  }
                />
              </Col>
              {type === "bill" && (
                <Col>
                  <h6>Empresa</h6>
                  <Select
                    placeholder="Empresas"
                    loadOptions={() => getFilterCompanies(document.documentId)}
                    value={empresa}
                    onChange={(value) => getEmpresaDataById([value])}
                  />
                </Col>
              )}
              {type === "bill" && (
                <div>
                  <Label
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      paddingLeft: "10%",
                    }}
                    check
                  >
                    <Input type="checkbox" onChange={switchIVA} checked={IVA} />
                    IVA Deducible
                  </Label>
                  <Row className="mx-1 mt-3">
                    <Col>
                      <h6>Banco</h6>
                      <Select
                        placeholder="Bancos"
                        loadOptions={getFilterBanks}
                        value={banco}
                        onChange={(value) => setBancoOrContado([value])}
                      />
                    </Col>
                    <Col>
                      {banco && banco[0]?.value !== 8 && (
                        <h6>Importe Financiado</h6>
                      )}
                      {banco && banco[0]?.value !== 8 && (
                        <Input
                          value={importeFin}
                          type="number"
                          onChange={(e) => handleChange(e, "importeFin")}
                        />
                      )}
                    </Col>
                  </Row>

                  {banco && banco[0]?.value !== 8 && (
                    <Row className="mt-3 mx-1">
                      <Col>
                        <h6>Comisión financiación</h6>

                        <Input
                          type="number"
                          value={comisionFin}
                          onChange={(e) => handleChange(e, "comisionFin")}
                        />
                      </Col>
                      <Col>
                        <h6>Comisión de tienda</h6>
                        <Input
                          type="number"
                          value={comisionTien}
                          onChange={(e) => handleChange(e, "comisionTien")}
                        />
                      </Col>
                    </Row>
                  )}
                </div>
              )}
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          {type === "bill" && (
            <span>
              <Button
                className={`${styles.btn_custom}`}
                onClick={() => changeData()}
                color="primary"
                disabled={disabled}
              >
                Actualizar datos
              </Button>
            </span>
          )}

          {type === "delivery" && (
            <>
              {document?.fecha_entrega && (moment(document?.fecha_entrega).format("YYYY/MM/DD")
                <= moment().format("YYYY/MM/DD")) && document.status === 3 ?
                <Button onClick={() => changeDate()} color="primary">
                  Confirmar entrega
                </Button>
                :
                <Button onClick={() => changeDate()} color="primary">
                  {document?.fecha_entrega ? "Actualizar fecha" : "Añadir fecha"}
                </Button>
              }
            </>)}
          {type === "delivery" && document?.fecha_entrega && (
            <Button onClick={() => changeDate("delete")} color="danger">
              Cancelar entrega
            </Button>
          )}
        </ModalFooter>
        <div className={styles.close}>
          <MdClose title="Cerrar" onClick={close} size={30} />
        </div>
      </Modal>
      <ModalDelivery
        modalDelivery={modalDelivery}
        idVehiculo={document?.id_vehicle_stock}
        date={date}
        document={document}
        setModalDelivery={setModalDelivery}
        recallDocuments={recallDocuments}
        setModalUnsigned={setModalUnsigned} 
      />
      <ModalWarningContract
        modalUnsigned={modalUnsigned}
        setModalUnsigned={setModalUnsigned}
      />
    </div>
  );
};

export default ModalCalendar;
