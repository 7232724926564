import { useCallback, useState } from "react";

/**
 * Custom hook to manage the loading state of an HTTP request.
 *
 * @returns {[boolean, Function]} An array with the loading state and a function to handle the HTTP request.
 */
const useHttpLoadingState = () => {
	const [isLoading, setIsLoading] = useState(false);

	/**
	 * Function to handle the HTTP request.
	 *
	 * @param {Function} requestFunction - The HTTP request function to be executed.
	 * @returns {Function} A function that accepts form data and manages the loading state.
	 */
	const handleHttpRequest = useCallback((requestFunction) => {
		return async (...args) => {
			setIsLoading(true);
			try {
				await requestFunction(...args);
			} finally {
				setIsLoading(false);
			}
		};
	}, []);

	return [isLoading, handleHttpRequest];
};

export default useHttpLoadingState;
