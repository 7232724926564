import * as Yup from "yup";

import { vehicleBasicDataFormSchema } from "@schema/forms/vehicleBasicDataFormSchema";
import { vehicleCharacteristicsFormSchema } from "@schema/forms/vehicleCharacteristicsFormSchema";
import { vehicleStateFormSchema } from "@schema/forms/vehicleStateFormSchema";

const vehicleValidationDataFormSchema = (translate) =>
	Yup.object().shape({
		...vehicleBasicDataFormSchema(translate).fields,
		...vehicleCharacteristicsFormSchema(translate).fields,
		...vehicleStateFormSchema(translate).fields
	});

export { vehicleValidationDataFormSchema };
