import * as Yup from "yup";

export const vehicleBasicDataFormSchema = ({ t }) =>
	Yup.object({
		mode: Yup.boolean(),
		make: Yup.object({
			label: Yup.string(),
			value: Yup.number()
		}).required(t("errors.mandatory")),
		model: Yup.object({
			label: Yup.string(),
			value: Yup.number()
		}).required(t("errors.mandatory")),
		year: Yup.object({
			label: Yup.number(),
			value: Yup.number()
		}).required(t("errors.mandatory")),
		fuel: Yup.object({
			label: Yup.string(),
			value: Yup.number()
		}).required(t("errors.mandatory")),
		transmission: Yup.object({
			label: Yup.string(),
			value: Yup.number()
		}).required(t("errors.mandatory")),
		bodyType: Yup.object({
			label: Yup.string(),
			value: Yup.number()
		}).required(t("errors.mandatory")),
		version: Yup.mixed().when(["$mode"], ([mode]) =>
			mode
				? Yup.object({
						label: Yup.string(),
						value: Yup.mixed()
				  }).required(t("errors.mandatory"))
				: Yup.string().required(t("errors.mandatory"))
		),
		registrationDate: Yup.string().isISODateString().required(t("errors.mandatory"))
	});
