import * as Yup from "yup";

export const vehicleCharacteristicsFormSchema = ({ t }) =>
	Yup.object({
		specs: Yup.object({
			cc: Yup.number().nullable(),
			power: Yup.number().required(t("errors.mandatory")),
			emissions: Yup.number().nullable(),
			cubinVolume: Yup.number().nullable(),
			consumptionMix: Yup.number().nullable(),
			consumptionRoad: Yup.number().nullable(),
			consumptionUrban: Yup.number().nullable(),
			seats: Yup.number().required(t("errors.mandatory")),
			doors: Yup.number().required(t("errors.mandatory")),
			gearCount: Yup.number().nullable(),
			ecosticker: Yup.object({
				label: Yup.string(),
				value: Yup.number()
			}).nullable()
		})
	});
