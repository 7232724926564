import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { UserContext } from "@contexts/UserContext";
import RolType, { IS_SUPER_ADMIN } from "@constants/roles";

import styles from "@styles/components/forms/vehicle-characteristics-form.module.scss";

import FormFormattedNumberInput from "@components/Form/FormattedNumberInput/FormFormattedNumberInput";
import FormSelect from "@components/Form/Select/FormSelect";
import { ecoStickerOptions } from "@constants/selectOptions";
import { VEHICLE_MODE } from "@constants/vehicleValidation";

const VehicleCharacteristicsForm = ({ vehicle }) => {
	const { t } = useTranslation("forms/vehicleCharacteristicsForm");
	const { watch } = useFormContext();
	const { rol, userType } = useContext(UserContext);

	const canRolEdit = IS_SUPER_ADMIN(userType) || rol.includes(RolType.TERRITORIAL) || rol.includes(RolType.TRADING_MANAGER);

	const [mode, setMode] = useState(vehicle.mode);

	const watchedMode = watch("mode");

	useEffect(() => {
		setMode(watchedMode ? VEHICLE_MODE.JATO : VEHICLE_MODE.CLASSIC);
	}, [watchedMode]);

	return (
		<div className={styles.vehicle_characteristics_form}>
			<h4>{t("title")}</h4>

			<FormFormattedNumberInput
				name="specs.cc"
				label={t("displacement")}
				placeholder={t("displacement")}
				decimalScale={0}
				suffix="cc"
				disabled={mode === VEHICLE_MODE.JATO}
			/>
			<FormFormattedNumberInput
				name="specs.power"
				label={`${t("power")}*`}
				placeholder={t("power")}
				decimalScale={0}
				suffix=" CV"
				disabled={mode === VEHICLE_MODE.JATO}
			/>
			<FormFormattedNumberInput
				name="specs.emissions"
				label={t("co2Emissions")}
				placeholder={t("co2Emissions")}
				decimalScale={0}
				suffix=" g/km"
				disabled={mode === VEHICLE_MODE.JATO}
			/>
			<FormFormattedNumberInput
				name="specs.cubinVolume"
				label={t("trunkCapacity")}
				placeholder={t("trunkCapacity")}
				decimalScale={0}
				suffix="l"
				disabled={mode === VEHICLE_MODE.JATO}
			/>
			<div className={styles.vehicle_characteristics_form__consumption}>
				<label>
					{t("consumption")}
					<div>
						<FormFormattedNumberInput
							name="specs.consumptionMix"
							// label="Combi"
							placeholder="Combi l/100"
							suffix=""
							disabled={mode === VEHICLE_MODE.JATO}
						/>
						<FormFormattedNumberInput
							name="specs.consumptionRoad"
							// label="Road"
							placeholder="Road l/100"
							suffix=""
							disabled={mode === VEHICLE_MODE.JATO}
						/>
						<FormFormattedNumberInput
							name="specs.consumptionUrban"
							// label="Urban"
							placeholder="Urban l/100"
							suffix=""
							disabled={mode === VEHICLE_MODE.JATO}
						/>
					</div>
				</label>
			</div>
			{/* min 1 */}
			<FormFormattedNumberInput
				name="specs.seats"
				label={`${t("seats")}*`}
				placeholder={t("seats")}
				decimalScale={0}
				suffix=""
				disabled={mode === VEHICLE_MODE.JATO && !canRolEdit}
			/>
			{/* min 1 */}
			<FormFormattedNumberInput
				name="specs.doors"
				label={`${t("doors")}*`}
				placeholder={t("doors")}
				decimalScale={0}
				suffix=""
				disabled={mode === VEHICLE_MODE.JATO}
			/>
			{/* min 1 */}
			<FormFormattedNumberInput
				name="specs.gearCount"
				label={t("numberOfGears")}
				placeholder={t("numberOfGears")}
				decimalScale={0}
				suffix=""
				disabled={mode === VEHICLE_MODE.JATO}
			/>
			<FormSelect
				name="specs.ecosticker"
				label={t("ecoSticker")}
				placeholder={t("ecoSticker")}
				options={ecoStickerOptions}
				isDisabled={mode === VEHICLE_MODE.JATO}
			/>
		</div>
	);
};

export default VehicleCharacteristicsForm;
