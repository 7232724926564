import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { customYupResolver } from "@components/Form/customYupResolver";

/**
 * @typedef {Object} Props
 * @property {React.ReactNode} children - The child elements of the component.
 * @property {string} i18nErrorsFile - The name of the file that has the keys and messages for the schema errors.
 * @property {Function} onSubmit - The function called when the form is submitted.
 * @property {Function} schema - The schema that will be evaluated for the form validation.
 * @property {keyof import('react-hook-form').ValidationMode} formValidationMode - The mode for form validation.
 * @property {Object} defaultValues - The default values for form fields.
 * @property {Function} onFormStateChange - Callback to listen on formState changes.
 * @property {string} className - The CSS class for the form.
 * @property {React.Ref} ref - A React ref that can be used to trigger form-related actions imperatively.
 */

/**
 * @type React.ForwardRefRenderFunction<?, Props>
 */
const Form = forwardRef(function Form(
	{
		children,
		i18nErrorsFile,
		onSubmit,
		schema,
		formValidationMode,
		defaultValues,
		onFormStateChange,
		className
	},
	ref
) {
	const translate = useTranslation(i18nErrorsFile);
	const formRef = useRef(null);

	const resolver = useMemo(() => {
		if (!schema) return null;
		if (!i18nErrorsFile) return customYupResolver(typeof schema === "function" ? schema() : schema, { strict: true });
		if (typeof schema !== "function")
			throw new Error("Schema needs to be a function to pass the 't' for the i18n errors");

		return customYupResolver(schema(translate), { strict: true });
	}, [i18nErrorsFile, schema, translate]);

	const methods = useForm({
		resolver,
		mode: formValidationMode,
		defaultValues
	});

	useEffect(() => {
		onFormStateChange?.(methods.formState);
	}, [methods.formState, onFormStateChange]);

	useImperativeHandle(ref, () => ({
		...methods,
		triggerSubmit: () => {
			if (formRef.current) {
				formRef.current.dispatchEvent(new Event("submit", { cancelable: true }));
			}
		}
	}));

	return (
		<FormProvider {...methods}>
			<form
				ref={formRef}
				onSubmit={methods.handleSubmit(onSubmit)}
				style={{ width: "100%" }}
				className={className}
			>
				{children}
			</form>
		</FormProvider>
	);
});

export default Form;
