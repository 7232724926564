import * as Yup from "yup";

const vehicleValidationSalesFormSchema = ({ t }, type) =>
	Yup.object({
		priceCash: Yup.number().nullable(),
		priceFinanced: Yup.number().nullable(),
		offer: Yup.boolean(),
		priceOffer: Yup.number().when(["$offer"], ([offer], schema) =>
			offer ? schema.required(t("errors.mandatory")) : schema.notRequired()
		),
		chargeFree: Yup.boolean(),
		taxDeductible: Yup.boolean(),
		buyAndSell: Yup.boolean(),
		salesManagement: Yup.boolean(),
		priceDealerSelling: Yup.number().when(["$buyAndSell"], ([buyAndSell], schema) =>
			type === "stock" && buyAndSell ? schema.required(t("errors.mandatory")) : schema.notRequired()
		),
		estimatedPrice: Yup.number().when(["$salesManagement"], ([salesManagement], schema) =>
			salesManagement
				? schema.required(t("errors.mandatory")).moreThan(0, t("errors.positive"))
				: schema.notRequired()
		),
		commission: Yup.number().when(["$salesManagement"], ([salesManagement], schema) =>
			salesManagement
				? schema.required(t("errors.mandatory")).moreThan(0, t("errors.positive"))
				: schema.notRequired()
		),
		penalty: Yup.number().when(["$salesManagement"], ([salesManagement], schema) =>
			salesManagement
				? schema.required(t("errors.mandatory")).moreThan(0, t("errors.positive"))
				: schema.notRequired()
		)
	});

export { vehicleValidationSalesFormSchema };
