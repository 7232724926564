const getEnvironmentVariable = (environmentVariable) => {
  const unvalidatedEnvironmentVariable = process.env[environmentVariable];
  if (!unvalidatedEnvironmentVariable) {
    throw new Error(`Couldn't find environment variable: ${environmentVariable}`);
  } else {
    return unvalidatedEnvironmentVariable;
  }
};

export const environment = {
  CRM_SERVICES: getEnvironmentVariable("REACT_APP_API_HOST"),
  API_CRM: getEnvironmentVariable("REACT_APP_CRM_API"),
  REACT_HOST: getEnvironmentVariable("REACT_APP_VIEW_REACT"),
  PHP_HOST: getEnvironmentVariable("REACT_APP_PORT_PHP"),
  API_KEY_ERP: getEnvironmentVariable("REACT_APP_API_KEY_ERP"),
  API_KEY_YOUTUBE: getEnvironmentVariable("REACT_APP_YOUTUBE_API"),
  CHANNEL_ID_YOUTUBE: getEnvironmentVariable("REACT_APP_CHANNEL_ID"),
  API_KEY_GOOGLEMAPS: getEnvironmentVariable("REACT_APP_GOOGLEMAPS_API"),
  CLOUD_FUNCTIONS_URL: getEnvironmentVariable("REACT_APP_CLOUD_FUNCTIONS_URL"),
  API_KEY_CF_SIGNED_URL: getEnvironmentVariable(
    "REACT_APP_API_KEY_CF_SIGNED_URL"
  ),
  CF_SIGNED_URL: getEnvironmentVariable("REACT_APP_CF_SIGNED_URL"),
  API_FINANCE: getEnvironmentVariable("REACT_APP_API_HOST_FINANCE"),
  API_KEY_GOOGLE_TRANSLATE: getEnvironmentVariable("REACT_APP_GOOGLE_TRANSLATE_API"),
  GOOGLE_CLIENT_ID: getEnvironmentVariable("REACT_APP_GOOGLE_CLIENT_ID"),
  ADMIN_URL: getEnvironmentVariable("REACT_APP_ADMIN_URL"),
  SHOW_SAGE_BUTTONS: getEnvironmentVariable("REACT_APP_SHOW_SAGE_BUTTONS") === "true",
  SELL_CONTRACT_TEMPLATE_PT: getEnvironmentVariable("REACT_APP_SELL_CONTRACT_TEMPLATE_PT"),
  BUY_AND_SELL_TEMPLATE_PT: getEnvironmentVariable("REACT_APP_BUY_AND_SELL_TEMPLATE_PT")
};
