import * as Yup from "yup";

const sellContractAgentDiscountSchemaPT = (isSuperAdmin) =>
	Yup.object({
		total: Yup.number()
			.isNumberWithLessThanNDecimals(2)
			.when(["$sellInfo.pvp"], ([pvp], schema) => 
				isSuperAdmin
					? schema.max(pvp, `Não deve ser superior a ${pvp}€`)
					: schema.max(490, "Não deve ser superior a 490€")
			),
		administrativeCosts: Yup.object({
			selected: Yup.boolean(),
			amount: Yup.number()
				.min(0, "Valores negativos não são permitidos")
				.isNumberWithLessThanNDecimals(2)
				.when(
					["$agentDiscount.administrativeCosts.selected", "$sellInfo.administrativeCosts"],
					([transferDiscountSelected, administrativeCostsAmount], schema) =>
						transferDiscountSelected
							? schema.max(
									administrativeCostsAmount,
									"A cortesia não pode ser superior ao preço do produto"
							  )
							: schema.notRequired()
				)
		}),
		transport: Yup.object({
			selected: Yup.boolean(),
			amount: Yup.number()
				.min(0, "Valores negativos não são permitidos")
				.isNumberWithLessThanNDecimals(2)
				.when(
					["$agentDiscount.transport.selected", "$sellInfo.transport.amount"],
					([transportDiscountSelected, transportAmount], schema) =>
						transportDiscountSelected
							? schema.max(transportAmount, "A cortesia não pode ser superior ao preço do produto")
							: schema.notRequired()
				)
		}),
		warranty: Yup.object({
			selected: Yup.boolean(),
			amount: Yup.number()
				.min(0, "Valores negativos não são permitidos")
				.isNumberWithLessThanNDecimals(2)
				.when(
					["$agentDiscount.warranty.selected", "$sellInfo.warranty.amount"],
					([warrantyDiscountSelected, warrantyAmount], schema) =>
						warrantyDiscountSelected
							? schema.max(warrantyAmount, "A cortesia não pode ser superior ao preço do produto")
							: schema.notRequired()
				)
		})
	});

export { sellContractAgentDiscountSchemaPT };
