import React, { useState, useEffect } from "react";
import { Button, Spinner } from "reactstrap";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import styles from "./index.module.scss";
import getLastLeadId from "@helpers/getLastLeadId";
import { errorToast, successToast } from "@helpers/toastFunctions";
import moment from "moment";
import { BsFillCalendarEventFill } from "react-icons/bs";
import DatePicker, { registerLocale } from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import es from "date-fns/locale/es";
import { useTranslation } from 'react-i18next';
import Locale from "@constants/Locale";
import Select from "@components/Select";
import { getFilterAppointmentDealerships } from "@services/Filtros/filtros";
import { formatDateTimeToISO } from "@helpers/formatDate";
import LEAD_STATUS from "@constants/LeadStatus";

registerLocale("es", es);

const ModalCitar = ({
  state,
  user,
  setState,
  getDataAdd,
  clientData,
  vehiculo,
  createLead,
  setLocalActual,
}) => {

  const [deleted, setDeleted] = useState(true);
  const [, forceUpdate] = useState();
  const [spinner, setSpinner] = useState(false);
  const [minTime, setMinTime] = useState(
    setHours(setMinutes(new Date(), 30), 9)
  );
  const [maxTime, setMaxTime] = useState(
    setHours(setMinutes(new Date(), 1), 20)
  );
  const [excludeTime, setExcludeTime] = useState(false);
  const aurgiLocals = [76, 77, 78, 79, 80, 81];
  const { i18n, t } = useTranslation("modalCitar");


  useEffect(() => {
    forceUpdate();
  }, [deleted]);

  useEffect(() => {
    //ParqueSur Opening hours
    if (state.checkLead?.local?.[0]?.value === 73) {
      setMinTime(setHours(setMinutes(new Date(), 0), 10));
      setMaxTime(setHours(setMinutes(new Date(), 30), 19));
      setExcludeTime(false);
    }
    //Aurgi Opening hours
    if (aurgiLocals.includes(state.checkLead?.local?.[0]?.value)) {
      setMinTime(setHours(setMinutes(new Date(), 0), 10));
      setMaxTime(setHours(setMinutes(new Date(), 30), 19));
      setExcludeTime([
        setHours(setMinutes(new Date(), 0), 14),
        setHours(setMinutes(new Date(), 30), 14),
        setHours(setMinutes(new Date(), 0), 15),
        setHours(setMinutes(new Date(), 30), 15),
      ]);
    } else {
      setMinTime(setHours(setMinutes(new Date(), 30), 9));
      setMaxTime(setHours(setMinutes(new Date(), 1), 20));
      setExcludeTime(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.checkLead?.local]);


  const newAppointment = async () => {
    if (
      state.checkLead.local === "" ||
      state.checkLead.local === null ||
      state.checkLead.local[0]?.value === ""
    ) {
      return errorToast(<span>{t("errors.errorLocal")}</span>);
    }
    if (state.checkLead.cita === "" || state.checkLead.cita === null) {
      return errorToast(<span>{t("errors.errorCita")}</span>);
    }
    if (
      vehiculo.modelo === "" ||
      vehiculo.modelo.length === 0
    ) {
      return errorToast(
        <span>{t("errors.errorModelo")}</span>
      );
    }
    if (clientData.contact.email === "" || clientData.contact.email === null) {
      return errorToast(<span>{t("errors.errorEmail")}</span>);
    }
    if (moment(state.checkLead.cita).format("HH") === "00") {
      return errorToast(<span>{t("errors.errorHora")}</span>);
    }      
    const lead_id = state.checkLead.id_existente_lead;
    const local_id = state.checkLead.local[0].value;
    const last_id = await getLastLeadId(state.checkLead.id_existente_lead);
    const cita = formatDateTimeToISO(state.checkLead.cita); 
    try {
      await setSpinner(true);
      await createLead();
      const newAppointment = await post(API.NEW_APPOINTMENT, {
        lead_id,
        local_id,
        cita,
        user,
        last_id: last_id.id,
      });
      if (newAppointment) {
        setState({
          ...state,
          checkLead: {
            ...state.checkLead,
            estado: [{ label: "Citado", value: LEAD_STATUS.citado }],
          },
        });
        setLocalActual(state.checkLead.local)
        if (i18n.language === Locale.ES) {
         
          await post(API.SEND_EMAIL_CITA, {
            email: clientData.contact.email,
            cliente: clientData?.name,
            telefono_cliente:clientData.contact.primaryPhone,
            fecha: moment(state.checkLead.cita).format("DD-MM-YYYY"),
            hora: moment(state.checkLead.cita).format("HH.mm"),
            id_local: state.checkLead.local[0].value,
            marca: vehiculo.marca_vehiculo[0].label,
            modelo: vehiculo.modelo[0].label,
            version:
              vehiculo.version && vehiculo.version[0]?.label
                ? vehiculo.version[0]?.label
                : "-",
            anho:
              vehiculo.matriculacion && vehiculo.matriculacion[0]?.label > 0
                ? vehiculo.matriculacion[0]?.label
                : "-",
            km: state.checkLead.kilometros ? state.checkLead.kilometros : "-",
            precio: state.checkLead.valoracion ? state.checkLead.valoracion : "-",
            tasacion_min: state.checkLead.tasacion_min,
            tasacion_max: state.checkLead.tasacion_max 
          }).then((response) => {
            if (response) {
              setSpinner(false);
            }
          });
        }
        else {
          setSpinner(false)
        }
        return successToast(<span>Cita creada correctamente</span>);
      }
    } catch {
      setSpinner(false)
      return errorToast(<span>{t("errors.errorCrear")}</span>);
    }
  };
  const deleteAppointment = async () => {
    const lead_id = state.checkLead.id_existente_lead;

    const cita = state.checkLead.cita;
    await getLastLeadId(state.checkLead.id_existente_lead);
    await post(API.DELETE_APPOINTMENT, {
      lead_id,
      cita,
      user,
      last_status: 3,
    });

    await setState({
      ...state,
      checkLead: {
        ...state.checkLead,
        estado: [{ label: "Dudoso", value: 3 }],
        local: [{ label: null, value: null }],
        cita: null,
      },
    });
    await setDeleted(false);
    // document.getElementById('cita').value = null

    return successToast(<span>Cita borrada correctamente</span>);
  };

  return (
    <>
      <div>
        <div
          className="clearfix mb-3"
          style={{
            padding: ".7rem",
            backgroundColor: "#F9F9F9",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span className="tittle ml-4" style={{ color: "#2C2C2C" }}>
            Citar
          </span>
        </div>
        <form>
          <div className={`col-md-12 col-sm-12 ${styles.floatingLabel}`}>
            <label
              className={
                state.checkLead?.local ? styles.select : styles.notSelect
              }
            >
              Local:{" "}
            </label>
            <div className={styles.filter}>
              <Select
                placeholder="Seleccionar local"
                loadOptions={getFilterAppointmentDealerships}
                value={state.checkLead?.local}
                onChange={(opt) => getDataAdd("local", [opt])}
              />
            </div>
          </div>
          <div className={!state.checkLead?.local?.[0]?.value ? 
            `form-inline justify-content-between ${styles.notAllowed}`
            :'form-inline justify-content-between'
            }>
            <DatePicker
              disabled={!state.checkLead?.local?.[0]?.value}
              showTimeSelect
              selected={
                state.checkLead.cita ? new Date(state.checkLead.cita) : null
              }
              onKeyDown={(e) => e.preventDefault()}
              onChange={(date) =>
                setState({
                  ...state,
                  checkLead: {
                    ...state.checkLead,
                    cita: formatDateTimeToISO(date),
                  },
                })
              }
              minTime={minTime}
              maxTime={maxTime}
              excludeTimes={excludeTime}
              timeFormat="HH:mm"
              timeIntervals={30}
              timeCaption="time"
              dateFormat="dd/MM/yyyy - HH:mm"
              placeholderText="Seleccionar cita"
              className={` ${styles.outline}`}
              locale="es"
            />
            <BsFillCalendarEventFill
              size={22}
              style={{ position: "absolute", right: "20px" }}
            />
          </div>
          {!spinner ? (
            <Button
              className={`btn btn-light btn-outline-dark ${styles.bgroup} btn-flexicar`}
              onClick={newAppointment}
            >
              Citar
            </Button>
          ) : (
            <Spinner type="grow" className="flexicarColor" />
          )}
          {state.checkLead.cita ? (
            <Button
              className={`btn btn-light btn-outline-dark ${styles.bgroup} btn-flexicar`}
              onClick={deleteAppointment}
            >
              {" "}
              Borrar Cita
            </Button>
          ) : null}
        </form>
      </div>
    </>
  );
};

export default ModalCitar;
