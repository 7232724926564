import React, { useCallback, useState, useEffect, useContext, useRef } from "react";
import { Row, Col, Button, Label, Input } from "reactstrap";
import { FaFilter } from "react-icons/fa";
import moment from "moment";
import isEqual from "lodash.isequal";

import styles from "./index.module.scss";

import Select from "@components/Select";
import BookingsCalendar from "./BookingsCalendar";
import MultiSelectChipsList from "@components/MultiSelectChipsList";
import { UserContext } from "@contexts/UserContext";
import RolType, { IS_SUPER_ADMIN } from "@constants/roles";
import { API } from "@services/urlConstants";
import instance from "@services/base";
import { getEmployeeLocals } from "@services/Appointments/AppointmentsService";
import { getFilterDealerships } from "@services/Filtros/filtros";
import { BOOKING_CALENDAR_STATES, bookingCalendarList, myBookingsStatus } from "@utils/calendars";
import { getDayNamesAndNumbersInDateRange } from "@utils/momentDate";
import { errorToast } from "@helpers/toastFunctions";

const INITIAL_RESERVATIONS = {
	dealerships: Array.from({ length: 7 }, () => []),
	bookings: Array.from({ length: 7 }, () => [])
};

const Bookings = () => {
	const { local: dealershipsId, user: employeeId, userType, rol } = useContext(UserContext);

	const prevFilterLocales = useRef([]);

	const [weekStart, setWeekStart] = useState(moment().clone().startOf("week"));
	const [weekEnd, setWeekEnd] = useState(moment().clone().endOf("week"));
	const [weekDays, setWeekDays] = useState([]);

	const [reservations, setReservations] = useState(INITIAL_RESERVATIONS);
	const [totalBookings, setTotalBookings] = useState(0);
	const [monthCount, setMonthCount] = useState(0);
	const [hasMonthChanged, setHasMonthChanged] = useState(true);
	const [hasFiltersLocalesChanged, setHasFiltersLocalesChanged] = useState(false);

	const [employeeDealerships, setEmployeeDealerships] = useState([]);

	const [filterLocales, setFilterLocales] = useState([]);
	const [filterEstados, setFilterEstados] = useState([]);
	const [filterList, setFilterList] = useState([]);
	const [showNoReservations, setShowNoReservations] = useState(false);

	const allowedToSwitchNoReservations =
		IS_SUPER_ADMIN(userType) || rol.includes(RolType.TERRITORIAL);

	const getPreviousWeek = () => {
		const newWeekStart = weekStart.clone().subtract(1, "week");
		const newWeekEnd = weekEnd.clone().subtract(1, "week");

		setHasMonthChanged(weekStart.month() !== newWeekStart.month());
		setWeekStart(newWeekStart);

		// NO TOCAR: nadie sabe que hace esto (afecta a las reservas del domingo por alguna razon)
		const magicWeekEnd = moment(
			new Date(Number(`${newWeekEnd.unix().toString().slice(0, -3) + 8000}00`))
		);
		setWeekEnd(magicWeekEnd);
	};

	const getNextWeek = () => {
		const newWeekStart = weekStart.clone().add(1, "week");
		const newWeekEnd = weekEnd.clone().add(1, "week");

		setHasMonthChanged(weekStart.month() !== newWeekStart.month());
		setWeekStart(newWeekStart);

		// NO TOCAR: nadie sabe que hace esto (afecta a las reservas del domingo por alguna razon)
		const magicWeekEnd = moment(
			new Date(Number(`${newWeekEnd.unix().toString().slice(0, -3) + 8000}00`))
		);
		setWeekEnd(magicWeekEnd);
	};

	const getBookings = useCallback(
		async (start, end) => {
			try {
				const payload = {
					start: start,
					end: end,
					locales: filterLocales.length !== 0 ? filterLocales.map((x) => x.value) : dealershipsId,
					ganados: filterEstados.some((x) => x.value === 1),
					reservados: filterEstados.some((x) => x.value === 2),
					gestion: false,
					cancelados: filterEstados.some((x) => x.value === 4),
					reservadosOnline: filterEstados.some((x) => x.value === 5)
				};

				const response = await instance.post(API.BOOKING_CALENDAR, payload);
				// Remove state pago_pasarela from the response data
				const dataWithoutState3 = response.data.res.filter((x) => x.estado !== 3);
				return dataWithoutState3;
			} catch (err) {
				errorToast(err?.response?.data?.message || "Error al cargar las reservas");
			}
		},
		[filterEstados, filterLocales, dealershipsId]
	);

	const getWeeklyBookings = useCallback(async () => {
		setReservations(INITIAL_RESERVATIONS);
		setTotalBookings("");

			const bookings = await getBookings(weekStart.unix(), weekEnd.unix());

			if (bookings) {
				const filteredBookings = bookings.reduce(
					(acc, booking) => {
						if (![3, 4].includes(booking.estado)) acc.total += 1;

						const index = booking.day_of_week - 1;

						if (!acc.data.dealerships[index]) acc.data.dealerships[index] = [];
						if (!acc.data.bookings[index]) acc.data.bookings[index] = [];

						const existingDealershipIndex = acc.data.dealerships[index].findIndex(
							(dealership) => dealership.value === booking.local
						);

						if (existingDealershipIndex === -1)
							acc.data.dealerships[index].push({
								label: booking.local_nombre,
								value: booking.local,
								totalBookings: 1
							});
						else acc.data.dealerships[index][existingDealershipIndex].totalBookings += 1;

						acc.data.bookings[index].push({
							id: booking.id,
							title: `${booking.nombre} / ${booking.vehiculo}`,
							leadId: booking.id_lead,
							clientId: booking.id_cliente,
							state: BOOKING_CALENDAR_STATES[booking.estado],
							stateId: booking.estado
						});

						return acc;
					},
					{
						data: {
							dealerships: Array.from({ length: 7 }, () => []),
							bookings: Array.from({ length: 7 }, () => [])
						},
						total: 0
					}
				);

				const sortByCountAndName = (bookings) => {
					return bookings.sort((a, b) => {
						if (a.totalBookings !== b.totalBookings) {
							return b.totalBookings - a.totalBookings;
						}
						return a.label.localeCompare(b.label);
					});
				};

				filteredBookings.data.dealerships.forEach((dealershipsInDay) => {
					if (dealershipsInDay.length > 0) sortByCountAndName(dealershipsInDay);
				});

				// day_of_week has sunday as 1 so we move it to the end
				setReservations({
					dealerships: [
						...filteredBookings.data.dealerships.slice(1),
						filteredBookings.data.dealerships[0]
					],
					bookings: [...filteredBookings.data.bookings.slice(1), filteredBookings.data.bookings[0]]
				});
				setTotalBookings(filteredBookings.total);

				if (showNoReservations && employeeDealerships.length !== 0) {
					setReservations((prevReservations) => {
						const noReservationsData = prevReservations.dealerships.map((dealershipsInDay) =>
							employeeDealerships
								.filter(
									(employeeDealership) =>
										!dealershipsInDay.some((x) => x.value === employeeDealership.id)
								)
								.map((filteredItem) => ({
									label: filteredItem.nombre,
									value: filteredItem.id,
									totalBookings: 0
								}))
						);
						const noReservationsDataSorted = noReservationsData.map((dealerships) =>
							sortByCountAndName(dealerships)
						);
						return {
							dealerships: [...noReservationsDataSorted],
							bookings: [...prevReservations.bookings]
						};
					});
				}
			}
	}, [getBookings, weekStart, weekEnd, showNoReservations, employeeDealerships]);

	const getMonthlyBookings = useCallback(async () => {
		if (hasMonthChanged || hasFiltersLocalesChanged) {
			setMonthCount("");

			const startOfMonth = weekStart.clone().startOf("month").unix();
			const endOfMonth = weekStart.clone().endOf("month").unix();
			const bookings = await getBookings(startOfMonth, endOfMonth);

			if (bookings) {
				const monthCount = bookings.reduce((acc, booking) => {
					if (![3, 4].includes(booking.estado)) acc += 1;
					return acc;
				}, 0);

				setMonthCount(monthCount);
				setHasMonthChanged(false);
				setHasFiltersLocalesChanged(false);
			}
		}
	}, [hasMonthChanged, hasFiltersLocalesChanged, getBookings, weekStart]);

	const totalBookingsText = !showNoReservations && (
		<div className={styles.totalBookings}>
			<span>Total: </span>
			<span className={styles.bold}>{totalBookings}</span>
		</div>
	);

	const totalDayBookingsText = (count) =>
		!showNoReservations && <span className={styles.numberCircle}>{count}</span>;

	const onDealershipClick = (local, nombre) => {
		if (!showNoReservations) setFilterLocales([{ label: nombre, value: local }]);
	};

	const onBookingClick = (leadId, clientId) =>
		window.open(`/main/lead-venta/${leadId}/${clientId}`, "_blank");

	const handleDeletedDropdownSelected = (element) => {
		const updateFilter = (filter, setFilterList) => {
			const newFilter = filter.filter((item) => item.label.toString() !== element);
			setFilterList(newFilter);
			return newFilter;
		};

		const updatedLocales = updateFilter(filterLocales, setFilterLocales);
		const updatedEstados = updateFilter(filterEstados, setFilterEstados);

		setFilterLocales(updatedLocales);
		setFilterEstados(updatedEstados);
	};

	const deleteFilter = () => {
		setFilterList([]);
		setFilterLocales([]);
		setFilterEstados([]);
	};

	const filters = (
		<div>
			<FaFilter
				size={20}
				color="#007bff"
			/>
			Filtros
			<Select
				isMulti
				placeholder="Locales"
				loadOptions={() => getFilterDealerships([dealershipsId])}
				value={filterLocales}
				onChange={setFilterLocales}
				isDisabled={showNoReservations}
			/>
			<div className="mt-2">
				<Select
					isMulti
					placeholder="Estados"
					options={myBookingsStatus}
					value={filterEstados}
					onChange={setFilterEstados}
					isDisabled={showNoReservations}
				/>
			</div>
			<Button
				outline
				color="danger"
				block
				className="mt-2"
				onClick={deleteFilter}
			>
				BORRAR
			</Button>
			{allowedToSwitchNoReservations && (
				<div className="custom-switch d-flex mt-4 ml-1">
					<Input
						type="checkbox"
						className="custom-control-input"
						id="no-reservations-switch"
						checked={showNoReservations}
						onChange={() => {
							setShowNoReservations(!showNoReservations);
							deleteFilter();
						}}
					/>
					<Label
						className="custom-control-label"
						for="no-reservations-switch"
					>
						Sin Reservas
					</Label>
				</div>
			)}
		</div>
	);

	const legend = (
		<div>
			<div className="mt-3">Leyenda</div>
			<ul className={`${styles.legend}`}>
				{bookingCalendarList(styles).map((filter, idx) => (
					<li key={idx}>
						<span className={filter.class}></span> <p>{filter.label}</p>
					</li>
				))}
			</ul>
		</div>
	);

	useEffect(() => {
		document.title = "Flexicar | Reservas";
	}, []);

	useEffect(() => {
		if (employeeId && allowedToSwitchNoReservations) {
			(async () => {
				try {
					const localsResponse = await getEmployeeLocals(employeeId);
					setEmployeeDealerships(localsResponse);
				} catch (err) {
					errorToast(err?.response?.data?.message || "Error al cargar los locales del usuario");
				}
			})();
		}
	}, [employeeId, allowedToSwitchNoReservations]);

	useEffect(() => {
		getWeeklyBookings();
	}, [getWeeklyBookings]);
	useEffect(() => {
		getMonthlyBookings();
	}, [getMonthlyBookings]);

	useEffect(() => {
		if (!isEqual(prevFilterLocales.current, filterLocales)) {
			prevFilterLocales.current = filterLocales;
			setHasFiltersLocalesChanged(true);
		}
	}, [filterLocales]);

	useEffect(() => {
		const dayNames = getDayNamesAndNumbersInDateRange(weekStart, weekEnd);
		setWeekDays(dayNames);
	}, [weekStart, weekEnd]);

	useEffect(() => {
		const _filterList = [filterLocales, filterEstados].filter((x) => x);
		const filterListFlat = _filterList.flatMap((list) => list.map((item) => item.label.toString()));
		setFilterList([...filterListFlat]);
	}, [filterLocales, filterEstados]);

	return (
		<div>
			<Row className="m-1">
				<Col className={`mt-3 col-12 col-md-2 ${styles.filtersContent}`}>
					{filters}
					{legend}
				</Col>
				<Col className="col-12 col-md-10">
					<div className={`clearfix mt-3 mb-3 ${styles.container2}`}>
						<span className="tittle ml-4">RESERVAS</span>
					</div>
					<div className="d-flex flex-wrap mb-2 mt-1">
						<MultiSelectChipsList
							dropdownSelected={filterList}
							deleteDropdownSelected={handleDeletedDropdownSelected}
						/>
					</div>

					<BookingsCalendar
						weekStart={weekStart}
						getPreviousWeek={getPreviousWeek}
						getNextWeek={getNextWeek}
						weekDays={weekDays}
						isAnyDealershipSelected={filterLocales.length !== 0}
						reservations={reservations}
						monthCount={monthCount}
						totalBookingsText={totalBookingsText}
						totalDayBookingsText={totalDayBookingsText}
						showNoReservations={showNoReservations}
						onDealershipClick={onDealershipClick}
						onBookingClick={onBookingClick}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default Bookings;
