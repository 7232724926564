import axios from "axios";
import { API } from "@services/urlConstants";
import { environment } from "@config/environment.config";

export const GetTextTranslate = async (dataToTranslate, targetLanguage) => {
	const lang = targetLanguage === "va" ? "ca" : targetLanguage;

	try {
		const translationRequests = Object.entries(dataToTranslate).map(([key, value]) => {
			if (value === "-")
				return Promise.resolve({ data: { data: { translations: [{ translatedText: "-" }] } } });

			return targetLanguage === "va" && value === "Rojo"
				? Promise.resolve({ data: { data: { translations: [{ translatedText: "Roig" }] } } })
				: axios.post(API.GOOGLE_TRANSLATE(environment.API_KEY_GOOGLE_TRANSLATE), {
						q: value,
						target: lang,
						format: "text"
				  });
		});

		const responses = await Promise.all(translationRequests);

		const translatedData = responses.reduce((acc, response, index) => {
			const key = Object.keys(dataToTranslate)[index];
			acc[key] = response.data.data.translations[0].translatedText;
			return acc;
		}, {});

		return translatedData;
	} catch (error) {
		console.error(error);
	}
};
