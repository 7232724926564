import { useTranslation } from "react-i18next";

import GenericModal from "@components/GenericModal";

const ServiceWorkerUpdateNotification = ({ newVersionAvailable, updateApp }) => {
	const { t } = useTranslation("swUpdateNotification");

	return (
		<GenericModal
			isOpen={newVersionAvailable}
			setIsOpen={() => {}}
			headContent={t("modalTitle")}
			bodyContent={t("modalBody")}
			footerContent={
				<button
					className="btn btn-flexicar-orange"
					onClick={updateApp}
				>
					{t("accept")}
				</button>
			}
		/>
	);
};

export default ServiceWorkerUpdateNotification;
