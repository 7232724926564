export function validaNIF(value) {
    const valueId = value + "";
  
    // has 9 digits?
    if (!/^[0-9]{9}$/.test(valueId)) return false;
  
    // is from a person?
    if (!/^[123]|45|5/.test(valueId)) return false;
  
    // digit check
    let tot =
      valueId[0] * 9 +
      valueId[1] * 8 +
      valueId[2] * 7 +
      valueId[3] * 6 +
      valueId[4] * 5 +
      valueId[5] * 4 +
      valueId[6] * 3 +
      valueId[7] * 2;
    let div = tot / 11;
    
    let mod = tot - parseInt(div) * 11;
    let tst = mod === 1 || mod === 0 ? 0 : 11 - mod;
    return valueId[8] === String(tst);
  }

// Es la misma validación que tiene back en Contrato de Venta PT  
export const NIFValidatorPT = (nif) => {
	const validFirstChars = ['1', '2', '3', '5'];
  
	if (nif.length !== 9) {
	  return false;
	}
  
	if (!validFirstChars.includes(nif[0])) {
	  return false;
	}
  
	const total =
	  nif[0] * 9 +
	  nif[1] * 8 +
	  nif[2] * 7 +
	  nif[3] * 6 +
	  nif[4] * 5 +
	  nif[5] * 4 +
	  nif[6] * 3 +
	  nif[7] * 2;
	const mod11 = Number(total) % 11;
	const checkDigit = mod11 < 2 ? 0 : 11 - mod11;
	return checkDigit === Number(nif[8]);
  };

  