import styles from "@styles/views/SellContract/client-data.module.scss";

import PaneHeader from "@components/PaneHeader";
import FormInput from "@components/Form/Input/FormInput";
import FormSelect from "@components/Form/Select/FormSelect";

const ClientDataPT = ({ provincesOptions }) => {
	return (
		<div>
			<PaneHeader title="Dados do cliente" />
			<div className={styles.container}>
				<FormInput
					name="client.name"
					label="Nome"
				/>
				<FormInput
					name="client.firstSurname"
					label="Primeiro Apelido"
				/>
				<FormInput
					name="client.secondSurname"
					label="Segundo Apelido"
				/>
				<FormInput
					name="client.dni"
					label="NIF"
				/>
				<FormInput
					name="client.phone"
					label="Telefone"
				/>
				<FormInput
					name="client.email"
					label="E-mail"
				/>
			</div>
			<div className={styles.container}>
				<FormSelect
					name="client.province"
					label="Distrito"
					options={provincesOptions}
				/>
				<FormInput
					name="client.town"
					label="Localidade"
					maxLength={45}
				/>
				<FormInput
					name="client.address"
					label="Morada"
					maxLength={45}
				/>
				<FormInput
					name="client.cp"
					label="CP"
				/>
			</div>
		</div>
	);
};

export default ClientDataPT;
