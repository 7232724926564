import styles from "@views/VehicleValidation/components/AdditionalInfo/index.module.scss";

import VehicleValidationAdditionalInfoForm from "@components/forms/VehicleValidationAdditionalInfoForm";

const VehicleValidationAdditionalInfo = ({ type, vehicle, setVehicle, ActionButtons }) => {
	return (
		<main className={styles.vehicle_validation_additional_info__main}>
			<VehicleValidationAdditionalInfoForm
				type={type}
				vehicle={vehicle}
				setVehicle={setVehicle}
				ActionButtons={ActionButtons}
			/>
		</main>
	);
};

export default VehicleValidationAdditionalInfo;
