import React, { useState, useEffect, useCallback } from "react";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
import { errorToast } from "@helpers/toastFunctions";
import { useTranslation } from "react-i18next";
import CommentsWithLink from "@components/CommentsWithLink";

const ModalMensajesLead = ({ lead_id, vehicle_id, client_id, user_id, setModal, status }) => {
	const { t } = useTranslation("modalLead");
	const [mensajes, setMensajes] = useState([]);
	const [nuevoMensaje, setNuevoMensaje] = useState("");

	const getData = useCallback(async () => {
		const response = await post(API.GET_MENSAJES_LEAD, { lead_id, client_id });
		setMensajes(response);
	}, [client_id, lead_id]);

	const saveMesaje = async () => {
		if (!nuevoMensaje.trim()) {
			return errorToast(<span>{t("errors.errorEmptyComment")}</span>);
		}
		const response = await post(API.SAVE_MENSAJE_LEAD, {
			lead_id,
			client_id,
			vehicle_id,
			user_id,
			nuevoMensaje
		});
		if (response) {
			getData();
			setNuevoMensaje("");
		}
	};

	useEffect(() => {
		if (status && lead_id) {
			getData();
		}
	}, [status, lead_id, getData]);

	return (
		<Modal
			className="modal-lg"
			centered
			isOpen={status}
			toggle={() => setModal()}
		>
			<ModalHeader>{t("leadComments")}</ModalHeader>
			<ModalBody className="p-4">
				{mensajes.length !== 0
					? mensajes.map((i, idx) => {
							return (
								<div
									key={`mensajes-lead-${idx}`}
									className="text-right mt-3"
								>
									<CommentsWithLink text={i.comentario} className="commentWithBackground" />
									<img
										alt="logo"
										className="pointer"
										style={{ width: "39px" }}
										title={i.nombre + " el " + moment(i.fecha).format("DD-MM-YYYY HH.mm")}
										src="/images/logo192.png"
									/>
								</div>
							);
					  })
					: "Ningún mensaje encontrado"}
				<hr className="mt-5" />
				<textarea
					className="w-100"
					value={nuevoMensaje}
					onChange={(e) => setNuevoMensaje(e.target.value)}
					onKeyDown={(e) => (e.key === "Enter" ? saveMesaje() : null)}
				></textarea>
			</ModalBody>
			<ModalFooter>
				<button
					type="button"
					className="btn btn-light btn-outline-dark btn-flexicar"
					onClick={() => saveMesaje()}
				>
					{t("send")}
				</button>
				<button
					type="button"
					className="btn btn-light btn-outline-dark btn-flexicar"
					onClick={() => setModal()}
				>
					{t("close")}
				</button>
			</ModalFooter>
		</Modal>
	);
};

export default ModalMensajesLead;
