import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "@styles/components/forms/vehicle-validation-extras-form.module.scss";

import VehicleExtrasForm from "@components/forms/VehicleExtrasForm";
import Form from "@components/Form/Form";
import RouteLeavingGuard from "@components/RouteLeavingGuard/RouteLeavingGuard";
import { putExtras } from "@services/Vehicle/vehicle.service";
import { errorToast, successToast } from "@helpers/toastFunctions";
import {
	mapVehicleValidationExtrasFromForm,
	mapVehicleValidationExtrasToForm
} from "@mappers/forms/vehicleValidationExtrasFormMapper";

const VehicleValidationExtrasForm = ({ type, vehicle, extras, updateExtras, ActionButtons }) => {
	const { t } = useTranslation("forms/vehicleValidationExtrasForm");
	const formRef = useRef(null);

	const [isDirty, setIsDirty] = useState(false);

	const onSubmit = async (formData) => {
		const selectedIds = mapVehicleValidationExtrasFromForm(formData);

		try {
			await putExtras(vehicle.id, selectedIds, type);
			updateExtras(selectedIds);
			formRef.current.reset(formData);
			successToast(t("saveOk"));
		} catch (e) {
			errorToast(<span>{t("saveError")}</span>);
		}
	};

	return (
		<Form
			ref={formRef}
			onSubmit={onSubmit}
			defaultValues={mapVehicleValidationExtrasToForm(extras)}
			className={styles.vehicle_validation_extras_form}
			onFormStateChange={(formState) => setIsDirty(formState.isDirty)}
		>
			<RouteLeavingGuard when={isDirty} />

			<ActionButtons isDirty={isDirty} />

			<VehicleExtrasForm extras={extras} />
		</Form>
	);
};

export default VehicleValidationExtrasForm;
