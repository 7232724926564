/**
 * Transforms an object or an array of objects into a format where each object is represented
 * by an array of `{ label, value }` pairs, based on specified properties of the input object.
 * Defaults to using "name" for `label` and "id" for `value` if not provided.
 *
 * @param {(Object|Object[])} input - The input can be a single object or an array of objects.
 * @param {string} [labelProp="name"] - The property name to use for the `label` field in the output.
 * @param {string} [valueProp="id"] - The property name to use for the `value` field in the output.
 *
 * @returns {(Object[]|Object)} - Returns an array of `{ label, value }` objects if the input is an array of objects.
 *                                Returns a single `{ label, value }` object if the input is a single object.
 *                                Returns an empty array if the input is neither an object nor an array.
 *
 * @example
 * // Single object
 * const singleObject = { name: "John", id: 1, city: "New York" };
 * console.log(transformToLabelValue(singleObject));
 * // Output: { label: 'John', value: 1 }
 *
 * @example
 * // Array of objects
 * const arrayOfObjects = [
 *     { name: "John", id: 1, city: "New York" },
 *     { name: "Jane", id: 2, city: "Los Angeles" }
 * ];
 * console.log(transformToLabelValue(arrayOfObjects));
 * // Output:
 * // [
 * //   { label: 'John', value: 1 },
 * //   { label: 'Jane', value: 2 }
 * // ]
 */
export const parseToRSOptions = (input, labelProp = "name", valueProp = "id") => {
	const transformObject = (obj) => ({
		label: obj[labelProp],
		value: obj[valueProp]
	});

	if (!input) return null;
	if (Array.isArray(input)) return input.map(transformObject);
	if (typeof input === "object" && input !== null) return transformObject(input);

	return [];
};
