import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import styles from "@styles/components/forms/vehicle-additional-info-form.module.scss";

import FormCheckBox from "@components/Form/Checkbox/FormCheckbox";
import FormDatePicker from "@components/Form/DatePicker/FormDatePicker";
import FormFormattedNumberInput from "@components/Form/FormattedNumberInput/FormFormattedNumberInput";
import FormTextArea from "@components/Form/Textarea/FormTextarea";

const VehicleAdditionalInfoForm = () => {
	const { t } = useTranslation("forms/vehicleAdditionalInfoForm");
	const { watch } = useFormContext();

	const watchedCambeltReplace = watch("cambeltReplace");

	return (
		<div className={styles.vehicle_additional_info_form}>
			<h4 className="bold">{t("additionalInformation")}</h4>
			<div className={styles.vehicle_additional_info_form__fields}>
				<FormDatePicker
					name="lastInspectionDate"
					label={t("lastDateRevision")}
					placeholderText={t("lastDateRevisionPlaceholder")}
				/>
				<FormFormattedNumberInput
					name="inspectionKm"
					label={t("lastKmRevision")}
					placeholder={t("lastKmRevisionPlaceholder")}
					suffix=""
					decimalScale={0}
				/>
				<FormDatePicker
					name="acquisitionDate"
					label={t("acquisitionDate")}
					placeholderText={t("acquisitionDatePlaceholder")}
				/>
				<FormCheckBox
					name="cambeltReplace"
					label={t("cambeltReplace")}
				/>
				{watchedCambeltReplace && (
					<FormFormattedNumberInput
						name="cambeltReplaceKm"
						label={t("kmChangeBelt")}
						placeholder={t("kmChangeBeltPlaceholder")}
						suffix=""
						decimalScale={0}
					/>
				)}
			</div>

			<FormTextArea
				name="comment"
				label={t("comments")}
				maxLength={300}
			/>
		</div>
	);
};

export default VehicleAdditionalInfoForm;
