import * as Yup from "yup";

const percentageCourtesy = 10;

const sellContractAgentDiscountSchema = Yup.object({
	total: Yup.number()
		.isNumberWithLessThanNDecimals(2)
		.when(["$sellInfo.pvp"], ([pvp], schema) =>
			schema.max(
				(pvp * percentageCourtesy) / 100,
				`No debe ser superior al ${percentageCourtesy}% del PvP venta`
			)
		),
	transferCost: Yup.object({
		selected: Yup.boolean(),
		amount: Yup.number()
			.min(0, "No se admiten valores negativos")
			.isNumberWithLessThanNDecimals(2)
			.when(
				["$agentDiscount.transferCost.selected", "$sellInfo.ownershipChangeAmount"],
				([transferDiscountSelected, transferCostAmount], schema) =>
					transferDiscountSelected
						? schema.max(
								transferCostAmount,
								"La cortesía no puede ser mayor que el precio del producto"
						  )
						: schema.notRequired()
			)
	}),
	transport: Yup.object({
		selected: Yup.boolean(),
		amount: Yup.number()
			.min(0, "No se admiten valores negativos")
			.isNumberWithLessThanNDecimals(2)
			.when(
				["$agentDiscount.transport.selected", "$sellInfo.transport.delivery.amount"],
				([transportDiscountSelected, transportAmount], schema) =>
					transportDiscountSelected
						? schema.max(
								transportAmount,
								"La cortesía no puede ser mayor que el precio del producto"
						  )
						: schema.notRequired()
			)
	}),
	warranty: Yup.object({
		selected: Yup.boolean(),
		amount: Yup.number()
			.min(0, "No se admiten valores negativos")
			.isNumberWithLessThanNDecimals(2)
			.when(
				["$agentDiscount.warranty.selected", "$sellInfo.warrantyAmount"],
				([warrantyDiscountSelected, warrantyAmount], schema) =>
					warrantyDiscountSelected
						? schema.max(
								warrantyAmount,
								"La cortesía no puede ser mayor que el precio del producto"
						  )
						: schema.notRequired()
			)
	})
});

export { sellContractAgentDiscountSchema };
