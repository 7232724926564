import { useFormContext } from "react-hook-form";

import styles from "@styles/components/Form/switch.module.scss";
import useFormError from "@components/Form/hooks/useFormError";

/**
 * @typedef {object} ExtraFormSwitchProps
 * @property {string} leftText - The text to be displayed on the left side of the switch.
 * @property {string} rightText - The text to be displayed on the right side of the switch.
 */

/**
 * @typedef {React.HTMLProps<HTMLInputElement> & ExtraFormSwitchProps} FormSwitchProps
 */

/**
 * FormSwitch component for rendering a switch with two options
 * @param {FormSwitchProps} props - Props for the FormSwitch component.
 * @returns {JSX.Element} The rendered component.
 */
const FormSwitch = ({ leftText, rightText, ...props }) => {
	const { register } = useFormContext();
	const { markupError } = useFormError(props.name);

	return (
		<>
			<label
				htmlFor={props.name}
				className={styles.switch}
			>
				<input
					id={props.name}
					type="checkbox"
					{...props}
					{...register(props.name)}
				/>
				<span>{leftText}</span>
				<span>{rightText}</span>
			</label>

			{markupError}
		</>
	);
};

export default FormSwitch;
