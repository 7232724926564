export const stockSearch = (search) => ({
	type: "FILTERS_STOCK_SEARCH",
	search
});

export const vehicleValidationSearch = (search) => ({
	type: "FILTERS_VEHICLE_VALIDATION_SEARCH",
	search
});

export const leadsSearch = (search) => ({
	type: "FILTERS_LEADS_SEARCH",
	search
});
