import { getDocgenContract } from "@services/Contract/ContractService";

import getDocgenBody from "@helpers/getDocgenBody";

import { docgenDocumentTypes } from "@constants/docgenDocumentTypes";

const getDocgenTemplate = async (id_contrato, documentType) => {

    const response = await getDocgenBody(id_contrato);

    const payload = { ...response, documentType };

    const templateResponse = await getDocgenContract(payload);

    let template;

    if (documentType === docgenDocumentTypes.HTML) {
        template = templateResponse.data;
        return (document.getElementById("template").innerHTML = (
            template
        ));
    } else {
        template = "data:application/pdf;base64," + templateResponse.data.file;
        return (document.getElementById("templatePDF").src = (
            template
        ));
    };

};

export default getDocgenTemplate;