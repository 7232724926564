import { useCallback, useEffect, useState } from "react";
import { Table } from "reactstrap";

import styles from "./index.module.scss";

import Loading from "@views/Loading/Loading";
import GenericModal from "@components/GenericModal";
import PaneHeader from "@components/PaneHeader";
import Paginator from "@components/Paginator";
import instance from "@services/base";
import { errorToast } from "@helpers/toastFunctions";
import { API } from "@services/urlConstants";
import { formatDateWithoutHour } from "@helpers/formatDate";

const PAGE_SIZE = 10;

export const ModalLeadTable = ({
	isOpen,
	setIsOpen,
	onAddNewLead,
	onSelectLead,
	clientId,
	setShowMClientsTable,
	setShowModalAddLead
}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [leads, setLeads] = useState({});

	const loadData = useCallback(async () => {
		setIsLoading(true);
		try {
			const response = await instance.get(API.GET_PURCHASE_LEADS_BY_CLIENT(clientId, currentPage));
			setLeads(response.data);
		} catch (err) {
			errorToast(err?.response?.data?.message || "Error al cargar los leads");
		} finally {
			setIsLoading(false);
		}
	}, [clientId, currentPage]);

	useEffect(() => {
		loadData();
	}, [loadData]);

	return (
		<GenericModal
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			modalClassName="modal-xl"
			bodyContent={
				<div className={styles.modalContainer}>
					<PaneHeader
						title="Leads de compra"
						buttons={
							<button
								className="btn btn-light btn-outline-dark btn-flexicar btn-flexicar-mobile"
								onClick={() => {
									onAddNewLead(clientId);
									setIsOpen(false);
									setShowMClientsTable(false);
									setShowModalAddLead(false);
								}}
							>
								Nuevo Lead
							</button>
						}
					/>
					{isLoading ? (
						<Loading />
					) : (
						<>
							<Table
								striped
								responsive
								className={styles.table}
							>
								<thead>
									<tr>
										<th>Última acción</th>
										<th>Marca</th>
										<th>Modelo</th>
										<th>Matrícula</th>
										<th>Estado</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{leads.results.map((dataLead, idx) => (
										<tr key={`leads-${idx}`}>
											<td data-label="Última acción">
												{dataLead.lastAction ? formatDateWithoutHour(dataLead.lastAction) : "-"}
											</td>
											<td data-label="Marca">{dataLead.make || "-"}</td>
											<td data-label="Modelo">{dataLead.model || "-"}</td>
											<td data-label="Matrícula">{dataLead.plate || "-"}</td>
											<td data-label="Estado">
												{dataLead.status ? (
													<>
														<span>{dataLead.status.name}</span>
													</>
												) : (
													<span className={styles.grey}>Sin lead</span>
												)}
											</td>
											<td>
												<button
													className="btn btn-light btn-outline-dark btn-flexicar btn-flexicar-mobile"
													onClick={() => onSelectLead(dataLead.id)}
												>
													Seleccionar
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</Table>

							<Paginator
								className={styles.pagination}
								pageSize={PAGE_SIZE}
								totalCount={leads.total}
								currentPage={currentPage}
								onPageChange={setCurrentPage}
							/>
						</>
					)}
				</div>
			}
		/>
	);
};
