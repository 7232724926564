import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import styles from "@styles/components/forms/vehicle-state-form.module.scss";

import FormInput from "@components/Form/Input/FormInput";
import FormSelect from "@components/Form/Select/FormSelect";
import FormCheckBox from "@components/Form/Checkbox/FormCheckbox";
import FormDatePicker from "@components/Form/DatePicker/FormDatePicker";
import FormFormattedNumberInput from "@components/Form/FormattedNumberInput/FormFormattedNumberInput";
import {
	vehicleValidationITVResultOptions,
	vehicleValidationOriginOptions,
	vehicleValidationWarrantyOptions
} from "@constants/selectOptions";
import { errorToast } from "@helpers/toastFunctions";
import { getColors } from "@services/Vehicle/vehicle.service";

const VehicleStateForm = () => {
	const { t, ready } = useTranslation("forms/vehicleStateForm");
	const { watch, formState, reset } = useFormContext();

	const [originOptions, setOriginOptions] = useState([]);
	const [itvResultOptions, setItvResultOptions] = useState([]);

	const watchedWarranty = watch("warranty");

	const getColorsOptions = async () => {
		try {
			const colors = await getColors();
			return colors.map((color) => ({
				label: color.name,
				value: color.id
			}));
		} catch (err) {
			errorToast("Error al cargar los colores de la carrocería");
		}
	};

	useEffect(() => {
		if (
			ready &&
			itvResultOptions.length !== 0 &&
			typeof formState.defaultValues?.itvResult === "number"
		) {
			// Load default option from a number (API response)
			const itvOpt = itvResultOptions.find(
				(opt) => opt.value === formState.defaultValues?.itvResult
			);
			reset({ ...formState.defaultValues, itvResult: itvOpt });
		}
	}, [ready, formState.defaultValues, formState.defaultValues?.itvResult, itvResultOptions, reset]);

	useEffect(() => {
		if (ready) {
			setOriginOptions(vehicleValidationOriginOptions(t));
			setItvResultOptions(vehicleValidationITVResultOptions(t));
		}
	}, [ready, t]);

	return (
		<div className={styles.vehicle_state_form}>
			<h4>{t("title")}</h4>

			<div className={styles.vehicle_state_form__fields}>
				<FormFormattedNumberInput
					name="mileage"
					label={`${t("mileage")}*`}
					placeholder={t("mileage")}
					decimalScale={0}
					suffix=""
				/>

				<FormSelect
					name="warranty"
					label={t("officialBrandWarranty")}
					placeholder={t("officialBrandWarranty")}
					options={vehicleValidationWarrantyOptions}
				/>

				{watchedWarranty?.value === 3 && (
					<FormDatePicker
						name="warrantyDate"
						label={t("officialBrandWarrantyEnd")}
						placeholderText={t("officialBrandWarrantyEnd")}
					/>
				)}

				<FormSelect
					name="origin"
					label={t("origin")}
					placeholder={t("origin")}
					options={originOptions}
				/>
				<FormSelect
					name="color"
					label={`${t("bodyColor")}*`}
					placeholder={t("bodyColor")}
					loadOptions={getColorsOptions}
				/>
				<FormInput
					name="upholsteryColor"
					label={`${t("upholstery")}*`}
					placeholder={t("upholstery")}
				/>
				<FormDatePicker
					name="itvDueDate"
					label={t("itvExpirationDate")}
					placeholderText="Seleccione una fecha"
				/>
				<FormDatePicker
					name="itvLastDate"
					label={t("lastItvDate")}
					placeholderText="Seleccione una fecha"
				/>
				<FormSelect
					name="itvResult"
					label={t("itvResult")}
					placeholder={t("itvResult")}
					options={itvResultOptions}
				/>
			</div>

			<div className={styles.vehicle_state_form__checks}>
				{/* No itv (no se manda) */}
				<FormCheckBox
					name="noItv"
					label={t("noItv")}
				/>
				<FormCheckBox
					name="km0"
					label={t("zeroKm")}
				/>
				<FormCheckBox
					name="uOwner"
					label={t("singleOwner")}
				/>
				<FormCheckBox
					name="imported"
					label={t("imported")}
				/>
			</div>
		</div>
	);
};

export default VehicleStateForm;
