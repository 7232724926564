import React, { useEffect, useState } from "react";

import { Spinner } from "reactstrap";

const LongLoadingSpinner = ({ localeES }) => {
    const [index, setIndex] = useState(0);
    const [pdfLoadingMessage, setPdfLoadingMessage] = useState("");

    const phrasesPdfLoading = localeES ? 
    [
        "Recopilando datos y ajustando detalles...",
        "Revisión de cláusulas y condiciones del contrato...",
        "Comprobación de términos para garantizar la precisión...",
        "Optimización de cada apartado del contrato...",
        "Estableciendo una conexión segura...",
        "Casi listo, validando los últimos datos...",
        "Asegúrese de que todo esté en orden legal y encriptado...",
        "Cifrar la conexión para mayor seguridad...",
        "Generando el documento final con total seguridad..."
    ] :
    [
        "Recolher dados e ajustar detalhes...",
        "Revisão de cláusulas e condições do contrato...",
        "Verificação dos termos para garantir a precisão...",
        "Otimização de cada secção do contrato... ",
        "Estabelecer uma ligação segura...",
        "Quase pronto, a validar os dados mais recentes...",
        "Assegurar que tudo está em ordem legal e encriptado...",
        "Criptografar a ligação para maior segurança...",
        "A gerar o documento final com total segurança... "
    ];

    useEffect(() => {
        setPdfLoadingMessage(phrasesPdfLoading[index]);
            setInterval(() => {
                setIndex((prevIndex) => {
                    const newIndex = (prevIndex + 1) % phrasesPdfLoading.length;
                    setPdfLoadingMessage(phrasesPdfLoading[newIndex]);
                    return newIndex;
                });
            }, 4000);
    }, []);

    return (
        <div className="height1000 flex justify-content-center mt-3">
            <Spinner type="grow" className="flexicarColor" />
            <span className="flexicarColor ml-2">{pdfLoadingMessage}</span>
        </div>
    );
};

export default LongLoadingSpinner;
