export const mapVehicleValidationExtrasToForm = (extras) =>
	extras.reduce((acc, category) => {
		category.items.forEach((item) => {
			acc[item.id] = item.selected;
		});
		return acc;
	}, {});

export const mapVehicleValidationExtrasFromForm = (formData) =>
	Object.entries(formData).reduce((acc, [key, value]) => (value ? [...acc, key] : acc), []);
