import { useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useSearchParam = () => {
	const location = useLocation();
	const history = useHistory();
	const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

	const getSearchParam = (param) => searchParams.get(param);

	const setSearchParam = (param, val = "") => {
		searchParams.set(param, val);
		history.push({
			search: searchParams.toString()
		});
	};

	const removeSearchParam = (param) => {
		searchParams.delete(param);
		history.push({
			search: searchParams.toString()
		});
	};

	return { getSearchParam, setSearchParam, removeSearchParam };
};

export default useSearchParam;
