import { useFormContext } from "react-hook-form";

import styles from "@styles/components/Form/input.module.scss";
import useFormError from "@components/Form/hooks/useFormError";

/**
 * FormInput component for rendering an input element with its label.
 *
 * @param {React.HTMLProps<HTMLInputElement>} inputProps - Props for the input element.
 * @returns {JSX.Element} The rendered component.
 */
const FormInput = ({ ...inputProps }) => {
	const { register } = useFormContext();
	const { markupError } = useFormError(inputProps.name);

	return (
		<div className={styles.customInputWrapper}>
			<label>
				{inputProps.label}
				<input
					className={styles.customInput}
					type="text"
					{...inputProps}
					{...register(inputProps.name)}
				/>
			</label>

			{markupError}
		</div>
	);
};

export default FormInput;
