import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { MdDeleteForever } from "react-icons/md";
import { Input } from "reactstrap";

import styles from "@views/VehicleValidation/components/Preview/index.module.scss"

import { putImages } from "@services/Vehicle/vehicle.service";
import { errorToast } from "@helpers/toastFunctions";

const Preview = ({
  type,
  vehicleId,
  vehicleImages,
  deleteImage,
  updateImageLabel
}) => {
  const { t } = useTranslation('vehicleValidation');
  const [state, setState] = useState({
    previewImages: [],
    dragId: "",
  });

  useEffect(() => {
		if (vehicleImages !== state.previewImages) {
			setState((state) => ({ ...state, previewImages: vehicleImages }));
		}
	}, [vehicleImages, state]);

  const handleOver = (ev) => {
    ev.preventDefault();
  };

  const handleDrag = (ev) => {
    setState({ ...state, dragId: Number(ev.currentTarget.id) });
  };

  const handleDrop = (ev) => {
    ev.preventDefault();
    const { previewImages } = state;
    const { dragId } = state;
    const dragImage = previewImages.find((image) => image.order_id === dragId);
    const dropImage = previewImages.find(
      (image) => image.order_id === Number(ev.currentTarget.id)
    );

    const arr = moveItem(dragImage.order_id - 1, dropImage.order_id - 1);

    setState({
      ...state,
      previewImages: arr,
    });
    const order = arr.map((item) => item.id);
    if (order) {
      try {
        putImages(vehicleId, order, type);
      } catch (e) {
        errorToast(e);
      }
    }
  };
  const moveItem = (from, to) => {
    const { previewImages } = state;
    const f = previewImages.splice(from, 1)[0];
    previewImages.splice(to, 0, f);
    return previewImages;
  };

  useEffect(() => {
    renderPreview();
  // eslint-disable-next-line
  }, [state.dragId]);

  const renderPreview = () => {
    const { previewImages } = state;

    if (previewImages.length > 0) {
      previewImages.forEach((items, index) => {
        items.order_id = index + 1;
      });
    }
  };

  return (
    <>
      <div className={styles.wrapper}>{renderPreview()}</div>
      <p>
        {t('preview.imagesOrder')}
      </p>
      <div
        className=" row masonry-grid"
        data-masonry='{"percentPosition": true }'
      >
        {state.previewImages.length > 0 &&
          state.previewImages.map((element, index) => {
            return (
              <div
                className={styles.gallery}
                key={index}
                id={element.order_id}
                draggable
                onDragOver={(e) => handleOver(e)}
                onDragStart={(e) => handleDrag(e)}
                onDrop={(e) => handleDrop(e)}
                style={{ cursor: "move" }}
              >
                {element.type?.includes("video") ? (
                  <>
                    <span className={styles.principal}>Video</span>
                    <video
                      controls="controls"
                      src={element.url}
                      width="220px"
                      height="120px"
                    />
                  </>
                ) : (
                  <>
                    <span
                      className={
                        element.order_id === 1 ? styles.principal : "d-none"
                      }
                    >
                      Principal
                    </span>
                    <img src={element.url} alt={element.name} />
                  </>
                )}

                <Input
                  placeholder="Categoría"
                  type="select"
                  className={
                    element.labelId !== null && element.labelId !== 0
                      ? "form-control ds-input mt-1 d-flex justify-content-center align-items-center just selected"
                      : "form-control ds-input mt-1 d-flex justify-content-center align-items-center just"
                  }
                  value={element.labelId}
                  onChange={(e) => {
                    updateImageLabel(e.target.value, element.order_id, element.id);
                  }}
                >
                  <option value={2}>{t("exterior")}</option>
                  <option value={3}>{t("inside")}</option>
                </Input>

                <div className={styles.delete}>
                  <MdDeleteForever
                    className="pointer"
                    onClick={() => deleteImage(element.id)}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Preview;
