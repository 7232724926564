import { useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { GrDocumentUpdate, GrTrash } from "react-icons/gr";

import styles from "@styles/components/Form/input-file.module.scss";
import useFormError from "@components/Form/hooks/useFormError";
import Card from "@components/Card";
import { formatFileSize } from "@utils/fileSizeUtils";

/**
 * FormFileInput is a component for upload components
 *
 * @param {React.InputHTMLAttributes<HTMLInputElement>} props - Props for the FormattedNumberInput component.
 * @returns {JSX.Element} The rendered component.
 */

const FormInputFile = (props) => {
	const { control, watch, setValue } = useFormContext();
	const { errorMessage } = useFormError(props.name);
	const fileInputRef = useRef(null);

	const file = watch(props.name);

	const handleRemoveFile = () => {
		setValue(props.name, undefined);
		if (fileInputRef.current) {
			fileInputRef.current.value = "";
		}
	};

	return (
		<div className={styles.inputFileContainer}>
			{file ? (
				<Card
					title={file.name}
					subtitle={
						<div className={styles.inputFileCardContent}>
							<span>{formatFileSize(file.size)}</span>
							<GrTrash
								className={styles.inputFileCardContentTrash}
								size={26}
								onClick={handleRemoveFile}
							/>
						</div>
					}
				/>
			) : (
				<label
					htmlFor={props.name}
					className={styles.inputFileLabelContainer}
				>
					<span>Seleccione un archivo</span>
					<GrDocumentUpdate size={64} />
				</label>
			)}

			<Controller
				control={control}
				name={props.name}
				render={({ field: { value, onChange, ref, ...field } }) => {
					return (
						<input
							{...field}
							{...props}
							ref={(e) => {
								ref(e);
								fileInputRef.current = e;
							}}
							id={props.name}
							type="file"
							value={value?.fileName}
							onChange={(event) => {
								props.multiple ? onChange(event.target.files) : onChange(event.target.files[0]);
							}}
						/>
					);
				}}
			/>

			<span className={styles.inputFileError}>{errorMessage}</span>
		</div>
	);
};

export default FormInputFile;
