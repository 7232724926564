import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "@views/VehicleValidation/components/Datos/index.module.scss";

import { UserContext } from "@contexts/UserContext";
import Select from "@components/Select";
import ModalUpdateLocalVehicle from "@components/ModalUpdateLocalVehicle";
import VehicleValidationDataForm from "@components/forms/VehicleValidationDataForm";
import { putLocal } from "@services/Vehicle/vehicle.service";
import { getFilterAllDealersihps } from "@services/Filtros/filtros";
import { errorToast, successToast } from "@helpers/toastFunctions";
import RolType from "@constants/roles";

const VehicleValidationData = ({
	type,
	vehicle,
	setVehicle,
	getVehicle,
	version,
	setVersion,
	ActionButtons
}) => {
	const { rol } = useContext(UserContext);
	const { t } = useTranslation("vehicleValidation");

	const [showChangeLocalModal, setShowChangeLocalModal] = useState({
		open: false
	});

	const changeDealer = async ({ dealer }) => {
		try {
			await putLocal({
				id: vehicle.id,
				type: type,
				idDealer: dealer[0].value
			});
			successToast(`Vehículo trasladado `);
			setVehicle((prev) => ({
				...prev,
				dealership: { id: dealer[0].value, name: dealer[0].label }
			}));
			setShowChangeLocalModal({ open: false });
		} catch (err) {
			errorToast(err?.response?.data?.message || t("errors.transfer"));
		}
	};

	return (
		<main className={styles.vehicle_validation_data__main}>
			<VehicleValidationDataForm
				type={type}
				vehicle={vehicle}
				getVehicle={getVehicle}
				version={version}
				setVersion={setVersion}
				ActionButtons={ActionButtons}
			/>

			{/* Ubicacion */}
			<div className={styles.vehicle_validation_data__location}>
				<h4>{t("tabs.data.location")}</h4>

				<div>
					<div>
						<label>{t("tabs.data.location")}*</label>
						<Select
							placeholder="Empresas"
							loadOptions={getFilterAllDealersihps}
							value={{ label: vehicle.dealership?.name, value: vehicle.dealership?.id }}
							isDisabled
						/>
					</div>

					{rol?.some(
						(e) =>
							e === RolType.TRADING_MANAGER_PILOT ||
							e === RolType.TRADING_MANAGER ||
							e === RolType.GENERAL_ADMIN
					) && (
						<button
							className="btn btn-flexicar-orange"
							onClick={() => setShowChangeLocalModal({ open: true })}
						>
							{t("tabs.data.moveVehicle")}
						</button>
					)}
				</div>
			</div>

			{showChangeLocalModal && (
				<ModalUpdateLocalVehicle
					changeLocal={showChangeLocalModal}
					setChangeLocal={setShowChangeLocalModal}
					createVehicleDraft={changeDealer}
				/>
			)}
		</main>
	);
};

export default VehicleValidationData;
