import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";

import { Button, Modal, Spinner } from "reactstrap";

import { errorToast } from "@helpers/toastFunctions";
import ejsTemplateRender from "@helpers/ejsTemplateRender";
import getDocgenTemplate from "@helpers/getDocgenTemplate";
import { docgenDocumentTypes } from "@constants/docgenDocumentTypes";
import LongLoadingSpinner from "@components/LongLoadingSpinner/LongLoadingSpinner";
import Locale from "@constants/Locale";


const Ejs = () => {
  const [openPDF, setOpenPDF] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const { t } = useTranslation('ejs');

  const { i18n } = useTranslation();

  const spanishContractProtection = i18n.language === Locale.ES;

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const factura_id = urlParams.get("factura");
    document.title = `Flexicar | ${factura_id ? factura_id : "Documento"}`;
    const factura_fecha = urlParams.get("fecha");
    if (factura_id && factura_fecha) {
      getTemplate(factura_id, factura_fecha);
    } else {
      getTemplate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (openPDF) {
      getTemplate();
    }
  }, [openPDF]);

  const getTemplate = async (factura_id, factura_fecha) => {
    const urlParam = window.location.pathname.split("/");
    const contractId = urlParam[urlParam.length - 1];

    try {

      const dataTemplate = { factura_id, factura_fecha, contractId };

      if (spanishContractProtection) {
        await ejsTemplateRender(5, dataTemplate);
      } else {
        if (openPDF) {
          setSpinner(true);
          await getDocgenTemplate(contractId, docgenDocumentTypes.JSON);
        } else {
          setSpinner(true);
          await getDocgenTemplate(contractId, docgenDocumentTypes.HTML);
        }
      };
    } catch {
      return errorToast(<span>{t("errors.errorContractExists")} {contractId}</span>);
    } finally {
      setSpinner(false);
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onClickPrint = () => {
    if (spanishContractProtection) {
      handlePrint();
    } else {
      setOpenPDF(true);
    }
  };

  return (
    <div>
      {spinner && !openPDF && (
        <LongLoadingSpinner localeES={spanishContractProtection} />
      )}
      <Modal className={"modal-xl"} isOpen={openPDF} toggle={() => !spinner && setOpenPDF(!openPDF)}>
        {spinner && (
          <LongLoadingSpinner localeES={spanishContractProtection} />
        )}
        <iframe width='100%' height='1000px' id="templatePDF"></iframe>
      </Modal>
      {!spinner && <Button
        className="btn-flexicar mt-4 mr-5 right no-print"
        onClick={() => onClickPrint()}
      >
        {t("printContract")}
      </Button>}
      <br />
      <div ref={componentRef} className={spanishContractProtection ? "m-5" : "docgenTemplate m-5"} id="template"></div>
    </div>
  );
};

export default Ejs;
