import React, { useContext } from "react";

import { Button } from "reactstrap";
import FilterDaysInStock from "@components/FilterDaysInStock/index.js";
import FilterFromTo from "@components/FilterFromTo";
import FilterCheckbox from "@components/FilterCheckbox";
import styles from "./index.module.scss";
import FilterFromToAnio from "@components/FilterFromToAnio";
import { UserContext } from "@contexts/UserContext";
import Select from "@components/Select";
import {
	getFilterCarroceria,
	getFilterFuel,
	getFilterGVOBrands,
	getFilterGVOModels,
	getFilterProvinces,
	getFilterSeats,
	getFilterStockCompanies,
	getFilterStockDealerships,
	getFilterTransmission,
  getFilterGestionType
} from "@services/Filtros/filtros";
import { getYearArray } from "@utils/getYearArray";

const valuesForFinancedPrice = [
  5000, 7500, 10000, 12500, 15000, 20000, 25000, 30000, 35000, 40000, 45000,
  50000,
];
const valuesCvPower= [50, 100, 150, 200, 250, 300]

const FiltersBlock = ({
  state,
  getData,
  getData_From_To,
  getData_Days,
  getData_IVA,
  getData_Disponible,
  getData_Reservado,
  getData_Historical,
  reset,
  brandDisabled,
  brandList,
}) => {
  const { rol } = useContext(UserContext);

  return (
    <>
      <Button outline color="danger" block onClick={() => reset()}>
        BORRAR
      </Button>
      <br />
      {rol?.some(
        (e) =>
          e === 1 ||
          e === 7 ||
          e === 18 ||
          e === 19 ||
          e === 9 ||
          e === 22 ||
          e === 8 ||
          e === 26 ||
          e === 15 ||
          e === 16 ||
          e === 37
      ) ? (
        <FilterCheckbox
          description=" Histórico"
          value={state.filterHistorical.value}
          query_val={"aplica_historico"}
          name={"filterHistorical"}
          sendData={getData_Historical}
        />
      ) : (
        ""
      )}

      <FilterCheckbox
        description=" Iva deducible"
        value={state.filterIVA.value}
        query_val={"aplica_iva"}
        name={"filterIVA"}
        sendData={getData_IVA}
      />
      <br />

      <div
        className={`d-flex ${
          state.filterHistorical.value ? styles.inactive : ""
        }`}
      >
        <FilterCheckbox
          description=" Disp. "
          value={state.filterDisponible}
          query_val={"aplica_disponible"}
          name={"filterDisponible"}
          sendData={getData_Disponible}
        />
        <FilterCheckbox
          description=" Reserv."
          value={state.filterReservado}
          query_val={"aplica_reservado"}
          name={"filterReservado"}
          sendData={getData_Reservado}
        />
      </div>

      <br />
      <Select
        isMulti
        placeholder="Marcas"
        loadOptions={getFilterGVOBrands}
        value={state.filters.Marcas}
        onChange={(opts) => getData("Marcas", opts)}
      />
      <br />

      <Select
        isMulti
        reloadOptions
        placeholder="Modelos"
        loadOptions={() => getFilterGVOModels([brandList])}
        value={state.filters.Modelos}
        onChange={(opts) => getData("Modelos", opts)}
        isDisabled={brandDisabled}
      />
      <br />
      <Select
        isMulti
        placeholder="Locales"
        loadOptions={getFilterStockDealerships}
        value={state.filters.Locales}
        onChange={(opts) => getData("Locales", opts)}
      />
      <br />
      <Select
        isMulti
        placeholder="Tipo de Gestión"
        loadOptions={getFilterGestionType}
        value={state.filters.TipoGestion}
        onChange={(opts) => getData("TipoGestion", opts)}
      />
      <br />
      <Select
        isMulti
        placeholder="Provincias"
        loadOptions={getFilterProvinces}
        value={state.filters.Provincias}
        onChange={(opts) => getData("Provincias", opts)}
      />
      <br />
      <Select
        isMulti
        placeholder="Carroceria"
        loadOptions={getFilterCarroceria}
        value={state.filters.Carroceria}
        onChange={(opts) => getData("Carroceria", opts)}
      />
      <br />
      <Select
        isMulti
        placeholder="Combustible"
        loadOptions={getFilterFuel}
        value={state.filters.Combustible}
        onChange={(opts) => getData("Combustible", opts)}
      />
      <br />
      <Select
        isMulti
        placeholder="Transmision"
        loadOptions={getFilterTransmission}
        value={state.filters.Transmision}
        onChange={(opts) => getData("Transmision", opts)}
      />
      <br />
      <Select
        isMulti
        placeholder="Plazas"
        loadOptions={getFilterSeats}
        value={state.filters.Plazas}
        onChange={(opts) => getData("Plazas", opts)}
      />
      <br />
      <FilterFromTo
        className="col-6"
        sendData={getData_From_To}
        value={state.cv.greater}
        refr={"cv"}
        name={"CV desde"}
        query_val={"potencia"}
        values={valuesCvPower}
        str={"Desde "}
        symbol={">="}
      />
      <FilterFromTo
        className="col-6"
        sendData={getData_From_To}
        value={state.cv.smaller}
        refr={"cv"}
        name={"CV hasta"}
        query_val={"potencia"}
        values={valuesCvPower}
        str={"Hasta "}
        symbol={"<="}
      />
      <br />
      <Select
        isMulti
        placeholder="Empresa Stock"
        loadOptions={getFilterStockCompanies}
        value={state.filters.EmpresaStock}
        onChange={(opts) => getData("EmpresaStock", opts)}
      />
      <br />
      <FilterFromTo
        className="col-6"
        sendData={getData_From_To}
        value={state.km.greater}
        refr={"km"}
        name={"Kilómetros desde"}
        query_val={"kilometros"}
        values={[5000, 25000, 50000, 100000, 150000, 200000]}
        str={"Más de "}
        symbol={">"}
      />
      <FilterFromTo
        className="col-6"
        sendData={getData_From_To}
        value={state.km.smaller}
        refr={"km"}
        name={"Kilómetros hasta"}
        query_val={"kilometros"}
        values={[5000, 25000, 50000, 100000, 150000, 200000]}
        str={"Menos de "}
        symbol={"<"}
      />
      <br />
      <FilterFromToAnio
        className="col-6"
        sendData={getData_From_To}
        value={state.anio.greater}
        refr={"anio"}
        name={"Año desde"}
        query_val={"anio"}
        values={getYearArray(2010)}
        str={"Desde "}
        symbol={">="}
      />
      <FilterFromToAnio
        className="col-6"
        sendData={getData_From_To}
        value={state.anio.smaller}
        refr={"anio"}
        name={"Año hasta"}
        query_val={"anio"}
        values={getYearArray(2010)}
        str={"Hasta "}
        symbol={"<="}
      />
      <br />

      <FilterFromTo
        className="col-6"
        sendData={getData_From_To}
        value={state.euros.greater}
        refr={"euros"}
        name={"Precio contado desde"}
        query_val={"pvp_contado"}
        values={[5000, 7500, 10000, 12500, 15000, 20000]}
        str={"Más de "}
        symbol={">"}
      />
      <FilterFromTo
        className="col-6"
        sendData={getData_From_To}
        value={state.euros.smaller}
        refr={"euros"}
        name={"Precio contado hasta"}
        query_val={"pvp_contado"}
        values={[5000, 7500, 10000, 12500, 15000, 20000]}
        str={"Menos de "}
        symbol={"<"}
      />
      <br />

      <FilterFromTo
        className="col-6"
        sendData={getData_From_To}
        value={state.euros.greater}
        refr={"euros"}
        name={"Precio finan. desde"}
        query_val={"pvp_financiado"}
        values={valuesForFinancedPrice}
        str={"Más de "}
        symbol={">"}
      />
      <FilterFromTo
        className="col-6"
        sendData={getData_From_To}
        value={state.euros.smaller}
        refr={"euros"}
        name={"Precio finan. hasta"}
        query_val={"pvp_financiado"}
        values={valuesForFinancedPrice}
        str={"Menos de "}
        symbol={"<"}
      />
      <br />
      <FilterDaysInStock
        sendData={getData_Days}
        value={state.mod?.smaller}
        name={"Días modificación desde"}
        values={[10, 20, 30, 40]}
        str={"Más"}
        symbol={"<"}
        column={"update_price"}
      />

      <FilterDaysInStock
        className="col-6"
        sendData={getData_Days}
        value={state.mod?.greater}
        name={"Días modificación hasta"}
        values={[10, 20, 30, 40]}
        str={"Menos"}
        symbol={">"}
        column={"update_price"}
      />
      <br />
      <FilterDaysInStock
        sendData={getData_Days}
        value={state.days_in_stock.smaller}
        name={"Días en stock desde"}
        values={[30, 60, 90, 120, 150, 180, 365]}
        str={"Más"}
        symbol={"<"}
        column={"fecha_alta"}
      />
      <FilterDaysInStock
        className="col-6"
        sendData={getData_Days}
        value={state.days_in_stock.greater}
        name={"Días en stock hasta"}
        values={[30, 60, 90, 120, 150, 180, 365]}
        str={"Menos"}
        symbol={">"}
        column={"fecha_alta"}
      />
    </>
  );
};

export default FiltersBlock;
