import { useEffect, useRef } from "react";

/**
 * Custom hook that executes a useEffect only after the first render.
 *
 * @param {Function} effect - The effect function that will be executed after the first render.
 * @param {Array} deps - The dependency array for useEffect, which determines when the effect should re-run.
 */
const useEffectAfterFirstRender = (effect, deps) => {
	const isFirstRender = useRef(true);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}
		effect();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
};

export default useEffectAfterFirstRender;
