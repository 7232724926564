import moment from "moment";

/**
 * Get an array of days as strings between two moment dates.
 *
 * @param {string | Date | moment} startDate - The start date in 'YYYY-MM-DD' format, Date object, or moment object.
 * @param {string | Date | moment} endDate - The end date in 'YYYY-MM-DD' format, Date object, or moment object.
 * @returns {Array<string>} An array of strings, each in the format "DayName DayNumber".
 */
export const getDayNamesAndNumbersInDateRange = (startDate, endDate) => {
	const start = moment.isMoment(startDate) ? startDate : moment(startDate, moment.ISO_8601);
	const end = moment.isMoment(endDate) ? endDate : moment(endDate, moment.ISO_8601);

	if (!start.isValid() || !end.isValid())
		throw new Error("Invalid date provided. Please provide valid dates.");
	if (start.isAfter(end)) throw new Error("Start date must be before or equal to end date.");

	const daysArray = Array.from({ length: end.diff(start, "days") + 1 }, (_, index) => {
		return start.clone().add(index, "days");
	});

	return daysArray.map((currentDate) => {
		const dayName =
			currentDate.format("dddd").charAt(0).toUpperCase() + currentDate.format("dddd").slice(1);
		const dayNumber = currentDate.format("DD");
		return `${dayName} ${dayNumber}`;
	});
};
