import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import { UserContext } from "@contexts/UserContext";
import Loading from "@views/Loading/Loading";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isLoading, isAuthenticated } =
    useContext(UserContext);

  if (isLoading) return <Loading />;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
            <Component {...props} />
          )
         : (
          <Redirect to="/main/login" />
        )
      }
    />
  );
};

export default ProtectedRoute;
