import { UserContext } from "@contexts/UserContext";
import { FaGoogle } from "react-icons/fa";
import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import styles from "./GoogleLogin.module.scss";

export default function GoogleLogin() {
	const { isAuthenticated, logInSocial, error } = useContext(UserContext);

	if (isAuthenticated) return <Redirect to="/main/admin" />;

	return (
		<div className={styles.loginWrapper}>
			<div className="d-flex align-items-center flex-column justify-content-between py-4">
				<img
					src="https://crm-dev.flexicar.es/public/logo.png"
					className="mb-3"
					alt="flexicar-logo"
				/>
				<h2>Acceso con Google</h2>

				<div className="mt-4 d-flex justify-content-center mb-4">
					<button
						className="btn-flexicar d-block"
						onClick={logInSocial}
					>
						<FaGoogle
							className="mr-2 mt-1"
							style={{ verticalAlign: "top" }}
							size={22}
						/>
						<span className="font-weight-bold">CONECTAR</span>
					</button>
				</div>
				<div className="">
					{error && (
						<div
							className="alert alert-danger"
							role="alert"
						>
							<p>{error.message}</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
