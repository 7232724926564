import VehicleValidationSalesForm from "@components/forms/VehicleValidationSalesForm";

import styles from "@views/VehicleValidation/components/Venta/index.module.scss";

const VehicleValidationSales = ({
	type,
	vehicle,
	flexicarVehicleId,
	getVehicle,
	ActionButtons
}) => {
	return (
		<main className={styles.vehicle_validation_sales__main}>
			<VehicleValidationSalesForm
				type={type}
				vehicle={vehicle}
				flexicarVehicleId={flexicarVehicleId}
				getVehicle={getVehicle}
				ActionButtons={ActionButtons}
			/>
		</main>
	);
};

export default VehicleValidationSales;
