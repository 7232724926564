import styles from "@styles/views/SellContract/data-protection.module.scss";

import PaneHeader from "@components/PaneHeader";
import FormCheckBox from "@components/Form/Checkbox/FormCheckbox";

const DataProtectionPT = () => {
	return (
		<section>
			<PaneHeader title="Comunicação e Proteção de Dados" />

			<div className={styles.container}>
				<FormCheckBox
					name="dataProtection.communicationEmail"
					label="Comunicação de Marketing E-mail"
				/>
				<FormCheckBox
					name="dataProtection.communicationSMS"
					label="Comunicação de Marketing SMS"
				/>
				<FormCheckBox
					name="dataProtection.surveysEmail"
					label="Inquéritos de qualidade e satisfação E-mail"
				/>
				<FormCheckBox
					name="dataProtection.surveysSMS"
					label="Inquéritos de qualidade e satisfação SMS"
				/>
			</div>
		</section>
	);
};

export default DataProtectionPT;
