import { errorToast } from "@helpers/toastFunctions";
import instance from "@services/base";
import { API } from "@services/urlConstants";

export const getDocgenContract = async (payload) => {
    try {
        const response = await instance.post(API.DOCGEN_CONTRACT, payload);
        return response;
    } catch (err) {
        errorToast("Erro ao exibir contrato");
    }
}