import { yesOrNotSelectPT } from "@constants/selectOptionsPT";
import * as Yup from "yup";

const reservation = Yup.object({
	wants: Yup.object({
		label: Yup.string(),
		value: Yup.boolean()
	}).required("Campo obrigatório"),
	amount: Yup.number().when(["$payment.reservation.wants"], ([wants], schema) =>
		wants?.value === yesOrNotSelectPT[0].value
			? schema
				.moreThan(0, "O campo deve ser maior que 0")
				.isNumberWithLessThanNDecimals(2, "O campo não pode ter mais de 2 casas decimais")
			: schema.notRequired()
	)
});

const entry = Yup.object({
	wants: Yup.object({
		label: Yup.string(),
		value: Yup.boolean()
	}).required("Campo obrigatório"),
	amount: Yup.number().when(["$payment.entry.wants"], ([wants], schema) =>
		wants?.value === yesOrNotSelectPT[0].value
			? schema
				.moreThan(0, "O campo deve ser maior que 0")
				.isNumberWithLessThanNDecimals(2, "O campo não pode ter mais de 2 casas decimais")
			: schema.notRequired()
	)
});

const sellContractPaymentSchemaPT = Yup.object({
	type: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obrigatório"),
	reservation,
	entry
});

export { sellContractPaymentSchemaPT };
