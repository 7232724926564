import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import styles from "@styles/components/Form/iban-input.module.scss";

import IBANInput from "@components/IBANInput/IBANInput";
import useFormError from "@components/Form/hooks/useFormError";

/**
 * @typedef {Object} Props
 * @property {string} label - The label for the IBAN input field.
 * @property {string} name - The name of the IBAN input field.
 */

/**
 * A component to render an IBAN input field within a form.
 * @param {Props} props - The props object.
 * @returns {JSX.Element} The rendered IBAN input field wrapped in a form control.
 */
const FormIBAN = (props) => {
	const { control } = useFormContext();
	const { markupError } = useFormError(props.name);

	return (
		<div className={styles.customIBANInputWrapper}>
			<label>
				{props.label}

				<Controller
					name={props.name}
					control={control}
					render={({ field }) => <IBANInput {...field} />}
				/>
			</label>

			{markupError}
		</div>
	);
};

export default FormIBAN;
