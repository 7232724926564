import React, { useState } from "react";

import styles from "@views/VehicleValidation/components/Uploader/index.module.scss";

import { Input } from "reactstrap";
import { postImage } from "@services/Vehicle/vehicle.service";
import { errorToast } from "@helpers/toastFunctions";
import { MdOutlineFindInPage } from "react-icons/md";
import { useTranslation } from "react-i18next";

const Uploader = ({ id, type, setImageLoad, appendVehicleImages }) => {
	const { t } = useTranslation("vehicleValidation");
	const [imageValidationError, setImageValidationError] = useState(null);
	const filesSelectedHandler = async (e) => {
		setImageLoad(false);
		if (checkMimeType(e)) {
			const files = Array.from(e.target.files);

			for (const file of files) {
				const formData = new FormData();

				formData.append("image", file, file.name);
				try {
					const response = await postImage(id, formData, type);
					appendVehicleImages({
						id: response.id,
						url: response.url,
						labelId: response.labelId
					});
				} catch (e) {
					errorToast(e);
				}
			}
			await setImageLoad(true);
			e.preventDefault(e);
		}
	};
	const checkMimeType = (event) => {
		const { files } = event.target;
		const types = ["image/png", "image/jpeg", "image/jpg"];
		const invalidExtensions = ["jfif"];
		const maxSize = 2097152; // 2MB

		let error = "";

		for (let x = 0; x < files.length; x++) {
			const { type, size, name } = files[x];

			const fileExtension = name.split(".").pop();
			const isInvalidExtension = invalidExtensions.includes(fileExtension.toLowerCase());

			if (isInvalidExtension) {
				error += `${fileExtension} no es una extensión compatible para ${name}\n`;
				continue;
			}

			if (!types.includes(type)) {
				error += `${type} no es un formato válido para ${name}\n`;
				setImageLoad(true);
			}

			if (size > maxSize) {
				error += `La imagen ${name} debe tener un tamaño inferior a 2MB\n`;
				setImageLoad(true);
			}
		}

		if (error !== "") {
			event.target.files = null;
			errorToast(error);
			setImageValidationError(error);
			return false;
		}

		return true;
	};
	return (
		<div className="row mt-2">
			<div className="col-md-6">
				<div className={styles.uploader}>
					<div>
						<h5>{t("uploader.selectOrDragImages")}</h5>
						<label
							htmlFor="uploader-file-input"
							className="d-flex justify-content-center align-items-center"
						>
							<MdOutlineFindInPage
								title="Buscar archivo"
								size={70}
								color={"#007bff"}
							/>
						</label>
						<Input
							className={styles.uploader__input}
							id="uploader-file-input"
							type="file"
							multiple
							onChange={(event) => {
								filesSelectedHandler(event);
							}}
							label="Seleccion un archivo"
						/>
					</div>

					{imageValidationError && <span className={styles.error}>{imageValidationError}</span>}
				</div>
			</div>
			<div className="col-md-6">{t("uploader.imagesText")}</div>
		</div>
	);
};

export default Uploader;
