import React, { useState, useEffect, useRef, useContext } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useForm, useWatch } from "react-hook-form";
import moment from "moment";
import "./ModalProposal.css";
import { errorToast } from "@helpers/toastFunctions";
import { useReactToPrint } from "react-to-print";
import ModalProposalItem from "@views/ModalProposal/ModalProposalItem/ModalProposalItem";
import { UserContext } from "@contexts/UserContext";
import { useTranslation } from "react-i18next";
import ejsTemplateRender from "@helpers/ejsTemplateRender";

const ModalProposal = ({ setModal, status, client, lead, proposalData, createCommercialProposal, localInfo }) => {
  const { t } = useTranslation("modalProposal");
  const numberWithThousandDots = (str) =>
    str.replace(/[e+-.]/gi, "").replace(/\B(\.\d*)?(?=(\d{3})+(?!\d))/g, ".");

  const { name, phone, email, } = useContext(UserContext);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      id_lead: lead?.id_lead,
      nombre: client?.name || proposalData?.client.name,
      apellidos: client?.firstSurname || proposalData?.client.lastname,
      telefono1: client?.contact.primaryPhone || proposalData?.client.phone,
      email: client?.contact.email || proposalData?.client.email,
      provincia: client?.address?.province?.name || proposalData?.client.province,
      poblacion: client?.address ? client?.address?.municipality : proposalData?.client.location,
      photo: lead?.image_url || proposalData?.car.image,
      nombre_marca_v: lead?.nombre_marca_v || proposalData?.car.make,
      nombre_modelo_v: lead?.nombre_modelo_v || proposalData?.car.model,
      nombre_version_v: lead?.nombre_version_v || proposalData?.car.version,
      anio: lead?.anio || proposalData?.car.year,
      kilometros: lead?.kilometros || proposalData?.car.km,
      puertas: lead?.num_puertas || proposalData?.car.doors,
      combustible: lead?.combustible || proposalData?.car.fuel,
      color: lead?.color || proposalData?.car.color,
      motor: lead?.potencia || proposalData?.car.power,
      seats: lead.num_asientos || proposalData?.car.seats,
      cambio: lead?.transmision || proposalData?.car.gear,
      consumo: lead?.consumo_carretera || proposalData?.car.consume,
      matricula: lead?.matricula || proposalData?.car.license_plate,
      bastidor: lead?.bastidor || proposalData?.car.chassis,
      origen: lead?.procedencia || proposalData?.car.origin,
      precio: numberWithThousandDots((parseFloat(lead?.pvp) || proposalData?.pricing.pvp).toString()),
      gestoria: 390 || proposalData?.pricing.gestoria,
      garantia: lead?.garantia || proposalData?.pricing.warranty_premium,
      precioTotal: lead?.precio_total || proposalData?.pricing.total_pvp,
      totalFinanciado: numberWithThousandDots((parseFloat(lead?.pvp_financiado) || proposalData?.finantial.total_financed).toString()),
      primeraMatricula: moment(lead?.fecha_matriculacion).format("YYYY-MM-DD") || moment(proposalData?.car.matriculation_date).format("YYYY-MM-DD"),
      tin: proposalData?.finantial.tin || 0,
      down_payment: proposalData?.finantial.down_payment || 0,
      fee: proposalData?.finantial.fee || 0,
      term: proposalData?.finantial.term || 0,
      id_agente: lead?.id_agente,
      commercial_name: `${name.nombre} ${name.apellido1} ${name.apellido2}` || proposalData.commercial?.commercial_name,
      commercial_phone: proposalData?.commercial.commercial_phone || phone.replace(/\s+/g, ''),
      commercial_email: proposalData?.commercial.commercial_email || email,
      dealer: proposalData?.commercial.dealer || localInfo?.nombre,
      dealer_phone: proposalData?.commercial.dealer_phone || localInfo.telefono,
      dealer_direction: proposalData?.commercial.dealer_direction || localInfo.direccion,
      observations: proposalData?.observations || '',
      all_risk: proposalData?.pricing.insurance_allrisk || 0,
      oferta: proposalData?.car.offer_pvp || lead.pvp_anterior,
      transport_pvp: proposalData?.pricing.transport_pvp || 0,
    },
  });



  const [variable, setData] = useState({
    contado: lead?.pvp_contado || proposalData?.car.cash_pvp,
    iva: lead?.aplica_iva,
  });

  const pvpWatched = useWatch({
    control,
    name: "precio",
  });

  const gestoriaWatched = useWatch({
    control,
    name: "gestoria",
  });

  const garantiaWatched = useWatch({
    control,
    name: "importe_garantia",
  });

  const transportWatched = useWatch({
    control,
    name: "transport_pvp",
  });


  const mapCommercialData = (data) => {
    return {
      client: {
        name: data.nombre,
        lastname: data.apellidos,
        phone: data.telefono1,
        email: data.email,
        province: data.provincia,
        location: data.poblacion,
      },
      car: {
        image: data.photo || "",
        make: data.nombre_marca_v,
        model: data.nombre_modelo_v,
        version: data.nombre_version_v,
        year: data.anio,
        km: data.kilometros.split(".").join(""),
        doors: data.puertas,
        fuel: data.combustible,
        seats: data.seats,
        color: data.color,
        power: data.motor,
        gear: data.cambio,
        vat: variable.iva === 0 ? false : true,
        license_plate: data.matricula,
        consume: data.consumo,
        chassis: data.bastidor,
        matriculation_date: moment(lead?.fecha_matriculacion).format(
          "YYYY-MM-DD"
        ),
        origin: data.origen,
        cash_pvp: variable.contado,
        offer_pvp: data.oferta,
        financed_pvp: parseFloat(data?.totalFinanciado.toString().replace(",", ".")),
      },
      pricing: {
        pvp: parseFloat(data?.precio.toString().replaceAll(".", "")),
        gestoria: parseFloat(data?.gestoria.toString().replaceAll(".", "")),
        warranty_premium: isNaN(parseFloat(data?.importe_garantia.toString().replaceAll(".", ""))) ? 0
          : parseFloat(data?.importe_garantia.toString().replaceAll(".", "")),
        insurance_allrisk: data.all_risk,
        total_pvp: data?.precioTotal.replaceAll(".", "") || "0",
        transport_pvp: parseFloat(data?.transport_pvp.toString().replaceAll(".", "")),
      },
      finantial: {
        total_financed: parseFloat(data?.totalFinanciado.toString().replaceAll(".", "")),
        tin: parseFloat(data?.tin.toString().replaceAll(".", "")),
        down_payment: parseFloat(data?.down_payment.toString().replaceAll(".", "")),
        fee: parseFloat(data?.fee.toString().replaceAll(".", "")),
        term: data.term,
      },
      commercial: {
        commercial_name: `${name.nombre} ${name.apellido1} ${name.apellido2}`,
        commercial_phone: phone.replace(/\s+/g, ''),
        commercial_email: email,
        dealer: data.dealer,
        dealer_phone: data.dealer_phone,
        dealer_direction: data.dealer_direction
      },
      observations: data.observations,
      lead: data.id_lead,
      id_agente_modify: data.id_agente,
      create_forced: false,
    };
  };


  const onSubmit = async (data) => {
    const commercialData = mapCommercialData(data);
    const { data: res } = await createCommercialProposal(commercialData);
    await gethtmltopdf(commercialData, res.expired_at);
  };


  const maxDate = (value) => {
    const date = new Date(value);
    const today = new Date();
    return date <= today;
  };

  let template;
  const reset = () =>
    (template = document.getElementById("templateModalProposal").innerHTML = <div></div>);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setModal(),
  });


  const gethtmltopdf = async (commercialProposal, expired_at) => {
    try {

      const dataTemplate = { commercialProposal, localInfo, expired_at };

      template = await ejsTemplateRender(3, dataTemplate, "templateModalProposal");

      handlePrint();
    } catch (err) {
      errorToast(<span>{t("errors.errorPrintVehicle")}</span>);
    }
  };

  const createAgain = !proposalData || proposalData.isExpire;

  useEffect(() => {
    const newPvP =
      (parseFloat(pvpWatched.toString().replaceAll(".", "")) || 0) +
      (parseFloat(gestoriaWatched.toString().replaceAll(".", "")) || 0) +
      (parseFloat(garantiaWatched?.toString().replaceAll(".", "")) || 0) +
      (parseFloat(transportWatched?.toString().replaceAll(".", "")) || 0);
    setValue("precioTotal", numberWithThousandDots(newPvP.toString()));
  }, [
    pvpWatched,
    gestoriaWatched,
    garantiaWatched,
    transportWatched,
    setValue,
  ]);


  const validChars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Backspace"];
  const restrictNumericInputs = (e) => {
    if (!validChars.includes(e.key)) e.preventDefault();
  };
  const replaceNumericInputs = (value, inputName) => {
    const justNumbers = /[^0-9 ]/g;
    setValue(inputName, numberWithThousandDots(value.replace(justNumbers, '')));
  };

  return (
    <Modal
      className="modal-xl"
      centered
      isOpen={status}
      toggle={() => {
        setModal()
        reset()
      }} >
      <div style={{ display: "none" }}>
        <div ref={componentRef} className="m-5" id="templateModalProposal">
          {template}
        </div>
      </div>
      <form >
        <ModalHeader toggle={() => {
          setModal();
          reset();
        }
        }>
          <b>
            {proposalData ? `${t('proposalCommercial')} ${proposalData?.car.make} - ${proposalData?.car.model}` : `${t('createProposalCommercial')} ${lead?.nombre_marca_v}`}
            {lead?.nombre_modelo_v} {lead?.matricula}
          </b>
        </ModalHeader>
        <ModalBody className="p-4">
          <div
            className="clearfix mb-3"
            style={{
              padding: ".7rem",
              backgroundColor: "#2C2C2C",
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
              justifyContent: "space-between",
            }}
          >
            <span className="tittle ml-4 mr-auto">{t('clientData')}</span>
          </div>

          <div className="d-flex flex-wrap flex-column flex-md-row">
            <ModalProposalItem
              description={t('name')}
              type="text"
              registerInput={{
                ...register("nombre", {
                  required: true,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroName')}</span>,
              }[errors.nombre?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('surnames')}
              type="text"
              registerInput={{
                ...register("apellidos", {
                  required: true,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroSurnames')}</span>,
              }[errors.apellidos?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('telephone')}
              registerInput={{
                ...register("telefono1", {
                  required: true,
                  minLength: 9,
                  maxLength: 9,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroTelephone')}</span>,
                'minLength': <span className="error">{t('errors.errorIntroTelephoneMssg')}</span>,
                'maxLength': <span className="error">{t('errors.errorIntroTelephoneMssg')}</span>,
              }[errors.telefono1?.type]}
            </ModalProposalItem>
            <ModalProposalItem
              description={t('email')}
              registerInput={{
                ...register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroEmail')}</span>,
                'pattern': <span className="error">{t('errors.errorIntroEmailMssg')}</span>,
              }[errors.email?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('province')}
              type="text"
              registerInput={{
                ...register("provincia", {
                  required: true,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroProvince')}</span>,
              }[errors.provincia?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('population')}
              type="text"
              registerInput={{
                ...register("poblacion", {
                  required: true,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroPopulation')}</span>,
              }[errors.poblacion?.type]}
            </ModalProposalItem>
          </div>

          <div
            className="clearfix mb-3"
            style={{
              padding: ".7rem",
              backgroundColor: "#2C2C2C",
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
              justifyContent: "space-between",
            }}
          >
            <span className="tittle ml-4 mr-auto">{t('vehicleData')}</span>
          </div>

          <div className="d-flex flex-wrap flex-column flex-md-row">
            <ModalProposalItem
              description={t('brand')}
              type="text"
              registerInput={{
                ...register("nombre_marca_v", {
                  required: true,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroBrand')}</span>,
              }[errors.nombre_marca_v?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('model')}
              type="text"
              registerInput={{
                ...register("nombre_modelo_v", {
                  required: true,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroModel')}</span>,
              }[errors.nombre_modelo_v?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('version')}
              type="text"
              registerInput={{
                ...register("nombre_version_v", {
                  required: true,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroVersion')}</span>,
              }[errors.nombre_version_v?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('year')}
              type="text"
              registerInput={{
                ...register("anio", {
                  required: true,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroYear')}</span>,
              }[errors.anio?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('km')}
              type="number"
              registerInput={{
                ...register("kilometros", {
                  required: true,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroKms')}</span>,
              }[errors.kilometros?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('doors')}
              type="number"
              registerInput={{
                ...register("puertas", {
                  required: true,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroDoors')}</span>,
              }[errors.puertas?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('seats')}
              type="number"
              registerInput={{
                ...register("seats", {
                  required: true,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroSeats')}</span>,
              }[errors.seats?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('fuel')}
              type="text"
              registerInput={{
                ...register("combustible", {
                  required: true,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroFuel')}</span>,
              }[errors.combustible?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('color')}
              type="text"
              registerInput={{
                ...register("color", {
                  required: true,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroColor')}</span>,
              }[errors.color?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('motor')}
              registerInput={{
                ...register("motor", {
                  required: true,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroMotor')}</span>,
              }[errors.motor?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('gearshift')}
              type="text"
              registerInput={{
                ...register("cambio", {
                  required: true,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroGearShift')}</span>,
              }[errors.cambio?.type]}
            </ModalProposalItem>

            <div className="modal-item align-self-auto align-self-md-center p-2">
              <span className="tittle_sub mb-2">{t('deductible')}</span>
              <input
                {...register("iva")}
                className="ml-2"
                style={{ verticalAlign: "text-top" }}
                type="checkbox"
                checked={variable.iva}
                onChange={() =>
                  setData((prev) => ({ ...prev, iva: !prev.iva }))
                }
              />
            </div>

            <ModalProposalItem
              description={t('consumption')}
              registerInput={{
                ...register("consumo", {
                  required: true,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroConsumption')}</span>,
              }[errors.consumo?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('licensePlate')}
              registerInput={{
                ...register("matricula", {
                  required: true,
                  pattern: /^[0-9]{1,4}[BCDFGHJKLMNPRSTVWXYZ]{3}$/i,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroLicensePlate')}</span>,
                'pattern': <span className="error">{t('errors.errorIntroLicensePlateMssg')}</span>,
              }[errors.matricula?.type]}
            </ModalProposalItem>
            <ModalProposalItem
              description={t('frame')}
              registerInput={{
                ...register("bastidor", {
                  required: true,
                  pattern: /^\b[(A-H|J-N|P|R-Z|0-9)]{17}\b$/i,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroFrame')}</span>,
                'pattern': <span className="error">{t('errors.errorIntroFrameMssg')}</span>,
              }[errors.bastidor?.type]}
            </ModalProposalItem>

            <div className="d-flex flex-column modal-item p-2">
              <span className="tittle_sub mb-2">{t('firstRegistration')}</span>
              <input
                type="date"
                className={`form-control ${errors.primeraMatricula && "is-invalid"
                  } `}
                placeholder={t('firsRegistration')}
                {...register("primeraMatricula", {
                  required: true,
                  pattern:
                    /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/i,
                  validate: maxDate,
                })}
              />
              {errors.primeraMatricula?.type === "required" && (
                <span className="error">
                  {t('errors.errorIntroFirstRegistration')}
                </span>
              )}
              {errors.primeraMatricula &&
                errors.primeraMatricula?.type !== "required" && (
                  <span className="error">
                    {t('errors.errorIntroFirstRegistrationMssg')}
                  </span>
                )}
            </div>

            <ModalProposalItem
              description={t('origin')}
              registerInput={{
                ...register("origen", {
                  required: true,
                })
              }}
            >
              {{
                'required': <span className="error"> {t('errors.errorIntroOrigin')}</span>,
              }[errors.origen?.type]}
            </ModalProposalItem>


          </div>

          <div
            className="clearfix mb-3"
            style={{
              padding: ".7rem",
              backgroundColor: "#2C2C2C",
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
              justifyContent: "space-between",
            }}
          >
            <span className="tittle ml-4 mr-auto">{t('salePrice')}</span>
          </div>
          <div className="d-flex flex-wrap flex-column flex-md-row">
            <ModalProposalItem
              description={t('pvp')}
              registerInput={{
                ...register("precio", {
                  required: true,
                })
              }}
              onKeyDown={restrictNumericInputs}
              onInput={(e) => replaceNumericInputs(e.target.value, "precio")}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroPvp')}</span>,
              }[errors.precio?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('management')}
              registerInput={{
                ...register("gestoria", {
                  required: true,
                })
              }}
              onKeyDown={restrictNumericInputs}
              onInput={(e) => replaceNumericInputs(e.target.value, "gestoria")}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroManagementPvp')}</span>,
              }[errors.gestoria?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('premWarranty')}

              registerInput={{
                ...register("importe_garantia",
                  {
                    defaultValue: variable.importe_garantia,
                  }
                )
              }}
              onKeyDown={restrictNumericInputs}
              onInput={(e) => replaceNumericInputs(e.target.value, "importe_garantia")}
            />

            {/* Ocultamos el campo hasta que se decida si se va a utilizar
             <ModalProposalItem 
                description="Seguro todo riesgo:"
               
                registerInput={{...register("seguro")}}
            /> */}
            <ModalProposalItem
              description={t('transportationPvp')}
              registerInput={{ ...register("transport_pvp") }}
              onKeyDown={restrictNumericInputs}
              onInput={(e) => replaceNumericInputs(e.target.value, "transport_pvp")}
            />
            <ModalProposalItem
              description={t('totalPVP')}
              registerInput={{
                ...register("precioTotal", {
                  required: true,
                })
              }}
              onKeyDown={restrictNumericInputs}
              onInput={(e) => replaceNumericInputs(e.target.value, "precioTotal")}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroTotalPvp')}</span>,
              }[errors.precioTotal?.type]}
            </ModalProposalItem>
          </div>
          <div
            className="clearfix mb-3"
            style={{
              padding: ".7rem",
              backgroundColor: "#2C2C2C",
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
              justifyContent: "space-between",
            }}
          >
            <span className="tittle ml-4 mr-auto">{t('financingOffer')}</span>
          </div>
          <div className="d-flex flex-wrap flex-column flex-md-row">
            <ModalProposalItem
              description={t('totalFinanced')}
              registerInput={{ ...register("totalFinanciado") }}
              onKeyDown={restrictNumericInputs}
              onInput={(e) => replaceNumericInputs(e.target.value, "totalFinanciado")}
            />

            <ModalProposalItem
              description={t('cheap')}
              registerInput={{ ...register("down_payment") }}
              onKeyDown={restrictNumericInputs}
              onInput={(e) => replaceNumericInputs(e.target.value, "down_payment")}
            />

            <ModalProposalItem
              description={t('fee')}
              registerInput={{ ...register("fee") }}
              onKeyDown={restrictNumericInputs}
              onInput={(e) => replaceNumericInputs(e.target.value, "fee")}
            />

            <ModalProposalItem
              description={t('termInMonths')}
              registerInput={{ ...register("term") }}
            />

            <ModalProposalItem
              description={t('tin')}
              registerInput={{
                ...register("tin", {
                  required: true,
                  min: 3,
                  max: 15,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroTIN')}</span>,
                'min': <span className="error">{t('errors.errorIntroTINMssg1')}</span>,
                'max': <span className="error">{t('errors.errorIntroTINMssg2')}</span>,
              }[errors.tin?.type]}
            </ModalProposalItem>
          </div>
          <div>
            <div>
              <span>
                {t('financingConditions')}
              </span>
            </div>
            <div>
              <span>
                {t('approximateFee')}
              </span>
            </div>
          </div>
          <div
            className="clearfix mb-3"
            style={{
              padding: ".7rem",
              backgroundColor: "#2C2C2C",
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
              justifyContent: "space-between",
            }}
          >
            <span className="tittle ml-4 mr-auto">{t('commercialContactInformation')}</span>
          </div>
          <div className="d-flex flex-wrap flex-column flex-md-row">
            <ModalProposalItem
              description={t('name&Surname')}
              registerInput={{
                ...register("commercial_name", {
                  required: true,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroName&Surname')}</span>,
              }[errors.commercial_name?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('mobilePhone')}
              registerInput={{
                ...register("commercial_phone", {
                  type: "number",
                  required: true,
                  minLength: 9,
                  maxLength: 9,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroTelephone')}</span>,
                'minLength': <span className="error">{t('errors.errorIntroTelephonMssg')}</span>,
                'maxLength': <span className="error">{t('errors.errorIntroTelephoneMssg')}</span>,
              }[errors.commercial_phone?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('email')}
              registerInput={{
                ...register("commercial_email", {
                  type: "email",
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroEmail')}</span>,
                'pattern': <span className="error">{t('errors.errorIntroEmailMssg')}</span>,
              }[errors.commercial_email?.type]}
            </ModalProposalItem>
          </div>

          <div className="d-flex flex-wrap flex-column flex-md-row">
            <ModalProposalItem
              description={t('local')}
              registerInput={{
                ...register("dealer", {
                  type: "text",
                  required: true,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroLocal')}</span>,
              }[errors.dealer?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('localTelephone')}
              registerInput={{
                ...register("dealer_phone", {
                  type: "number",
                  required: true,
                  minLength: 9,
                  maxLength: 9,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroTelephone')}</span>,
                'minLength': <span className="error">{t('errors.errorIntroTelephoneMssg')}</span>,
                'maxLength': <span className="error">{t('errors.errorIntroTelephoneMssg')}</span>,
              }[errors.dealer_phone?.type]}
            </ModalProposalItem>

            <ModalProposalItem
              description={t('localAddress')}
              registerInput={{
                ...register("dealer_direction", {
                  required: true,
                })
              }}
            >
              {{
                'required': <span className="error">{t('errors.errorIntroLocalAddress')}</span>,
              }[errors.dealer_direction?.type]}
            </ModalProposalItem>
          </div>

          <div>
            <span>{`${t('validOfert')}  ${moment().add(7, "days").format("YYYY-MM-DD")}`}</span>
          </div>
          <div
            className="clearfix mb-3"
            style={{
              padding: ".7rem",
              backgroundColor: "#2C2C2C",
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
              justifyContent: "space-between",
            }}
          >
            <span className="tittle ml-4 mr-auto">  {t('observations')}</span>
          </div>
          <div className="col-md-12 col-sm-12">
            <textarea rows="4" cols="50" className="w-100" {...register("observations", {
              type: "textarea"
            })} />
          </div>
        </ModalBody>
        <ModalFooter>
          {createAgain && <button
            type="submit"
            className="btn btn-primary font-weight-bold p-2"
            onClick={handleSubmit(onSubmit)}
          >
            {t('generateProposal')}
          </button>}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ModalProposal;
