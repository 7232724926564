const filtersReducer = (state = {}, action) =>
	({
		FILTERS_STOCK_SEARCH: {
			...state,
			stock: action.search
		},
		FILTERS_VEHICLE_VALIDATION_SEARCH: {
			...state,
			vehicleValidation: action.search
		},
		FILTERS_LEADS_SEARCH: {
			...state,
			leads: action.search
		}
	}[action.type] || state);

export default filtersReducer;
