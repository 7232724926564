import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import styles from "./index.module.scss";
import Mainlayout from "@layouts/MainLayout";
import instance from "@services/base";
import { API } from "@services/urlConstants";
import useSearchParam from "@hooks/useSearchParam";
import { withdrawalReceptionFormSchema } from "@schema/postventaSchema";
import { errorToast } from "@helpers/toastFunctions";
import { get, postWithStatus } from "@services/axiosCalls";
import {
	WARRANTY_OPTIONS,
	typeDocument,
	typeDocumentTranslate
} from "@constants/documentWithdrawReception";
import { UserContext } from "@contexts/UserContext";
import Form from "@components/Form/Form";
import FormInput from "@components/Form/Input/FormInput";
import FormSelect from "@components/Form/Select/FormSelect";
import FormTextArea from "@components/Form/Textarea/FormTextarea";
import FormRadioButtonGroup from "@components/Form/RadioButtonGroup/FormRadioButtonGroup";
import FormEmailAutocomplete from "@components/Form/AutocompleteEmail/FormEmailAutocomplete";
import FormFormattedNumberInput from "@components/Form/FormattedNumberInput/FormFormattedNumberInput";

const WithdrawalReceptionForm = () => {
	const formRef = useRef(null);
	const { vehicleId, caseId, documentId } = useParams();
	const history = useHistory();
	const { getSearchParam } = useSearchParam();
	const { name } = useContext(UserContext);

	const [docType, setDocType] = useState(null);
	const [docTypeText, setDocTypeText] = useState("");
	const [locales, setLocales] = useState([]);

	const loadCreationDefaultValues = async () => {
		const { client, vehicle, warranty, dealership } = await get(
			API.GET_VEHICLE_DRAFT_POST_VENTA(caseId)
		);
		const type = getSearchParam("type");
		setDocType(type);
		setDocTypeText(type);

		return {
			fullName: client.fullName,
			phone: client.phone,
			email: client.email,
			make: vehicle.make.name,
			model: vehicle.model.name,
			plate: vehicle.plate,
			warranty: warranty ? { label: warranty.name, value: warranty.id } : WARRANTY_OPTIONS[0],
			local: { label: dealership.name, value: dealership.id },
			agentFullName: `${name.nombre} ${name.apellido1} ${name.apellido2}`
		};
	};

	const loadEditionDefaultValues = async () => {
		const { data } = await get(API.GET_VEHICLE_POST_VENTA(caseId, documentId));
		setDocType(data.type);
		setDocTypeText(typeDocumentTranslate[data.type]);

		return {
			fullName: data.client.fullName,
			identification: data.client.identification,
			phone: data.client.phone,
			email: data.client.email,
			make: data.vehicle.make.name,
			model: data.vehicle.model.name,
			plate: data.vehicle.plate,
			kmOnDelivery: data.vehicle.kmOnDelivery,
			warranty: data.warranty
				? { label: data.warranty.name, value: data.warranty.id }
				: WARRANTY_OPTIONS[0],
			local: { label: data.dealership.name, value: data.dealership.id },
			agentFullName: `${name.nombre} ${name.apellido1} ${name.apellido2}`,
			afterSaleEmail: data.afterSaleEmail,
			isOwnerVerified: data.isOwnerVerified,
			hasDrivingLicence: data.hasDrivingLicence,
			clientIssue: data.clientIssue || ""
		};
	};

	const onSubmit = async (formData) => {
		const {
			warranty,
			local,
			clientIssue,
			fullName,
			email,
			phone,
			identification,
			kmOnDelivery,
			afterSaleEmail,
			isOwnerVerified,
			hasDrivingLicence
		} = formData;

		// Hacemos esto para pasar null al back y validar el select cuando no esta seleccionado
		// ya que si intentamos pasar null al value da errores
		const realWarranty = warranty.value === 0 ? null : warranty.value;

		const data = {
			client: {
				fullName,
				email,
				phone,
				identification
			},
			vehicle: {
				kmOnDelivery
			},
			warrantyTypeId: realWarranty,
			clientIssue: clientIssue || null,
			dealershipId: local.value,
			afterSaleEmail,
			isOwnerVerified,
			hasDrivingLicence,
			type: !documentId ? typeDocument[docType] : docType
		};
		try {
			const response = !documentId
				? await postWithStatus(API.CREATE_DOCUMENT_POST_VENTA(caseId), data)
				: await instance.put(API.EDIT_DOCUMENT_POST_VENTA(caseId, documentId), data);

			if (response.status === 201 || response.status === 204) {
				history.push(`/main/vehicle/${vehicleId}/caso/${caseId}?tab=docs`);
			}
		} catch (e) {
			errorToast(<span>{e.message}</span>);
		}
	};

	const barButtons = (
		<>
			<button
				className="btn btn-flexicar-inverse"
				onClick={() => history.push(`/main/vehicle/${vehicleId}/caso/${caseId}?tab=docs`)}
			>
				Volver
			</button>
			<button
				className="btn btn-flexicar-orange"
				onClick={() => formRef.current.triggerSubmit(onSubmit)}
			>
				{!documentId ? "Generar" : "Editar"}
			</button>
		</>
	);

	useEffect(() => {
		(async () => {
			try {
				const response = await instance.post(API.FILTROS, {
					dataCall: {
						data_query: "concesionarios_flexicar",
						data_call: null
					}
				});
				setLocales(response.data.map(({ id, nombre }) => ({ label: nombre, value: id })));
			} catch (err) {
				errorToast(err?.response?.data?.message || "Error al cargar los locales");
			}
		})();
	}, [vehicleId, caseId, documentId]);

	return (
		<Mainlayout>
			<Mainlayout.Content
				contentTitle={`Formulario documento ${docTypeText}`}
				buttons={barButtons}
			>
				<Form
					ref={formRef}
					className={styles.form}
					defaultValues={!documentId ? loadCreationDefaultValues : loadEditionDefaultValues}
					schema={withdrawalReceptionFormSchema}
					onSubmit={onSubmit}
				>
					<div>
						<span>Datos del Cliente</span>
						<div className={styles.dataClientWrapper}>
							<FormInput
								label="Nombre completo*"
								name="fullName"
							/>
							<FormInput
								label="DNI/NIE*"
								name="identification"
							/>
							<FormInput
								label="Teléfono*"
								name="phone"
							/>
							<FormEmailAutocomplete
								label="Email*"
								name="email"
							/>
						</div>
					</div>

					<div>
						<span>Datos del vehículo</span>
						<div className={styles.dataVehicleWrapper}>
							<FormInput
								label="Marca"
								name="make"
								disabled
							/>
							<FormInput
								label="Modelo"
								name="model"
								disabled
							/>
							<FormInput
								label="Matrícula"
								name="plate"
								disabled
							/>
							<FormFormattedNumberInput
								label="Km a la entrega*"
								name="kmOnDelivery"
								suffix=""
								placeholder=""
								decimalScale={0}
							/>
							<FormSelect
								label="Garantía*"
								name="warranty"
								options={WARRANTY_OPTIONS}
							/>
						</div>
					</div>

					<div>
						<span>Datos del concesionario</span>
						<div className={styles.dataDealershipWrapper}>
							<FormSelect
								label="Local*"
								name="local"
								options={locales}
							/>
							<FormInput
								label="Agente CC*"
								name="agentFullName"
								disabled
							/>
							<FormInput
								label="Email Postventa*"
								name="afterSaleEmail"
							/>
							<div>
								<div>
									<label>Verificación propietario*</label>
									<FormRadioButtonGroup
										name="isOwnerVerified"
										options={[
											{ label: "Si", value: true },
											{ label: "No", value: false }
										]}
									/>
								</div>
							</div>
							<div>
								<div>
									<label>Verificación permiso de conducir*</label>
									<FormRadioButtonGroup
										name="hasDrivingLicence"
										options={[
											{ label: "Si", value: true },
											{ label: "No", value: false }
										]}
									/>
								</div>
							</div>

							<FormTextArea
								label="Incidencias comentadas por el cliente"
								name="clientIssue"
							/>
						</div>
					</div>
				</Form>
			</Mainlayout.Content>
		</Mainlayout>
	);
};
export default WithdrawalReceptionForm;
