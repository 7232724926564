import styles from "@views/VehicleValidation/components/ExtrasCategory/index.module.scss";

import VehicleValidationExtrasForm from "@components/forms/VehicleValidationExtrasForm";

const VehicleValidationExtras = ({ type, vehicle, extras, updateExtras, ActionButtons }) => {
	return (
		<main className={styles.vehicle_validation_extras__main}>
			<VehicleValidationExtrasForm
				type={type}
				vehicle={vehicle}
				extras={extras}
				updateExtras={updateExtras}
				ActionButtons={ActionButtons}
			/>
		</main>
	);
};

export default VehicleValidationExtras;
