import { createContext, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { get } from "@services/axiosCalls";
import { getUserData, googleLogin } from "@services/Auth/AuthService";
import { getCookie, removeCookie, setCookie } from "@helpers/getCookie";
import { errorToast } from "@helpers/toastFunctions";
import { useGoogleLogin } from "@react-oauth/google";

export const UserContext = createContext();

export function UserContextProvider({ children }) {
	const router = useHistory();

	const [employee, setEmployee] = useState({});
	const [isLoading, setIsLoading] = useState(true);

	const [error, setError] = useState(null);

	const [isAuthenticated, setIsAuthenticated] = useState(getCookie("JWT") ? true : false);
	const logInSocial = useGoogleLogin({
		onSuccess: (codeResponse) => {
			setError(null);
			setIsLoading(true);
			return googleLogin(codeResponse.code)
				.then(({ data }) => {
					setEmployee({
						accessToken: data.access_token,
						refreshToken: data.refresh_token,
						profile: data.profile
					});
					setCookie("JWT", data.jwt, {
						expires: new Date(Date.now() + 24 * 60 * 60 * 1000)
					});

					setIsAuthenticated(true);
					localStorage.setItem("accessToken", data.access_token);
				})
				.catch((error) => {
					setError(error);
					return error;
				})
				.finally(() => {
					setIsLoading(false);
				});
		},
		onError: (error) => {
			setError(error);
		},
		flow: "auth-code"
	});

	const logOut = () => {
		removeCookie("JWT");
		setIsAuthenticated(false);
		setEmployee({});
	};

	const checkUser = useCallback(async () => {
		try {
			const response = await get("/api/employee/validate");
			if (response && response.activo === 0) {
				router.push("/main/login");
			}
		} catch (err) {
			errorToast(err.response?.data?.message);
		}
	}, [router]);

	const loadUser = useCallback(async () => {
		try {
			const user = await getUserData();
			setEmployee(user);
			setIsLoading(false);
			await checkUser();
		} catch (err) {
			errorToast(err.response?.data?.message);
		}
	}, [checkUser]);

	useEffect(() => {
		if (isAuthenticated) loadUser();
	}, [isAuthenticated, loadUser]);

	const value = {
		user: employee.id,
		userType: employee.id_tipo,
		rol: employee.rolesId,
		local: employee.localesId,
		permission: employee.permissionsId,
		name: employee.name,
		phone: employee.telefono1,
		email: employee.email,
		isLoading,
		isAuthenticated,
		logOut,
		logInSocial,
		error
	};

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
