import { usePagination, DOTS } from "@hooks/usePagination";

import styles from "./index.module.scss";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

/**
 * Pagination Component.
 *
 * This component allows for navigating between pages in a list or set of items.
 *
 * @component
 * @param {Object} props - The component's properties.
 * @param {function} props.onPageChange - Function called when the page changes.
 * @param {number} props.totalCount - The total number of items or elements.
 * @param {number} [props.siblingCount=1] - The number of adjacent pages to show around the current page.
 * @param {number} props.currentPage - The current page.
 * @param {number} props.pageSize - The number of items or elements per page.
 * @param {string} [props.className] - Additional CSS classes for the component.
 * @returns {JSX.Element} The pagination component.
 */
const Paginator = ({
	onPageChange,
	totalCount,
	siblingCount = 1,
	currentPage,
	pageSize,
	className
}) => {
	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize
	});

	const lastPage = paginationRange[paginationRange.length - 1];

	const onNext = () => onPageChange(currentPage + 1);
	const onPrevious = () => onPageChange(currentPage - 1);

	if (currentPage === 0 || paginationRange.length < 2) return null;

	return (
		<ul className={`${styles.paginationContainer} ${className ?? ""}`}>
			{/* Left arrow */}
			<li
				className={`${styles.paginationItem} ${currentPage === 1 && styles.disabled}`}
				onClick={onPrevious}
			>
				<MdChevronLeft
					size={22}
					className={styles.arrow}
				/>
			</li>

			{/* Pages */}
			{paginationRange.map((pageNumber) => {
				if (pageNumber === DOTS)
					return (
						<li
							key={`pagination-dots-${pageNumber}`}
							className={`${styles.paginationItem} ${styles.dots}`}
						>
							&#8230;
						</li>
					);

				return (
					<li
						key={`pagination-${pageNumber}`}
						className={`${styles.paginationItem} ${pageNumber === currentPage && styles.selected}`}
						onClick={() => onPageChange(pageNumber)}
					>
						{pageNumber}
					</li>
				);
			})}

			{/* Right arrow */}
			<li
				className={`${styles.paginationItem} ${currentPage === lastPage && styles.disabled}`}
				onClick={onNext}
			>
				<MdChevronRight
					size={22}
					className={styles.arrow}
				/>
			</li>
		</ul>
	);
};

export default Paginator;
