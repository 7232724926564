import { useEffect, useState, useContext } from "react";
import { useFormContext } from "react-hook-form";
import Decimal from "decimal.js";

import styles from "@styles/views/SellContract/sell-data.module.scss";

import {
	getFilterFuel,
	getFilterGVOBrands,
	getFilterGVOModels,
	getFilterTransmission
} from "@services/Filtros/filtros";

import PaneHeader from "@components/PaneHeader";
import FormInput from "@components/Form/Input/FormInput";
import FormSelect from "@components/Form/Select/FormSelect";
import FormFormattedNumberInput from "@components/Form/FormattedNumberInput/FormFormattedNumberInput";
import FormDatePicker from "@components/Form/DatePicker/FormDatePicker";
import FormRadioButtonGroup from "@components/Form/RadioButtonGroup/FormRadioButtonGroup";

import { yesOrNotSelectPT, transportPricesPT } from "@constants/selectOptionsPT";
import { SELL_CONTRACT_PRICE_TYPES } from "@constants/sellContratDefaults";
import RolType, { IS_SUPER_ADMIN } from "@constants/roles";
import { UserContext } from "@contexts/UserContext";
import FormCheckBox from "@components/Form/Checkbox/FormCheckbox";

const SellDataPT = ({ prices, carAsPaymentBrand, warrantyOptions }) => {
	const [modelDisabled, setModelDisabled] = useState(true);
	const [modelOptions, setModelOptions] = useState([]);
	const [brandFromData, setBrandFromData] = useState(undefined);

	const { rol, userType } = useContext(UserContext);

	const { watch, setValue } = useFormContext();

	const priceType = watch("sellInfo.priceType");

	const wantsTransport = watch("sellInfo.transport.wants");
	const wantsCarAsPayment = watch("sellInfo.carAsPayment.wants");
	const brandSelected = watch("sellInfo.carAsPayment.vehicle.brand");

	const warrantyPrice = watch("sellInfo.warranty.type")?.price;

	const transportPrice = watch("sellInfo.transport.type")?.price;

	const pvp = watch("sellInfo.pvp");

	const carPricePercentage = (pvp * 10) / 100;

	const allowedEditPrice = IS_SUPER_ADMIN(userType) || rol.includes(RolType.TERRITORIAL);

	const today = new Date();

	const isBuyAndSell = prices.buyAndSell;

	const administrativesCostsDiscountSelected = watch("agentDiscount.administrativeCosts.selected");
	const transportDiscountSelected = watch("agentDiscount.transport.selected");
	const warrantyDiscountSelected = watch("agentDiscount.warranty.selected");

	const administrativesCostsDiscountAmount = watch("agentDiscount.administrativeCosts.amount");
	const transportDiscountAmount = watch("agentDiscount.transport.amount");
	const warrantyDiscountAmount = watch("agentDiscount.warranty.amount");

	const priceTypeOptions = isBuyAndSell
		? [
			{
				label: "PVD",
				value: SELL_CONTRACT_PRICE_TYPES.PVD
			}
		]
		: [
			{
				label: "PVP Contado",
				value: SELL_CONTRACT_PRICE_TYPES.PVP_CONTADO
			},
			{
				label: "PVP Financiado",
				value: SELL_CONTRACT_PRICE_TYPES.PVP_FINANCIADO,
				disabled: true
			}
		];

	useEffect(() => {
		setValue("sellInfo.warranty.amount", warrantyPrice);
	}, [setValue, warrantyPrice]);

	useEffect(() => {
		setValue("sellInfo.transport.amount", transportPrice);
	}, [setValue, transportPrice]);

	useEffect(() => {
		const pvp = {
			[SELL_CONTRACT_PRICE_TYPES.PVP_CONTADO]: prices.priceCash,
			[SELL_CONTRACT_PRICE_TYPES.PVP_FINANCIADO]: prices.priceOffer || prices.priceFinanced,
			[SELL_CONTRACT_PRICE_TYPES.PVD]: prices.priceDealerSellingOffer || prices.priceDealerSelling
		}[priceType];

		setValue("sellInfo.pvp", pvp);
	}, [setValue, priceType, prices]);

	useEffect(() => {
		if (wantsTransport?.value === yesOrNotSelectPT[1].value) {
			setValue("sellInfo.transport.amount", 0);
			setValue("sellInfo.transport.type", null);
		}
	}, [setValue, wantsTransport?.value, transportPrice]);

	useEffect(() => {
		if (wantsCarAsPayment?.value === yesOrNotSelectPT[1].value) {
			setValue("sellInfo.carAsPayment.amount", 0);
		}
	}, [setValue, wantsCarAsPayment?.value]);

	useEffect(() => {
		setBrandFromData(carAsPaymentBrand);
	}, [carAsPaymentBrand]);

	useEffect(() => {
		if (brandSelected) {
			setModelDisabled(false);
			if (brandFromData !== brandSelected?.label) {
				setValue("sellInfo.carAsPayment.vehicle.model", null);
				setBrandFromData(undefined);
			}
			(async () => {
				const response = await getFilterGVOModels(brandSelected?.value);
				setModelOptions(response);
			})();
		} else {
			setModelDisabled(true);
		}
	}, [setValue, brandSelected, carAsPaymentBrand, brandFromData]);

	useEffect(() => {
		const values = [];
		if (administrativesCostsDiscountSelected) values.push(administrativesCostsDiscountAmount);
		if (transportDiscountSelected) values.push(transportDiscountAmount);
		if (warrantyDiscountSelected) values.push(warrantyDiscountAmount);

		const sumReduce = values.reduce((acc, num) => acc.plus(new Decimal(num)), new Decimal(0));
		setValue("agentDiscount.total", sumReduce.toNumber());
	}, [
		setValue,
		administrativesCostsDiscountSelected,
		transportDiscountSelected,
		warrantyDiscountSelected,
		administrativesCostsDiscountAmount,
		transportDiscountAmount,
		warrantyDiscountAmount
	]);

	return (
		<div>
			<PaneHeader title="Dados da viatura" />
			<div className={styles.container}>
				<div className={styles.priceOptions}>
					<FormRadioButtonGroup
						name="sellInfo.priceType"
						options={priceTypeOptions}
					/>
				</div>
				<div className={styles.module}>
					<FormFormattedNumberInput
						name="sellInfo.pvp"
						label="Preço da viatura (PVP)"
						disabled
					/>

					<FormFormattedNumberInput
						name="agentDiscount.total"
						label="Desconto"
						disabled
					/>
				</div>

				<PaneHeader
					title="Titularidad"
					color="secondary"
				/>
				<div className={styles.module}>
					<FormFormattedNumberInput
						name="sellInfo.administrativeCosts"
						label="Despesas administrativas"
						disabled={!allowedEditPrice}
					/>
					<FormCheckBox
						name="agentDiscount.administrativeCosts.selected"
						label="Desconto para mudança de propriedade"
					/>

					{administrativesCostsDiscountSelected && (
						<FormFormattedNumberInput
							name="agentDiscount.administrativeCosts.amount"
							label="Desconto para mudança de propriedade"
						/>
					)}
				</div>

				<PaneHeader
					title="Transporte"
					color="secondary"
				/>
				<div className={styles.module}>
					<FormSelect
						name="sellInfo.transport.wants"
						label="Transporte"
						options={yesOrNotSelectPT}
					/>
					{wantsTransport?.value === yesOrNotSelectPT[0].value && (
						<>
							<FormSelect
								name="sellInfo.transport.type"
								label="Tipo de transporte"
								options={transportPricesPT(carPricePercentage)}
							/>
							<FormFormattedNumberInput
								name="sellInfo.transport.amount"
								label="Quantia do transporte"
								disabled={!allowedEditPrice}
							/>
							<FormDatePicker
								name="sellInfo.transport.expectedDeliveryDate"
								label="Data Prevista de Entrega"
								minDate={today}
							/>
							<FormCheckBox
								name="agentDiscount.transport.selected"
								label="Desconto para la entrega"
							/>
							{transportDiscountSelected && (
								<FormFormattedNumberInput
									name="agentDiscount.transport.amount"
									label="Desconto para la entrega"
								/>
							)}
						</>
					)}
				</div>

				{!isBuyAndSell && <>
					<PaneHeader
						title="Garantia"
						color="secondary"
					/>
					<div className={styles.module}>
						<FormSelect
							name="sellInfo.warranty.type"
							label="Tipo de Garantia"
							options={warrantyOptions}
						/>
						<FormFormattedNumberInput
							name="sellInfo.warranty.amount"
							label="Quantia da Garantia"
							disabled={!allowedEditPrice}
						/>
						<FormCheckBox
							name="agentDiscount.warranty.selected"
							label="Cortesía comercial para la garantía"
						/>
						{warrantyDiscountSelected && (
							<FormFormattedNumberInput
								name="agentDiscount.warranty.amount"
								label="Cortesía comercial para la garantía"
							/>
						)}
					</div>
				</>}
			<PaneHeader
				title="Retoma"
				color="secondary"
			/>

			<div className={styles.module}>
				<FormSelect
					name="sellInfo.carAsPayment.wants"
					label="Retoma"
					options={yesOrNotSelectPT}
				/>
				{wantsCarAsPayment?.value === yesOrNotSelectPT[0].value && (
					<FormFormattedNumberInput
						name="sellInfo.carAsPayment.amount"
						label="Quantia da Retoma"
					/>
				)}
			</div>

			{wantsCarAsPayment?.value === yesOrNotSelectPT[0].value && (
				<div className={styles.module}>
					<FormSelect
						name="sellInfo.carAsPayment.vehicle.brand"
						label="Marca"
						loadOptions={getFilterGVOBrands}
					/>
					<FormSelect
						name="sellInfo.carAsPayment.vehicle.model"
						label="Modelo"
						options={modelOptions}
						isDisabled={modelDisabled}
					/>
					<FormInput
						name="sellInfo.carAsPayment.vehicle.version"
						label="Versão"
					/>
					<FormInput
						name="sellInfo.carAsPayment.vehicle.plate"
						label="Matrícula"
					/>
					<FormDatePicker
						name="sellInfo.carAsPayment.vehicle.plateDate"
						label="Data matrícula"
					/>
					<FormInput
						name="sellInfo.carAsPayment.vehicle.vin"
						label="Nº de chassis"
					/>
					<FormFormattedNumberInput
						name="sellInfo.carAsPayment.vehicle.kms"
						label="Quilómetros"
						suffix=" Km"
					/>
					<FormInput
						name="sellInfo.carAsPayment.vehicle.color"
						label="Cor"
						maxLength={15}
					/>
					<FormSelect
						name="sellInfo.carAsPayment.vehicle.fuel"
						label="Combustível"
						loadOptions={getFilterFuel}
					/>
					<FormSelect
						name="sellInfo.carAsPayment.vehicle.transmission"
						label="Transmissão"
						loadOptions={getFilterTransmission}
					/>
				</div>
			)}
		  </div>
		</div>
	);
};

export default SellDataPT;
