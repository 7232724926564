import { errorToast } from "@helpers/toastFunctions";
import instance from "@services/base";
import { API } from "@services/urlConstants";

export const getWarrantiesOptions = async (id) => {
	try {
		const response = await instance.get(API.GET_WARRANTIES_BY_VEHICLE(id))
		if (response.data) {
			const responseMapOptions = response.data.map(({ name, id, ...rest }) => {
				return {
					label: name,
					value: id,
					...rest
				};
			});
			return responseMapOptions
		}
	} catch (err) {
		errorToast(err?.response?.data?.message || "Error al obtener las garantías del vehiculo");
	}
}