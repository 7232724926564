import React from "react";
import { MdOutlineLocationOn } from "react-icons/md";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row } from "reactstrap";

import styles from "@views/VehicleValidation/components/ModalPreview/index.module.scss";

const ModalPreview = ({
	showModalPreview,
	setShowModalPreview,
	setShowModalCommentPdf,
	vehicle
}) => {
	return (
		<div>
			<Modal
				isOpen={showModalPreview}
				size="xl"
			>
				<ModalHeader>Resumen de Vehículo</ModalHeader>
				<ModalBody>
					<Row>
						<Col
							xs="6"
							md="6"
						>
							<div width="250px">
								<img
									src={vehicle.images[0]?.url}
									width={"100%"}
									alt="MainPicture"
								/>
							</div>
							<div style={{ position: "relative" }}>
								{vehicle.priceFinanced && vehicle.priceOffer && (
									<p className={styles.tachado}>{vehicle.priceFinanced}€</p>
								)}

								{vehicle.priceOffer && (
									<p className={styles.oferta}>
										<strong> {vehicle.priceOffer}€ </strong>
									</p>
								)}

								{vehicle.priceCash && (
									<p>
										<strong> Precio al contado: {vehicle.priceCash}€ </strong>
									</p>
								)}

								{vehicle.priceFinanced && !vehicle.priceOffer && (
									<p>
										<strong> Precio financiado: {vehicle.priceFinanced}€ </strong>
									</p>
								)}
							</div>
						</Col>

						<Col md="6">
							<h3>
								{vehicle.version.name
									? `${vehicle.make.name} ${vehicle.model.name} ${vehicle.version.name || ""}`
									: "-"}
							</h3>

							<Row>
								<Col md="4">
									<p>Año</p>
									<p>
										<strong>{vehicle.year || "-"}</strong>
									</p>
									<p>Puertas / Plazas</p>
									<p>
										<strong>
											{vehicle.specs.doors || "-"} / {vehicle.specs.seats || "-"}
										</strong>
									</p>
									<p>Cambio</p>
									<p>
										<strong>{vehicle.transmission.name || "-"}</strong>
									</p>
								</Col>

								<Col md="4">
									<p>Kilómetros</p>
									<p>
										<strong>{vehicle.mileage || "-"}</strong>
									</p>
									<p>Motor</p>
									<p>
										<strong>
											{vehicle.specs.cc ? `${vehicle.specs.cc} cc` : "-"} /
											{vehicle.specs.power ? `${vehicle.specs.power} C.V.` : "-"}
										</strong>
									</p>
									<p>Consumo</p>
									<p>
										<strong>
											{vehicle.specs.consumptionMix ? `${vehicle.specs.consumptionMix} l/100` : "-"}
										</strong>
									</p>
								</Col>
								<Col md="4">
									<p>Combustible</p>
									<p>
										<strong>{vehicle.fuel.name || "-"}</strong>
									</p>
									<p>Color</p>
									<p>
										<strong>{vehicle.color?.name || "-"}</strong>
									</p>
									<p>I.V.A. Deducible</p>
									<p>
										<strong>{vehicle.taxDeductible ? "SI" : "NO"}</strong>
									</p>
								</Col>
								<Col
									md="12"
									className={styles.local}
								>
									<MdOutlineLocationOn size={18} />
									{vehicle.dealership?.name || "-"}
								</Col>
							</Row>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button
						color="primary"
						onClick={() => {
							setShowModalCommentPdf(true);
						}}
					>
						Publicar
					</Button>
					<Button
						color="secondary"
						onClick={() => {
							setShowModalPreview((prev) => !prev);
						}}
					>
						Cancelar
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default ModalPreview;
