import React from "react";
import styles from "./index.module.scss";

const CommentsWithLink = ({ text, className }) => {
    const HTTPS = "https://";
    const HTTP = "http://";

    const textArray = Array.isArray(text) ? text : [text];

    const cleanedTextArray = textArray.flatMap((chunk) =>
        chunk.split("\n").map((line) => line.trim()).filter(Boolean)
    );

    return (
        <span className={styles[className]}>
            {cleanedTextArray.map((sentence, sentenceIndex) => (
                <React.Fragment key={sentenceIndex}>
                    {sentence.split(" ").map((word, wordIndex) => (
                        word.startsWith(HTTPS) || word.startsWith(HTTP) ? (
                            <a
                                href={word}
                                key={wordIndex}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {word}
                            </a>
                        ) : (
                            ` ${word} `
                        )
                    ))}
                    <br />
                </React.Fragment>
            ))}
        </span>
    );
};

export default CommentsWithLink;
