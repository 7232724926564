const customStyles = () => {
	const fontColor = "#181818";
	const optionFocusColor = "rgb(239, 239, 239, .45)";
	const optionSelectedColor = "rgba(0, 0, 0, 0.08)";

	return {
		control: (provided, state) => {
			return {
				...provided,
				outline: 0,
				boxShadow: "none",
				backgroundColor: state.isDisabled ? "#e9ecef" : "white"
			};
		},
		singleValue: (provided, state) => {
			return {
				...provided,
				color: state.isDisabled ? "rgb(84, 84, 84)" : "black"
			};
		},
		multiValue: (provided) => {
			return {
				...provided,
				minWidth: "unset",
				backgroundColor: "transparent",
				borderRadius: "32px",
				border: "1px solid #424242",
				".css-xb97g8:hover": {
					backgroundColor: "transparent",
					color: "#212529"
				}
			};
		},
		multiValueLabel: (provided) => {
			return { ...provided, textOverflow: "unset" };
		},
		input: (provided, state) => {
			return {
				...provided,
				...(state.isMulti &&
					state.hasValue && {
						order: 5,
						width: "fit-content"
					})
			};
		},
		option: (provided, state) => {
			let backgroundColor = provided.backgroundColor;
			let active = provided[":active"];
			const cursor = "pointer";
			const color = fontColor;

			if (state.isFocused) {
				backgroundColor = optionFocusColor;
				active = { ...active, backgroundColor };
			}

			if (state.isSelected) {
				backgroundColor = optionSelectedColor;
				active = {
					...active,
					backgroundColor: backgroundColor
				};
			}

			if (state.isFocused && state.isSelected) {
				backgroundColor = optionSelectedColor;
				active = {
					...active,
					backgroundColor: optionSelectedColor
				};
			}

			return {
				...provided,
				":active": active,
				backgroundColor,
				cursor,
				color
			};
		},
		valueContainer: (provided) => {
			return { ...provided, flexWrap: "nowrap" };
		},
		placeholder: (provided) => ({
			...provided,
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis"
		})
	};
};

export default customStyles;
