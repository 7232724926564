import React, { useState, useContext } from "react";
import { Button, Spinner } from "reactstrap";
import styles from "./index.module.scss";
import ModalPossession from "@components/ModalPossession";
import moment from "moment";
import { BsFillCalendarEventFill } from "react-icons/bs";
import DatePicker from "react-datepicker";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import { UserContext } from "@contexts/UserContext";

const CitasActionsVenta = ({
  cita,
  lead_id,
  client_id,
  agente_lead,
  client_phone,
  newAppointment,
  deleteAppointment,
  setCita,
  spinner,
}) => {
  const [modalPossession, setModalPossession] = useState(false);
  const { user, userType } = useContext(UserContext);

  const minTime = setHours(setMinutes(new Date(), 30), 9);
  const maxTime = setHours(setMinutes(new Date(), 1), 20);

  const toggleModalPossession = () => {
    setModalPossession(!modalPossession);
  };

  const handleCitaAction = (action, date) => {
    if (user !== agente_lead && userType !== 3) {
      toggleModalPossession();
    } else {
      if (action === "set") {
        setCita(moment(date).format("YYYY-MM-DD HH:mm"));
      }
      if (action === "new") {
        newAppointment();
      }
      if (action === "delete") {
        deleteAppointment();
      }
    
    }
  };
  return (
    <>
      <ModalPossession
        setModal={toggleModalPossession}
        status={modalPossession}
        lead_id={lead_id}
        client_id={client_id}
        agente_lead={agente_lead}
        client_phone={client_phone}
      />
      <div>
        <div className={`clearfix mb-3 ${styles.titleContainer}`}>
          <span className={`title ${styles.title}`}>Citar</span>
        </div>
        <form>
          <div className="form-inline justify-content-between">
            <DatePicker
              showTimeSelect
              selected={cita ? new Date(cita) : null}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(date) => handleCitaAction("set", date)}
              minTime={minTime}
              maxTime={maxTime}
              timeFormat="HH:mm"
              timeIntervals={30}
              timeCaption="time"
              dateFormat="dd/MM/yyyy - HH:mm"
              placeholderText="Seleccionar cita"
              className={` ${styles.outline}`}
              locale="es"
            />
            <BsFillCalendarEventFill
              size={22}
              style={{ position: "absolute", right: "20px" }}
            />
          </div>
          {!spinner ? (
            <Button
              className={`btn btn-light btn-outline-dark ${styles.bgroup} btn-flexicar`}
              onClick={() => handleCitaAction("new")}
            >
              Citar
            </Button>
          ) : (
            <Spinner type="grow" className="flexicarColor" />
          )}
          {cita ? (
            <Button
              className={`btn btn-light btn-outline-dark ${styles.bgroup} btn-flexicar`}
              onClick={() => handleCitaAction("delete")}
            >
              Borrar Cita
            </Button>
          ) : null}
        </form>
      </div>
    </>
  );
};

export default CitasActionsVenta;
