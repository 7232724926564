import DocumentationType from "@constants/documentationType";

export const mapVehicleValidationSalesToForm = (vehicle) => ({
	priceCash: vehicle.priceCash,
	priceFinanced: vehicle.priceFinanced,
	offer: vehicle.priceOffer ? true : false,
	priceOffer: vehicle.priceOffer,
	minimumInterestRate: 9.99,
	minimumMonths: 72,
	maximumInitialContribution: 10,
	transportAmount: 290,
	flexicarWarrantyAmount: 500,
	transferAmount: 290,
	priceBought: vehicle.priceBought,
	chargeFree: vehicle.chargeFree,
	taxDeductible: vehicle.taxDeductible,
	buyAndSell: vehicle.buyAndSell,
	salesManagement: !vehicle.owned || vehicle.tipo_contrato === DocumentationType.GESTION_VENTA,
	priceDealerSelling: vehicle.priceDealerSelling,
	estimatedPrice: vehicle.trading?.estimatedPrice ?? null,
	commission: vehicle.trading?.commission ?? null,
	penalty: vehicle.trading?.penalty ?? null
});

export const mapVehicleValidationSalesFromForm = (formData) => ({
	priceCash: formData.priceCash || null,
	priceFinanced: formData.priceFinanced || null,
	priceOffer: formData.offer && formData.priceOffer !== 0 ? formData.priceOffer : null,
	chargeFree: formData.chargeFree,
	taxDeductible: formData.taxDeductible,
	buyAndSell: formData.buyAndSell,
	priceDealerSelling:
		formData.buyAndSell && formData.priceDealerSelling !== 0 ? formData.priceDealerSelling : null,
	trading: {
		estimatedPrice: formData.estimatedPrice || null,
		commission: formData.commission || null,
		penalty: formData.penalty || null
	}
});
