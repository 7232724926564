import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import { HiCheck, HiX } from "react-icons/hi";
import styles from "./index.module.scss";
import { formatDateWithoutHour } from "@helpers/formatDate";
import LeadTypes from "@constants/LeadTypes";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";


export const ModalClientsTable = ({
	isOpen,
	setIsOpen,
	clientsInfo,
	onAddNewClient,
	onSelectClient,
	leadType
}) => {
	const { t } = useTranslation("modalClient");

	const history = useHistory();

	const handleAddNewClient = () => {
		setIsConfirmView(true);
	};

	const handleConfirm = () => {
		setIsConfirmView(false);
		onAddNewClient();
	};

	const [isConfirmView, setIsConfirmView] = useState(false);


	const allClientsHaveEmail = clientsInfo.every(client => client.contact && client.contact.email);

	const handleOpen = () => {
		setIsOpen(false);
		setIsConfirmView(false);
		if (leadType === LeadTypes.COMPRA) {
			history.push("/main/listaleads?section=todos");
		}
	};
	

	return (
		<Modal
            className="modal-xxl"
            centered
            isOpen={isOpen}
            toggle={handleOpen}
        >
            <ModalHeader toggle={handleOpen}>{t("chooseClientTitle")}</ModalHeader>
            <ModalBody className="p-8">
				{isConfirmView ? (
					<div>
						<p>{t("confirmationMessage")}</p>
					</div>
				) : (
					<div className={styles.modalContainer}>
						<div className={styles.tableContainer}>
							<Table
								striped
								responsive
								size="sm"
								className={styles.textTable}
							>
								<thead>
									<tr>
										<th>{t("nameHeader")}</th>
										<th>{t("phoneHeader")}</th>
										<th>{t("mailHeader")}</th>
										{leadType === LeadTypes.VENTA && (
											<>
												<th>{t("lastActionHeader")}</th>
												<th>{t("vehicleHeader")}</th>
											</>
										)}
										<th>{t("statusHeader")}</th>
										<th>{t("zcHeader")}</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									{clientsInfo.map((dataClient, idx) => (
										<tr key={`clients-${idx}`}>
											<td data-label="Nombre del Cliente">
												<span>{`${dataClient.name} ${dataClient.firstSurname || ""}`}</span>
											</td>
											<td data-label="Teléfono">
												<span>{dataClient.contact.primaryPhone}</span>
											</td>
											<td data-label="Email">
												<span>{dataClient.contact.email}</span>
											</td>
											{leadType === LeadTypes.VENTA && (
												<>
													<td data-label="Fecha Creación Lead">
														<span>
															{dataClient.sellLead?.updatedAt
																? formatDateWithoutHour(dataClient.sellLead?.updatedAt)
																: "-"}
														</span>
													</td>
													<td data-label="Vehiculo">
														<span>{`${dataClient.sellLead?.vehicle?.brand || "-"} ${
															dataClient.sellLead?.vehicle?.model || ""
														} `}</span>
													</td>
												</>
											)}
											<td data-label="Estado">
												{dataClient.sellLead?.statusName || dataClient.purchaseLead?.statusName ? (
													<span>
														{dataClient.sellLead?.statusName || dataClient.purchaseLead?.statusName}
													</span>
												) : (
													<span className={styles.grey}>{t("withoutLead")}</span>
												)}
											</td>
											<td
												className={styles.checkWeb}
												data-label="Zona cliente"
											>
												{dataClient.isWebClient ? (
													<HiCheck
														size={24}
														color="green"
													/>
												) : (
													<HiX
														size={20}
														color="red"
													/>
												)}
											</td>
											<td>
												<button
													className="btn btn-light btn-outline-dark btn-flexicar btn-flexicar-mobile"
													onClick={() =>
														onSelectClient(
															dataClient.id,
															dataClient.sellLead || dataClient.purchaseLead
														)
													}
												>
													{t("chooseClientButton")}
												</button>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</div>
					</div>
				)}
            </ModalBody>
			{isConfirmView ? (
					<ModalFooter>
						<>
							<button
								className="btn btn-flexicar-inverse-size-s btn-flexicar-mobile"
								onClick={() => setIsConfirmView(false)}
							>
								{t("cancel")}
							</button>
							<button
								className="btn btn-light btn-outline-dark btn-flexicar btn-flexicar-mobile"
								onClick={handleConfirm}
							>
								{t("accept")}
							</button>
						</>
					</ModalFooter>
				) : (allClientsHaveEmail && (
					<ModalFooter style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
						<span>
						{t("msgCheckExistClient1")}{" "}
						<span
							style={{
								color: "#D34600",
								cursor: "pointer"
							}}
							onClick={(e) => {
							e.preventDefault();
							handleAddNewClient();
							}}
						>
							{t("clickHere")}
						</span>
						{t("msgCheckExistClient2")}
						</span>
					</ModalFooter>
				  )
				)}
        </Modal>
	);
};
