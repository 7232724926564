import Collapsible from "@components/Collapsible";
import FormCheckBox from "@components/Form/Checkbox/FormCheckbox";

const VehicleExtrasForm = ({ extras }) => {
	const renderExtra = (extra) => (
		<Collapsible
			key={`vehicle-extras-collapsible-${extra.category}`}
			header={extra.category}
		>
			<div className="container">
				<div
					className="d-flex flex-column"
					style={{ gap: "1.5rem" }}
				>
					{extra.items.map((item) => (
						<div key={item.id}>
							<FormCheckBox
								name={item.id}
								label={item.name}
							/>
							<span>{`Precio: ${item.retailPrice}€`}</span>
						</div>
					))}
				</div>
			</div>
		</Collapsible>
	);

	return (
		<div className="row">
			<div className="col-md-6 col-sm-12">
				{extras.map((extra, index) => index % 2 === 0 && renderExtra(extra))}
			</div>
			<div className="col-md-6 col-sm-12">
				{extras.map((extra, index) => index % 2 !== 0 && renderExtra(extra))}
			</div>
		</div>
	);
};

export default VehicleExtrasForm;
