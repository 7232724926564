import React from "react";

const EquipmentJato = ({ equipment, ActionButtons }) => {
	return (
		<main className="flex flex-column mt-4">
			<ActionButtons />
			<div className="row pt-3">
				{equipment?.map((tipo, idx) => (
					<div
						key={`equipment-jato-${idx}`}
						className="col-md-6 col-sm-12"
					>
						<h5 style={{ fontWeight: "600" }}>{tipo.category}</h5>
						<hr />
						{tipo.items.map((elm, index) => (
							<ul key={`equipment-jato-${idx}-tipo-${index}`}>
								<li>
									<strong>{`${elm}`}</strong>
								</li>
							</ul>
						))}
					</div>
				))}
			</div>
		</main>
	);
};

export default EquipmentJato;
