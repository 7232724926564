import React from 'react';
import { Row, Col } from 'reactstrap';
import Select from '@components/Select';
import { getFilterCompanieDealership } from '@services/Filtros/filtros';
import { errorToast } from '@helpers/toastFunctions';

const Step3 = ({
  data,
  rol,
  lead,
  localRoles,
  setData,
  companie,
  setCompanie,
  checkReservaData,
  activeBtn1,
  activeBtn2,
  activeBtn3,
}) => {

  const onChangeKmValueHandle = (e) => {
    const value = e.target.value;
    const regex = /[^0-9]/;
    if (regex.test(value)) {
      e.target.value = value.replace(regex, '');
    }
    setData({ ...data, km: e.target.value });
  };

  const handleConceptoChange = (e) => {
    const inputValue = e.target.value;
    setData({ ...data, concepto: inputValue });
  };

  const handleCrearDocumento = () => {
    if (data.concepto.length > 50) {
      errorToast('El concepto no puede tener más de 50 caracteres');
    } else {
      checkReservaData(1);
    }
  };

  return (
    <>
      <Row className="mt-5 ml-2">
        <Col md={4} sm={12}>
          <span className="tittle_sub ml-1">Reserva (en €):</span>
          <input
            className="mb-3"
            type="number"
            value={data.reserva}
            onChange={(e) => setData({ ...data, reserva: e.target.value })}
          />
        </Col>
        <Col md={4} sm={12}>
          <span className="tittle_sub ml-1">Precio (en €):</span>
          <input
            className="mb-3"
            type="number"
            value={data.precio}
            onChange={(e) => setData({ ...data, precio: e.target.value })}
          />
        </Col>
        <Col md={4} sm={12}>
          <span className="tittle_sub ml-1">DNI/NIE:</span>
          <input
            type="text"
            className="mb-3"
            value={data.dni}
            onChange={(e) => setData({ ...data, dni: e.target.value })}
          />
        </Col>
      </Row>

      <Row className="ml-2">
        <Col md={4} sm={12}>
          <span className="tittle_sub ml-1">Nombre:</span>
          <input
            type="text"
            className="mb-3"
            value={data.nombre}
            onChange={(e) => setData({ ...data, nombre: e.target.value })}
          />
        </Col>
        <Col md={4} sm={12}>
          <span className="tittle_sub ml-1">Primer apellido:</span>
          <input
            type="text"
            className="mb-3"
            value={data.apellido1}
            onChange={(e) => setData({ ...data, apellido1: e.target.value })}
          />
        </Col>
        <Col md={4} sm={12}>
          <span className="tittle_sub ml-1">Segundo apellido:</span>
          <input
            type="text"
            className="mb-3"
            value={data.apellido2}
            onChange={(e) => setData({ ...data, apellido2: e.target.value })}
          />
        </Col>
      </Row>

      <Row className="ml-2">
        <Col md={4} sm={12}>
          <span className="tittle_sub ml-1">Email:</span>
          <input
            type="text"
            className="mb-3"
            value={data.email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
          />
        </Col>
        <Col md={4} sm={12}>
          <span className="tittle_sub ml-1">Teléfono:</span>
          <input
            type="text"
            className="mb-3"
            value={data.telefono}
            onChange={(e) => setData({ ...data, telefono: e.target.value })}
          />
        </Col>
        <Col md={4} sm={12}>
          <span className="tittle_sub ml-1">Vehículo:</span>
          <input
            type="text"
            className="mb-3"
            value={data.vehiculo}
            onChange={(e) => setData({ ...data, vehiculo: e.target.value })}
          />
        </Col>
      </Row>

      <Row className="ml-2">
        <Col md={4} sm={12}>
          <span className="tittle_sub ml-1">Matrícula:</span>
          <input
            type="text"
            value={data.matricula}
            onChange={(e) => setData({ ...data, matricula: e.target.value.toUpperCase() })}
          />
        </Col>
        <Col md={4} sm={12}>
          <span className="tittle_sub ml-1">Bastidor:</span>
          <input
            type="text"
            className="mb-3"
            value={data.bastidor}
            onChange={(e) => setData({ ...data, bastidor: e.target.value })}
          />
        </Col>
        <Col md={4} sm={12}>
          <span className="tittle_sub ml-1">Kilómetros:</span>
          <input
            type="number"
            className="mb-3"
            value={data.km}
            onChange={(e) => onChangeKmValueHandle(e)}
          />
        </Col>
      </Row>

      <Row className="ml-2">
        <Col md={6} sm={12}>
          <span className="tittle_sub ml-1">Concepto:</span>
          <div className="mb-3">
            <input
              type="text"
              className="w-100"
              value={data.concepto}
              onChange={handleConceptoChange}
            />
            <div className="text-right">
              <span className="text-muted">{data.concepto.length}/50</span>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="ml-2">
        <Col md={6} sm={12}>
          <span className="tittle_sub ml-1">Empresa:</span>
          <Select
            placeholder="Empresa"
            loadOptions={() => getFilterCompanieDealership(lead.id_local_for_marketplace)}
            value={companie}
            onChange={(opt) => setCompanie(opt)}
          />
        </Col>
      </Row>

      <h6 className="mt-3 centerXY">Introduce notas o comentarios</h6>
      <textarea
        style={{ width: '100%' }}
        value={data.notas}
        onChange={(e) => setData({ ...data, notas: e.target.value })}
      />

      {rol && localRoles.some((r) => rol.includes(r)) ? (
        <>
          <button
            type="button"
            className="btn btn-light btn-outline-dark btn-flexicar"
            disabled={!activeBtn1}
            onClick={() => handleCrearDocumento()}
          >
            Crear Documento
          </button>
          <button
            type="button"
            className="btn btn-light btn-outline-dark btn-flexicar"
            disabled={!activeBtn2}
            onClick={() => checkReservaData(2)}
          >
            Reservar
          </button>
        </>
      ) : (
        <button
          type="button"
          className="btn btn-light btn-outline-dark btn-flexicar"
          disabled={!activeBtn3}
          onClick={() => checkReservaData(3)}
        >
          Reservar
        </button>
      )}
    </>
  );
};

export default Step3;