import React, { useState, useContext, useEffect, useCallback } from "react";
import moment from "moment";
import ModalWarning from "@components/ModalWarning";
import { deleteVehiculo } from "@services/Vehicle/vehicle.service";
import { successToast } from "@helpers/toastFunctions";
import errorCall from "@services/errorCall";
import { Table } from "react-bootstrap";
import { MdCarRepair, MdDeleteForever } from "react-icons/md";
import styles from "./index.module.scss";
import { useHistory } from "react-router-dom";
import { UserContext } from "@contexts/UserContext";
import { useTranslation } from "react-i18next";
import RolType from "@constants/roles";
import { VEHICLE_VALIDATION_ACTIONS } from "@views/VehicleList/actions/vehicleValidationReducer";


const TableStock = ({
  cars,
  loadVehiclePage,
  filterDispatch,
}) => {
  const { rol } = useContext(UserContext);
  const history = useHistory();
  const [modalDelete, setModalDelete] = useState(false);
  const [elementToDelete, setElementToDelete] = useState(null);
  const [showIcons, setShowIcons] = useState(false);
  const { t } = useTranslation('vehicleValidationList');

  const cancelDelete = () => {
    setModalDelete(false);
  };

  const deleteVehicule = async () => {
    try {
      await deleteVehiculo(elementToDelete);
      filterDispatch({ type: VEHICLE_VALIDATION_ACTIONS.ON_DELETE_VEHICLE });
      setModalDelete(false);
      successToast("Vehiculo Borrado correctamente");
    } catch (e) {
      errorCall(e);
    }
  };

  const warningAlert = async (id) => {
    setModalDelete(true);
    setElementToDelete(id);
  };

  const getBtnVisibility = useCallback(() => {
		if (rol.includes(1)) {
			setShowIcons(true);
			return;
		} else if (
			rol.includes(15) ||
			rol.includes(16) ||
			rol.includes(17) ||
			rol.includes(18) ||
			rol.includes(2) ||
			rol.includes(3) ||
			rol.includes(4) ||
			rol.includes(5) ||
			rol.includes(8) ||
			rol.includes(13) ||
			rol.includes(14) ||
			rol.includes(19) ||
			rol.includes(22) ||
			rol.includes(23) ||
			rol.includes(24) ||
			rol.includes(25) ||
			rol.includes(26) ||
			rol.includes(27) ||
			rol.includes(28) ||
			rol.includes(29) ||
			rol.includes(35)
		) {
			history.push("/main/validacion-vehiculo");
			setShowIcons(false);
			return;
		} else if (rol.includes(6) || rol.includes(7) || rol.includes(RolType.TERRITORIAL)) {
			setShowIcons(true);
			return;
		}
	} ,[history, rol]);

	useEffect(() => {
		getBtnVisibility();
	}, [rol, getBtnVisibility]);

  return (
    <div className={styles.tableContainer}>
      <Table>
        <thead>
          <tr>
            <th>{t("image")}</th>
            <th>{t("vehicle")}</th>
            <th>{t("plate")}</th>
            <th>{t("price")}</th>
            <th>{t("contract")}</th>
            <th>{t("year")}</th>
            <th>{t("km")}</th>
            <th>{t("date")}</th>
            <th>{t("actions")}</th>
          </tr>
        </thead>
        <tbody>
          {cars.map((car, idx) => {
            return (
              <tr
                key={"vehicle" + idx}
                style={
                  idx % 2
                    ? { background: "#ffffff" }
                    : { background: "rgba(242,242,242,0.5)" }
                }
              >
                <td data-label="Imagen">
                  <span
                    className="pointer"
                    onClick={() => loadVehiclePage(car.id)}
                  >
                    <img
                      alt={"carimg-" + idx}
                      src={car.image}
                      height="80"
                      width="120"
                      className="Stock-Thumb"
                    />
                  </span>
                </td>

                <td data-label="Vehículo">
                  <span
                    className="pointer"
                    onClick={() => loadVehiclePage(car.id)}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    {car.make.name} {car.model.name}
                  </span>
                </td>

                <td data-label="Matricula">
                  <span
                    className="pointer"
                    onClick={() => loadVehiclePage(car.id)}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    {car.plate}
                  </span>
                </td>
                <td data-label="Precio compra">
                  <span
                    className="pointer"
                    onClick={() => loadVehiclePage(car.id)}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    {car?.priceBought} €
                  </span>
                </td>
                <td data-label="Contrato">
                  <span
                    className="pointer"
                    onClick={() => loadVehiclePage(car.id)}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    {car.contractType === 3
                      ? "Compra Directa"
                      : "Gestion de venta"}
                  </span>
                </td>
                <td data-label="Año">
                  <span
                    className="pointer"
                    onClick={() => loadVehiclePage(car.id)}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    {car.year}
                  </span>
                </td>

                <td data-label="Kilómetros">
                  <span
                    className="pointer"
                    onClick={() => loadVehiclePage(car.id)}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    {car.mileage
                      .toLocaleString("en")
                      .replace(/,/, ".")
                      .replace(/"/, "")
                      .replace(/"/, "")}
                  </span>
                </td>
                <td data-label="Fecha Alta">
                  <span
                    className="pointer"
                    onClick={() => loadVehiclePage(car.id)}
                    style={{
                      color: "inherit",
                      textDecoration: "none",
                    }}
                  >
                    {moment(car.createdAt).utc().format("DD-MM-YYYY")}
                  </span>
                </td>
                    
                       <td data-label="Acciones">
                       <MdCarRepair
                         onClick={showIcons ? () => loadVehiclePage(car.id) : null}
                         className="pointer"
                         title="Modificar Vehiculo"
                         color={showIcons ? "#007bff" : "grey" }
                         size={36}
                       />
     
                       <MdDeleteForever
                         className="md-36 pointer"
                         title="Eliminar vehículo"
                         onClick={showIcons ? () => warningAlert(car.id) : null}
                         size={36}
                         color={showIcons ? "red" : "grey"}
                       />
                     </td>
                      
                    

               
              </tr>
            );
          })}
        </tbody>
      </Table>

      <ModalWarning
        okButton={deleteVehicule}
        cancelButton={cancelDelete}
        open={modalDelete}
      ></ModalWarning>
    </div>
  );
};

export default TableStock;
