import { combineReducers } from "redux";

import filtersReducer from "@redux/reducers/filtersReducer";

const reducers = {
	filters: filtersReducer
};

const appReducer = combineReducers(reducers);
const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
