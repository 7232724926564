import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

import Mainlayout from "@layouts/MainLayout";
import { API } from "@services/urlConstants";
import { UserContext } from "@contexts/UserContext";
import { errorToast } from "@helpers/toastFunctions";
import instance from "@services/base";
import { createCaseFormSchema } from "@schema/postventaSchema";
import FormEmailAutocomplete from "@components/Form/AutocompleteEmail/FormEmailAutocomplete";
import Form from "@components/Form/Form";
import FormInput from "@components/Form/Input/FormInput";
import FormTextArea from "@components/Form/Textarea/FormTextarea";

const CreateCase = () => {
	const { t } = useTranslation("createCase");

	const history = useHistory();
	const { vehicleId } = useParams();
	const { user: userId } = useContext(UserContext);

	const getFormDefaultValues = async () => {
		try {
			const response = await instance.post(API.GET_DOCUMENTACION_FIRMAS, {
				vehicle_id: vehicleId
			});
			const docs = response.data;

			if (docs && docs.length > 0) {
				const contract = docs.filter((e) => e.tipo === 1);
				if (contract && contract.length > 0) {
					const contractParse = contract[0].datos;

					return {
						fullName: contractParse.nombre,
						phone: contractParse.telefono.toString(),
						email: contractParse.email
					};
				}
			}
		} catch (err) {
			errorToast(err?.response?.data?.message || t("errors.load"));
		}
	};

	const onSubmit = async (formData) => {
		try {
			await instance.post(API.CREATE_CASE, {
				vehicle_id: vehicleId,
				titulo: formData.title,
				descripcion: formData.description,
				agente: userId,
				nombre: formData.fullName,
				telefono: formData.phone,
				email: formData.email
			});

			history.push(`/main/vehicle/${vehicleId}?tab=postventa`);
		} catch (err) {
			errorToast(err?.response?.data?.message || t("errors.create"));
		}
	};

	const barButtons = (
		<>
			<button
				className="btn btn-flexicar-orange"
				onClick={() => history.push(`/main/vehicle/${vehicleId}?tab=postventa`)}
			>
				{t("buttons.back")}
			</button>
		</>
	);

	return (
		<Mainlayout>
			<Mainlayout.Content
				contentTitle={t("contentTitle")}
				buttons={barButtons}
			>
				<Form
					className={styles.form}
					schema={createCaseFormSchema}
					defaultValues={getFormDefaultValues}
					onSubmit={onSubmit}
				>
					<div>
						<FormInput
							label={t("form.fullName")}
							name="fullName"
						/>
						<FormInput
							label={t("form.phone")}
							name="phone"
						/>
						<FormEmailAutocomplete
							label={t("form.email")}
							name="email"
						/>
					</div>

					<div>
						<FormInput
							label={t("form.title")}
							name="title"
						/>
					</div>

					<div>
						<FormTextArea
							label={t("form.description")}
							name="description"
						/>
					</div>

					<div className={styles.actions}>
						<button
							className="btn orange-btn"
							onClick={() => history.goBack()}
						>
							{t("buttons.cancel")}
						</button>
						<button
							className="btn btn-flexicar-orange"
							type="submit"
						>
							{t("buttons.accept")}
						</button>
					</div>
				</Form>
			</Mainlayout.Content>
		</Mainlayout>
	);
};

export default CreateCase;
