import * as Yup from "yup";

const FILE_MAX_SIZE_MB = 10;
const FILE_MAX_SIZE = FILE_MAX_SIZE_MB * 1024 * 1024;
const FILE_NAME_MAX_CHARS = 30;
const FILE_ACCEPTED_FORMATS = [
	"application/pdf",
	"application/msword",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	"image/jpeg",
	"image/png",
	"image/webp"
];

const uploadDocumentationSchema = Yup.object({
	docName: Yup.string()
		.required("Campo obligatorio")
		.max(FILE_NAME_MAX_CHARS, "El nombre del documento no puede superar los 30 caracteres"),
	docType: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).required("Campo obligatorio"),
	file: Yup.mixed()
		.required("Archivo obligatorio")
		.test("fileExists", "Adjunte un documento", (file) => file)
		.test("fileType", "Formato no permitido", (file) => FILE_ACCEPTED_FORMATS.includes(file.type))
		.test("fileSize", `Tamaño máximo ${FILE_MAX_SIZE_MB} MB`, (file) => file.size <= FILE_MAX_SIZE)
});

export { uploadDocumentationSchema };
