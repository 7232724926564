import axios from "axios";
import { decode } from "jsonwebtoken";
import { isAfter } from "date-fns";

import { environment } from "@config/environment.config";
import { getCookie, removeCookie } from "@helpers/getCookie";

const instance = axios.create({
	baseURL: environment.API_CRM,
	maxContentLength: Infinity,
	maxBodyLength: Infinity,
	timeout: 25000
});

instance.interceptors.request.use((config) => {
	const token = getCookie("JWT");

	if (token) {
		const { exp } = decode(token);
		const now = Math.floor(((new Date().getTime())) / 1000);
		if (isAfter(exp, now)) {
			config.headers.Authorization = `Bearer ${token}`;
		} else {
			window.location.href = "/main/login";
			removeCookie("JWT");
			return Promise.reject(new Error("JWT token has expired"));
		}
	}

	return config;
});

instance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (
			window.location.pathname !== "/main/login" &&
			error.response &&
			error.response.status === 401
		) {
			window.location.href = "/main/login";
			removeCookie("JWT");
		}
		return Promise.reject(error);
	}
);

export default instance;
