import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";

import "./styles/global.scss";
import Routes from "./Routes.jsx";
import CustomNavbar from "@components/Navbar/CustomNavbar";
import { UserContext } from "@contexts/UserContext";
import Toast from "@components/Toast";
import { register as registerServiceWorker } from "./service-worker";
import ServiceWorkerUpdateNotification from "@components/ServiceWorkerUpdateNotification/ServiceWorkerUpdateNotification";

const broadcast = new BroadcastChannel("sw-update-channel");

const App = () => {
	const { rol } = useContext(UserContext);
	const history = useHistory();
	const previousPathname = useRef(history.location.pathname);

	const [newVersionAvailable, setNewVersionAvailable] = useState(false);

	const checkSWUpdate = async () => {
		const registrations = await navigator.serviceWorker.getRegistrations();
		registrations.forEach((reg) => reg.update());
	};

	const forceSWSkipWaiting = async () => {
		const registrations = await navigator.serviceWorker.getRegistrations();
		registrations.forEach((reg) => reg.waiting.postMessage({ type: "SKIP_WAITING" }));
	};

	const updateApp = async () => {
		await forceSWSkipWaiting();
		broadcast.postMessage("refreshPage");

		setNewVersionAvailable(false);
		window.location.reload();
	};

	useEffect(() => {
		document.addEventListener("visibilitychange", async () => {
			if (!document.hidden) await checkSWUpdate();
		});
	}, []);

	useEffect(() => {
		const unlisten = history.listen(async (location) => {
			if (location.pathname !== previousPathname.current) {
				await checkSWUpdate();
				previousPathname.current = location.pathname;
			}
		});

		return () => {
			unlisten();
		};
	}, [history]);

	useEffect(() => {
		registerServiceWorker({
			onUpdate: () => setNewVersionAvailable(true)
		});

		broadcast.onmessage = (event) => {
			if (event.data === "refreshPage") {
				setNewVersionAvailable(false);
				window.location.reload();
			}
		};

		return () => {
			broadcast.close();
		};
	}, []);

	useEffect(() => {
		const cookie = Cookies.get("JWT");
		if (!cookie) history.push("/main/login");
	}, [history]);

	return (
		<>
			{newVersionAvailable && (
				<ServiceWorkerUpdateNotification
					newVersionAvailable={newVersionAvailable}
					updateApp={updateApp}
				/>
			)}

			<div className="App">
				<header>{rol && <CustomNavbar />}</header>
				<main>
					<Routes />
				</main>
				<footer></footer>
				<Toast />
			</div>
		</>
	);
};

export default App;
