// eslint-disable-next-line no-restricted-globals
const ignored = self.__WB_MANIFEST;

export function register(config) {
	if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
		const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
		if (publicUrl.origin !== window.location.origin) return;

		window.addEventListener("load", async () => {
			const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
			const registration = await navigator.serviceWorker.register(swUrl);

			registration.onupdatefound = () => {
				const installingWorker = registration.installing;
				if (installingWorker == null) return;

				installingWorker.onstatechange = () => {
					if (installingWorker.state === "installed") {
						if (navigator.serviceWorker.controller) {
							if (config && config.onUpdate) {
								config.onUpdate(registration);
							}
						}
					}
				};
			};
		});
	}
}
