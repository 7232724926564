import * as Yup from "yup";

import { NAME_WITHOUT_SPECIAL_CHARACTERS } from "@constants/regex";
import Locale from "@constants/Locale";

const clientSchemaEs = Yup.object({
	name: Yup.string().min(1, "Campo obligatorio").max(60, "Máximo 60 caracteres"),
	firstSurname: Yup.string()
		.max(60, "Máximo 60 caracteres")
		.matches(NAME_WITHOUT_SPECIAL_CHARACTERS, {
			message: "Caracteres inválidos",
			excludeEmptyString: true
		}),
	secondSurname: Yup.string()
		.max(60, "Máximo 60 caracteres")
		.matches(NAME_WITHOUT_SPECIAL_CHARACTERS, {
			message: "Caracteres inválidos",
			excludeEmptyString: true
		}),
	email: Yup.string().email({ excludeEmptyString: true }).max(50, "Máximo 50 caracteres"),
	primaryPhone: Yup.string().phone(),
	secondaryPhone: Yup.string().phone().optional(),
	province: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).nullable(),
	municipality: Yup.string().max(50, "Máximo 50 caracteres").optional()
});

const clientSchemaPt = Yup.object({
	name: Yup.string().min(1, "Campo obrigatório").max(60, "Máximo 60 caracteres"),
	firstSurname: Yup.string()
		.max(60, "Máximo 60 caracteres")
		.matches(NAME_WITHOUT_SPECIAL_CHARACTERS, {
			message: "Caracteres inválidos",
			excludeEmptyString: true
		}),
	secondSurname: Yup.string()
		.max(60, "Máximo 60 caracteres")
		.matches(NAME_WITHOUT_SPECIAL_CHARACTERS, {
			message: "Caracteres inválidos",
			excludeEmptyString: true
		}),
	email: Yup.string().email({ excludeEmptyString: true }).max(50, "Máximo 50 caracteres"),
	primaryPhone: Yup.string().phone({ locale: Locale.PT }),
	secondaryPhone: Yup.string().phone({ locale: Locale.PT }).optional(),
	province: Yup.object({
		label: Yup.string(),
		value: Yup.number()
	}).nullable(),
	municipality: Yup.string().max(50, "Máximo 50 caracteres").optional()
});

export const clientSchema = {
	"es-ES": clientSchemaEs,
	"pt-PT": clientSchemaPt
};
