import { useTranslation } from "react-i18next";
import { MdDeleteForever } from "react-icons/md";
import { FaEye } from "react-icons/fa";

import styles from "./index.module.scss";

import { useMediaQuery } from "@hooks/useMediaQuery";
import { formatSpanishFullDate } from "@helpers/formatDate";
import Collapsible from "@components/Collapsible";

const TableFiles = ({ files, docTypesOptions, seeFile, deleteFile }) => {
	const { t } = useTranslation("documentationTableFiles");
	const isDesktop = useMediaQuery("(min-width: 768px)");

	const desktopView = (
		<div className={styles.table}>
			<div className={styles.container}>
				<div className={`${styles.row} ${styles.header}`}>
					<div>{t("documentType")}</div>
					<div>{t("format")}</div>
					<div>{t("name")}</div>
					<div>{t("date")}</div>
					<div>{t("user")}</div>
					<div></div>
				</div>

				{files.map((file) => (
					<div
						key={file.id}
						className={styles.row}
					>
						<div>
							{docTypesOptions.find((x) => x.id === file.id_document_type)?.description ?? "-"}
						</div>
						<div>{file.user_file_name ? file.user_file_name.split(".").pop() : "-"}</div>
						<div>{file.user_file_name ?? "-"}</div>
						<div>{formatSpanishFullDate(file.fecha)}</div>
						<div>
							{file.attacher?.name
								? `${file.attacher.name} ${file.attacher.surname1} ${file.attacher.surname2}`
								: "-"}
						</div>
						<div>
							<FaEye
								size={26}
								color="#007bff"
								role="button"
								onClick={() => seeFile(file)}
							/>
							<MdDeleteForever
								size={26}
								color="red"
								role="button"
								onClick={() => deleteFile(file.id, file.id_document_type)}
							/>
						</div>
					</div>
				))}
			</div>
		</div>
	);

	const mobileView = files.map((file) => (
		<Collapsible
			key={file.id}
			header={<b>{file.user_file_name}</b>}
		>
			<div className={styles.collapse}>
				<div>
					<span>{t("documentType")}</span>
					<span>
						{docTypesOptions.find((x) => x.id === file.id_document_type)?.description ?? "-"}
					</span>
				</div>

				<div>
					<span>{t("name")}</span>
					<span>{file.user_file_name ?? "-"}</span>
				</div>

				<div>
					<span>{t("date")}</span>
					<span>{formatSpanishFullDate(file.fecha)}</span>
				</div>

				<div>
					<span>{t("user")}</span>
					<span>
						{file.attacher?.name
							? `${file.attacher.name} ${file.attacher.surname1} ${file.attacher.surname2}`
							: "-"}
					</span>
				</div>

				<div>
					<FaEye
						size={26}
						color="#007bff"
						role="button"
						onClick={() => seeFile(file)}
					/>
					<MdDeleteForever
						size={26}
						color="red"
						role="button"
						onClick={() => deleteFile(file.id, file.id_document_type)}
					/>
				</div>
			</div>
		</Collapsible>
	));

	return isDesktop ? desktopView : mobileView;
};

export default TableFiles;
