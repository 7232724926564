/**
 * Formats a file size given in bytes to a more readable string in bytes, KB, or MB.
 *
 * @param {number} bytes - The file size in bytes.
 * @return {string} - The formatted file size string.
 */
export const formatFileSize = (bytes, base) => {
	if (bytes < 1000) return bytes + " bytes";
	else if (bytes < 1000 * 1000) return (bytes / 1000).toFixed(1) + " KB";
	else return (bytes / (1000 * 1000)).toFixed(1) + " MB";
};
