const permissions = {
  contratos: {
    id: [5, 1376, 1382],
    empenos: [31],
    empenosCancelados: [33],
    crearContrato: [1382],
    documentacion: [1376],
  },
  leads: {
    id: [35, 36, 37, 38, 249, 772, 1364, 1365, 1378],
    todosLeads: [1378],
    todosLeadsEmpenos: [35],
    leadsPropios: [1380],
    leadsPropiosEmpenos: [37],
    leadsPropiosPendientes: [1379],
    leadsPendientesTasacion: [1387],
    leadsPropiosPendientesEmpenos: [38],
    leadsPendientes: [1381],
    leadsPendientesEmpenos: [36],
    leadsAtendidos: [249],
    leadsVenta: [772],
    leadsPropiosVenta: [1364],
    destacados: [1365],
    leadsVentaTienda: [1372],
    buscarTasacion: [319],
    tasacionesRealizadas: [35],
    turnosAgentesVenta: [1373],
    listaAgentesLeads: [3],
  },
  citas: {
    id: [1244, 1371, 1374, 1377, 1426],
    citasCompra: [1371],
    citasVenta: [1244],
    citasPostVenta:[1426],
    reservas: [1374],
    entregas: [1377],
  },
  casos: { id: [1375] },
  contabilidad: {
    id: [40],
    empenos: [40],
    compraVenta: [41],
  },
  crearLeadPhp: {
    id: [64],
  },
  crearLeadTasacion: {
    id: [1383],
  },
  stock: {
    id: [1368, 651, 1427],
    flexicar: [1368, 651],
    professionals: [1427]
  },
  validacionVo: {
    id: [1386],
  },
  administracion: {
    id: [1],
  },
  ayuda: {
    id: [1385],
  },
  locales: { id: [6] },
  empleados: { id: [28] },
  vehiculo: { validacion: [1386] },
};

export default permissions;
