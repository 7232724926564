import GenericModal from "@components/GenericModal";
import ContractId from "@components/ContractId/ContractId";
import CreateSellContract from "@views/SellContract/CreateSellContract";
import EditSellContract from "@views/SellContract/EditSellContract";
import { successToast } from "@helpers/toastFunctions";

const SellContractModal = ({
	isOpen,
	setIsOpen,
	mode,
	lead,
	client,
	reservation,
	document,
	setIdContract,
	recall,
	vehicleYear,
	vehicleMonth
}) => (
	<GenericModal
		isOpen={isOpen}
		setIsOpen={setIsOpen}
		modalClassName="modal-xl"
		headContent={
			{
				create: `Crear contrato ${lead?.nombre_marca_v} ${lead?.nombre_modelo_v} ${lead?.matricula}`,
				edit: (
					<div>
						<ContractId
							contractType={document?.tipo}
							contractId={document?.documentId}
						>
							<span>
								{`Editar contrato ${document?.datos?.marca} ${document?.datos?.modelo} ${document?.datos?.matricula} -`}
							</span>
						</ContractId>
					</div>
				)
			}[mode]
		}
		bodyContent={
			{
				create: (
					<CreateSellContract
						data={{ lead, client, reservation }}
						onCreate={(id) => {
							setIdContract(id);
							setIsOpen(false);
						}}
					/>
				),
				edit: (
					<EditSellContract
						document={document}
						onEdit={async () => {
							successToast("Contrato actualizado con éxito");
							setIsOpen(false);
							await recall();
						}}
						vehicleYear={vehicleYear}
						vehicleMonth={vehicleMonth}
					/>
				)
			}[mode]
		}
	/>
);
export default SellContractModal;
