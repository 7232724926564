import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "reactstrap";

import Uploader from "@views/VehicleValidation/components/Uploader";
import Preview from "@views/VehicleValidation/components/Preview";
import Url360Uploader from "@components/Url360Uploader";
import { deleteVehicleImage, putImageCategory } from "@services/Vehicle/vehicle.service";
import { errorToast } from "@helpers/toastFunctions";
import Locale from "@constants/Locale";

const Gallery = ({
	type,
	vehicleImages,
	setVehicleImages,
	image360Link,
	setImage360Link,
	vehicleId,
	ActionButtons
}) => {
	const { t } = useTranslation("vehicleValidation");
	const { i18n } = useTranslation();

	const spanishAccessProtection = i18n.language === Locale.ES;

	const [imageLoad, setImageLoad] = useState(true);
	// Se deja comentado hasta que funcione el endpoint
	// const [requestInProcess, setRequestInProcess] = useState(false);
	// const [loading, setLoading] = useState(false);

	// const onClickRequestImages = async () => {
	// 	setLoading(true);
	// 	try {
	// 		await postImagesRequest(vehicleId);
	// 		setRequestInProcess(true);
	// 	} catch (e) {
	// 		errorToast(t("tabs.photos.errorImagesRequest"));
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };

	const appendVehicleImages = (result) => setVehicleImages([...vehicleImages, result]);
	const deleteImage = async (id) => {
		if (vehicleImages.length > 0) {
			try {
				const filterImages = await vehicleImages.filter((image) => image.id !== id);

				setVehicleImages(filterImages);
				await deleteVehicleImage(vehicleId, id, type);
			} catch (e) {
				errorToast(e);
			}
		}
	};

	const updateImageLabel = async (label, id, image_id) => {
		let previewImages = [...vehicleImages];
		let image = { ...previewImages[id - 1] };
		image.labelId = Number(label);
		previewImages[id - 1] = image;		
		try {
			setVehicleImages(previewImages);
			await putImageCategory(vehicleId, image_id, image.labelId, type);
		} catch (e) {
			errorToast(e);
		}
	};

	return (
		<main className="flex flex-column mt-4">
			<ActionButtons />

			{/* Se deja comentado hasta que funcione el endpoint */}
			{/* <div className="my-2">
				<button
					className="btn btn-light btn-outline-dark btn-flexicar"
					onClick={onClickRequestImages}
					disabled={loading || requestInProcess}
				>
					<span>
						{t("tabs.photos.imagesRequest")} {loading && <Spinner size={"sm"} />}
					</span>
				</button>
				<span className="ml-3">{t("tabs.photos.imagesRequestMessage")}</span>
				{requestInProcess && (
					<div className="d-flex mt-2">
						<WarningPanel text={t("tabs.photos.imagesRequestWarning")} />
					</div>
				)}
			</div> */}

			{spanishAccessProtection && image360Link && (
				<Url360Uploader
					id={vehicleId}
					image360Link={image360Link}
					setImage360Link={setImage360Link}
				/>
			)}

			<Uploader
				id={vehicleId}
				type={type}
				setImageLoad={setImageLoad}
				appendVehicleImages={appendVehicleImages}
			/>

			{imageLoad ? (
				<Preview
					type={type}
					vehicleId={vehicleId}
					vehicleImages={vehicleImages}
					deleteImage={deleteImage}
					updateImageLabel={updateImageLabel}
				/>
			) : (
				<div className="d-flex justify-content-center mt-5">
					<Spinner
						type="grow"
						className="flexicarColor"
					/>
					<p className="flexicarColor">{t("tabs.photos.loadingImages")}</p>
				</div>
			)}
		</main>
	);
};

export default Gallery;
