import { useState } from "react";

import styles from "./index.module.scss";

import DocumentationType from "@constants/documentationType";
import { errorToast, successToast } from "@helpers/toastFunctions";
import { useMediaQuery } from "@hooks/useMediaQuery";
import instance from "@services/base";
import { API } from "@services/urlConstants";

const ButtonCloseGdv = ({ documentId, documentType, sellContract, recall }) => {
	const [loading, setLoading] = useState(false);
	const isMobile = useMediaQuery("(max-width: 980px)");

	if (documentType !== DocumentationType.GESTION_VENTA) {
		return null;
	}

	const handleCloseGdv = async () => {
		setLoading(true);
		try {
			const response = await instance.post(API.CREATE_CONTRACT_BY_GDV(documentId));
			if (response.status === 201) {
				successToast(
					"Contrato Compra Directa creado, por favor, localice el registro en “Mi documentación filtrado con tareas pendientes"
				);
				recall();
			}
		} catch (error) {
			errorToast("Cierre no efectuado");
		} finally {
			setLoading(false);
		}
	};

	const canCreateContract = !sellContract;

	const buttonClass = canCreateContract
		? `${styles.btnFlexicarMini} ${styles.red}`
		: `${styles.btnFlexicarMini} ${styles.green}`;

	const buttonText = canCreateContract ? "Cierre de gestión" : "Gestión cerrada";

	return (
		<button
			className={buttonClass}
			onClick={handleCloseGdv}
			disabled={!canCreateContract || loading}
			style={{ marginLeft: "auto", maxWidth: !isMobile && "50%" }}
		>
			{loading ? "Procesando..." : buttonText}
		</button>
	);
};
export default ButtonCloseGdv;
