import * as Yup from "yup";

export const vehicleStateFormSchema = ({ t }) =>
	Yup.object({
		mileage: Yup.number()
			.required(t("errors.mandatory"))
			.moreThan(0, t("errors.positive"))
			.max(999999, t("errors.max", { max: "999.999" })),
		warranty: Yup.object({
			label: Yup.string(),
			value: Yup.number()
		}).nullable(),
		warrantyDate: Yup.string().isISODateString(true),
		origin: Yup.object({
			label: Yup.string(),
			value: Yup.number()
		}).nullable(),
		color: Yup.object({
			label: Yup.string(),
			value: Yup.number()
		}).required(t("errors.mandatory")),
		upholsteryColor: Yup.string().required(t("errors.mandatory")),
		itvDueDate: Yup.string().isISODateString(true),
		itvLastDate: Yup.string().isISODateString(true),
		itvResult: Yup.object({
			label: Yup.string(),
			value: Yup.number()
		}).nullable(),
		noItv: Yup.boolean(),
		km0: Yup.boolean(),
		uOwner: Yup.boolean(),
		imported: Yup.boolean()
	});
