import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

import styles from "@styles/components/forms/vehicle-basic-data-form.module.scss";

import FormInput from "@components/Form/Input/FormInput";
import FormSelect from "@components/Form/Select/FormSelect";
import FormSwitch from "@components/Form/Switch/FormSwitch";
import FormDatePicker from "@components/Form/DatePicker/FormDatePicker";
import useEffectAfterFirstRender from "@hooks/useEffectAfterFirstRender";
import Locale from "@constants/Locale";
import {
	body,
	fuels,
	makes,
	models,
	transmision,
	version,
	years
} from "@services/Vehicle/vehicle.service";
import { parseToRSOptions } from "@utils/mapOptions";
import { VEHICLE_MODE } from "@constants/vehicleValidation";

const fieldsToReset = ["model", "year", "fuel", "transmission", "bodyType", "version"];

const VehicleBasicDataForm = ({ vehicle, type }) => {
	const { t, i18n } = useTranslation("forms/vehicleBasicDataForm");
	const { watch, setValue, getValues } = useFormContext();

	const [mode, setMode] = useState(vehicle.mode);

	const watchedMode = watch("mode");
	const watchedMake = watch("make")?.value;
	const watchedModel = watch("model")?.value;
	const watchedYear = watch("year")?.value;
	const watchedFuel = watch("fuel")?.value;
	const watchedTransmission = watch("transmission")?.value;
	const watchedBodyType = watch("bodyType")?.value;

	const resetNextFields = (currentFieldName) => {
		if (mode === VEHICLE_MODE.JATO) {
			const fieldIndex = fieldsToReset.indexOf(currentFieldName);
			const fieldsToResetAfter = fieldsToReset.slice(fieldIndex + 1);
			fieldsToResetAfter.forEach((field) => setValue(field, null));
		}
	};

	useEffectAfterFirstRender(() => {
		// Reset the fields when changin from CLASSIC to JATO
		if (mode === VEHICLE_MODE.JATO) fieldsToReset.forEach((field) => setValue(field, null));

		// Change the value of version to string when CLASSIC
		if (mode === VEHICLE_MODE.CLASSIC) setValue("version", getValues("version")?.label ?? "");
	}, [mode, setValue]);

	useEffectAfterFirstRender(() => {
		setMode(watchedMode ? VEHICLE_MODE.JATO : VEHICLE_MODE.CLASSIC);
	}, [watchedMode]);

	useEffectAfterFirstRender(() => {
		setValue("registrationDate", null);
	}, [watchedYear]);

	return (
		<div className={styles.vehicle_basic_data_form}>
			<div className={styles.vehicle_basic_data_form__title}>
				<h4>{t("title")}</h4>

				{type === "draft" && i18n.language === Locale.ES && (
					<FormSwitch
						name="mode"
						leftText="Jato"
						rightText="Clásico"
					/>
				)}
			</div>

			<FormSelect
				name="make"
				label={t("brand")}
				placeholder={t("brand")}
				loadOptions={async () => {
					const response = await makes(mode);
					return parseToRSOptions(response);
				}}
				additionalOnChangeCb={resetNextFields}
				isDisabled={type === "stock"}
			/>

			<FormSelect
				name="model"
				label={t("model")}
				placeholder={t("model")}
				loadOptions={async () => {
					const response = await models(mode, watchedMake);
					return parseToRSOptions(response);
				}}
				reloadOptions
				additionalOnChangeCb={() => resetNextFields("model")}
				isDisabled={!watchedMake && mode === VEHICLE_MODE.JATO}
			/>

			<FormSelect
				name="year"
				label={t("year")}
				placeholder={t("year")}
				loadOptions={async () => {
					const response = await years(mode, {
						makeId: watchedMake,
						modelId: watchedModel
					});
					return response.map((x) => ({
						label: x,
						value: x
					}));
				}}
				reloadOptions
				additionalOnChangeCb={() => resetNextFields("year")}
				isDisabled={!watchedModel && mode === VEHICLE_MODE.JATO}
			/>

			<FormSelect
				name="fuel"
				label={t("fuel")}
				placeholder={t("fuel")}
				loadOptions={async () => {
					const response = await fuels(mode, {
						makeId: watchedMake,
						modelId: watchedModel,
						year: watchedYear
					});
					return parseToRSOptions(response);
				}}
				reloadOptions
				additionalOnChangeCb={() => resetNextFields("fuel")}
				isDisabled={!watchedYear && mode === VEHICLE_MODE.JATO}
			/>

			<FormSelect
				name="transmission"
				label={t("gearbox")}
				placeholder={t("gearbox")}
				loadOptions={async () => {
					const response = await transmision(mode, {
						makeId: watchedMake,
						modelId: watchedModel,
						year: watchedYear,
						fuelId: watchedFuel
					});
					return parseToRSOptions(response);
				}}
				reloadOptions
				additionalOnChangeCb={() => resetNextFields("transmission")}
				isDisabled={!watchedFuel && mode === VEHICLE_MODE.JATO}
			/>

			<FormSelect
				name="bodyType"
				label={t("body")}
				placeholder={t("body")}
				loadOptions={async () => {
					const response = await body(mode, {
						makeId: watchedMake,
						modelId: watchedModel,
						year: watchedYear,
						fuelId: watchedFuel,
						transmissionId: watchedTransmission
					});
					return parseToRSOptions(response);
				}}
				reloadOptions
				additionalOnChangeCb={() => resetNextFields("bodyType")}
				isDisabled={!watchedTransmission && mode === VEHICLE_MODE.JATO}
			/>

			{mode === VEHICLE_MODE.JATO && (
				<FormSelect
					name="version"
					label={t("version")}
					placeholder={t("version")}
					loadOptions={async () => {
						const response = await version(mode, {
							makeId: watchedMake,
							modelId: watchedModel,
							year: watchedYear,
							fuelId: watchedFuel,
							transmissionId: watchedTransmission,
							bodyId: watchedBodyType
						});
						return response.map((x) => ({
							label: `${x.name || ""} - ${x.doors ? `${x.doors}P` : ""} (${x.modelYear || "-"})`,
							value: x.id
						}));
					}}
					reloadOptions
					additionalOnChangeCb={() => resetNextFields("version")}
					isDisabled={!watchedBodyType && mode === VEHICLE_MODE.JATO}
				/>
			)}

			{mode === VEHICLE_MODE.CLASSIC && (
				<FormInput
					name="version"
					label={t("version")}
					placeholder={t("version")}
				/>
			)}

			<FormDatePicker
				name="registrationDate"
				label={t("registrationDate")}
				placeholderText="Seleccione una fecha"
				minDate={new Date(`${watchedYear}-01-01`)}
				maxDate={new Date(`${watchedYear}-12-31`)}
				disabled={!watchedYear}
			/>
		</div>
	);
};

export default VehicleBasicDataForm;
