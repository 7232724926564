import { Col, Table } from "reactstrap";

import moment from "moment";

import styles from "./index.module.scss";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
import { FaSpinner } from "react-icons/fa";
import SpinnerComponent from "@components/SpinnerComponent/SpinnerComponent";

const AdditionalInfoTable = ({
  documents,
  AdditionalDocumentType,
  viewingDocument,
  handleViewDocument,
  handleDocumentDelete,
  isStock,
  spinner,
}) => {
  return (
    <Col xs="12" sm="12">
      <div className={styles.tableContainer}>
        {spinner && (
          <div className={styles.spinnerOverlay}>
            Guardando el documento...
            <SpinnerComponent/>
          </div>
        )}

        <Table
          striped
          className={`table table-borderless ${
            spinner ? styles.disabledTable : ""
          }`}
        >
          <thead>
            <tr>
              <th>Nombre documento</th>
              <th>Fecha subida</th>
              <th>Tipo</th>
              <th>Acciones</th>
            </tr>
          </thead>

          <tbody>
            {documents?.map((document, idx) => {
              const selectedType = AdditionalDocumentType.find(
                (type) => type.value === document.typeDocumentId
              );

              return (
                <tr key={`case-body-tr${idx}`}>
                  <td>{document.fileTitle}</td>
                  <td>
                    {moment(document.createdAt).format("DD/MM/YYYY HH:mm")}
                  </td>
                  <td>{selectedType ? selectedType.label : ""}</td>
                  <td>
                    <div>
                      {viewingDocument === document.id ? (
                        <FaSpinner className={styles.spinner} size={24} />
                      ) : (
                        <AiOutlineEye
                          className="pointer"
                          size={24}
                          onClick={() => handleViewDocument(document.id)}
                          color="#007bff"
                        />
                      )}
                      {!isStock && (
                        <RiDeleteBin6Line
                          className="pointer"
                          size={24}
                          color="#ED0530"
                          onClick={() => {
                            handleDocumentDelete(document.id);
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </Col>
  );
};

export default AdditionalInfoTable;
