export const vehicleValidationFiltersInitialState = {
	page: 1,
	pageSize: 25,
	plate: "",
	locales: [],
	buyAndSell: false,
	isUpdating: false
};

export const VEHICLE_VALIDATION_ACTIONS = {
	SET_PAGE: "SET_PAGE",
	SET_PAGE_SIZE: "SET_PAGE_SIZE",
	SET_PLATE: "SET_PLATE",
	SET_LOCALES: "SET_LOCALES",
	SET_BUY_AND_SELL: "SET_BUY_AND_SELL",
	ON_DELETE_VEHICLE: "ON_DELETE_VEHICLE",
	COMPLETE_UPDATE: "COMPLETE_UPDATE"
};

const updateStateWithCommonProperties = (state, newProps) => ({
	...state,
	...newProps,
	page: 1
});

export const vehicleValidationFiltersReducer = (state, action) => {
	const { type, payload } = action;
	
	switch (type) {
		case VEHICLE_VALIDATION_ACTIONS.SET_PAGE:
			return { ...state, page: payload };

		case VEHICLE_VALIDATION_ACTIONS.SET_PAGE_SIZE:
			return updateStateWithCommonProperties(state, { pageSize: payload });

		case VEHICLE_VALIDATION_ACTIONS.SET_PLATE:
			if (payload.length >= 6) return updateStateWithCommonProperties(state, { plate: payload });
			return { ...state, plate: payload };

		case VEHICLE_VALIDATION_ACTIONS.SET_LOCALES:
			return updateStateWithCommonProperties(state, { locales: payload });

		case VEHICLE_VALIDATION_ACTIONS.SET_BUY_AND_SELL:
			return updateStateWithCommonProperties(state, { buyAndSell: payload });

		case VEHICLE_VALIDATION_ACTIONS.ON_DELETE_VEHICLE:
			return { ...state, isUpdating: true };

		case VEHICLE_VALIDATION_ACTIONS.COMPLETE_UPDATE:
			return { ...state, isUpdating: false };

		default:
			return state;
	}
};
