import { stockSearch, vehicleValidationSearch, leadsSearch } from "@redux/actions";

export const dispatcher = (actions) => {
	return (dispatch) => {
		const dispatcher = {};
		actions.forEach((action) => {
			switch (action) {
				case "stockFilters":
					dispatcher.dispatchStockFilters = (search) => dispatch(stockSearch(search));
					break;
				case "vehicleValidationFilters":
					dispatcher.dispatchVehicleValidationFilters = (search) =>
						dispatch(vehicleValidationSearch(search));
					break;
				case "leadsFilters":
					dispatcher.dispatchLeadsFilters = (search) => dispatch(leadsSearch(search));
					break;
				default:
					throw new Error(`Unknown action: ${action}`);
			}
		});
		return dispatcher;
	};
};
