import React, {
  useEffect,
  useRef,
  Fragment,
  useState,
  useContext,
} from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from "reactstrap";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import { errorToast, successToast } from "@helpers/toastFunctions";
import { useReactToPrint } from "react-to-print";
import { UserContext } from "@contexts/UserContext";
import { useTranslation } from 'react-i18next';
import RolType from "@constants/roles";
import Locale from "@constants/Locale";
import ModaLSignatureWithoutPhoto from "@components/ModalSignatureWithoutPhoto/ModalSignatureWithoutPhoto";
import ejsTemplateRender from "@helpers/ejsTemplateRender";
import getDocgenTemplate from "@helpers/getDocgenTemplate";
import { docgenDocumentTypes } from "@constants/docgenDocumentTypes";
import DocumentationType from "@constants/documentationType";

import getDocgenBody from "@helpers/getDocgenBody";
import ContractId from "@components/ContractId/ContractId";
import LongLoadingSpinner from "@components/LongLoadingSpinner/LongLoadingSpinner";

const ModalPrintView = ({
  title,
  open,
  close,
  contractId,
  contractType,
  contractSignStartDate,
  recall,
}) => {
  const { rol } = useContext(UserContext);

  const { t } = useTranslation('myDocumentation');
  const [spinner, setSpinner] = useState(false);
  const [modalNoPhoto, setModalNoPhoto] = useState(false);
  const [openPDF, setOpenPDF] = useState(false);

  const { i18n } = useTranslation();

  const spanishContractProtection = i18n.language === Locale.ES;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onClickPrint = () => {
    if (spanishContractProtection) {
      handlePrint();
    } else {
      if (contractType === DocumentationType.CONTRATO_VENTA) {
        setOpenPDF(true);
      } else {
        handlePrint();
      }
    }
  };

  useEffect(() => {
    if (contractId) {
      getTemplate();
    }
  }, [open]);

  useEffect(() => {
    if (openPDF) {
      getTemplate();
    }
  }, [openPDF]);

  const getTemplate = async () => {

    try {
      const dataTemplate = { contractId };

      if (spanishContractProtection) {
        await ejsTemplateRender(0, dataTemplate);
      } else {
        if (contractType === DocumentationType.CONTRATO_VENTA) {
          const documentType = openPDF ? docgenDocumentTypes.JSON : docgenDocumentTypes.HTML;
          setSpinner(true);
          await getDocgenTemplate(contractId, documentType);
        } else {
          await ejsTemplateRender(0, dataTemplate);
        }
      };
    } catch {
      return errorToast(<span>{t("errors.errorMissingContract")} {contractId}</span>);
    } finally {
      setSpinner(false);
    }
  };

  const getPDF = async (tipo, robusto) => {
    const id_contrato = contractId;

    try {
      setSpinner(true);

      let digitalSignBody;

      if (spanishContractProtection) {
        digitalSignBody = { id_contrato, tipo, robusto };
      } else {
        if (contractType === DocumentationType.CONTRATO_VENTA) {
          digitalSignBody = await getDocgenBody(id_contrato, tipo, robusto);
        } else {
          digitalSignBody = { id_contrato, tipo, robusto };
        }
      }
      const response = await post(API.DIGITAL_SIGN, digitalSignBody);
      if (response.error && response.errorMessage) {
        return errorToast(<span>{response.errorMessage}</span>);
      }

      tipo === 1
        ? successToast(t("digitalSign.revisedPhone"))
        : successToast(t("digitalSign.revisedEmail"));
      recall();
      close();
    } catch {
      return errorToast(<span>{t("errors.errorSignContract")}</span>);
    } finally {
      setSpinner(false);
    }
  };

  return (
    <>
      <Modal className="modal-xl" isOpen={openPDF} toggle={() => !spinner && setOpenPDF(!openPDF)}>
        {spinner && (
          <LongLoadingSpinner localeES={spanishContractProtection} />
        )}
        <iframe width='100%' height='1000px' id="templatePDF"></iframe>
      </Modal>
      <Modal className="modal-xl" isOpen={open} toggle={close}>
        <ModalHeader>
          <ContractId
            contractType={contractType}
            contractId={contractId}
          >
            {t("contractPdf.documentFrom")} {t(title)}
          </ContractId>
        </ModalHeader>
        <ModalBody className="p-4">
          <div>
            {!contractSignStartDate && !spinner && (
              <div className="d-flex justify-content-around flex-wrap">
                <Button
                  className="btn-flexicar mt-4 no-print btn-flexicar-mobile text-center"
                  onClick={onClickPrint}
                >
                  {t('contractPdf.printContract')}
                </Button>
                {rol &&
                  rol?.some(
                    (e) =>
                      e === RolType.TRADING_AGENT ||
                      e === RolType.TRADING_MANAGER ||
                      e === RolType.GENERAL_ADMIN ||
                      e === RolType.ADMINISTRATION ||
                      e === RolType.PURCHASING_AGENT
                  ) && (
                    <Fragment>
                      <Button
                        className="btn-flexicar mt-4 no-print btn-flexicar-mobile text-center"
                        onClick={() => getPDF(1, 2)}
                      >
                        {t('contractPdf.signPhoto')}
                      </Button>
                      <Button
                        className="btn-flexicar mt-4 no-print btn-flexicar-mobile text-center"
                        onClick={() => setModalNoPhoto(true)}
                      >
                        {t('contractPdf.signNoPhoto')}
                      </Button>
                    </Fragment>
                  )}
              </div>
            )}
            {spinner && !openPDF &&
              <LongLoadingSpinner localeES={spanishContractProtection} />
            }
            <br />
            <div ref={componentRef} className={spanishContractProtection ? "m-5" : "docgenTemplate m-5"} id="template"></div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => close()}>
            {t('contractPdf.close')}
          </Button>
        </ModalFooter>
      </Modal>
      <ModaLSignatureWithoutPhoto
        open={modalNoPhoto}
        setModal={setModalNoPhoto}
        getPDF={getPDF}
      />

    </>
  );
};

export default ModalPrintView;
