export const yesOrNotSelectPT = [
	{ label: "Sim", value: true },
	{ label: "Não", value: false }
];

export const paymentTypePT = [
	{ label: "Pronto pagamento", value: 1 },
	{ label: "Financiamento Total", value: 2 },
	{ label: "Financiamento Parcial", value: 3 }
];

export const transportPricesPT = (carPricePercentage) => [
	{ label: "Até 50km ida e volta", value: 1, price: 50 },
	{ label: "Até 100km ida e volta", value: 2, price: 100 },
	{ label: "Até 200km ida e volta", value: 3, price: 150 },
	{ label: "Até 350km ida e volta", value: 4, price: 200 },
	{ label: "Mais de 350km ida e volta", value: 5, price: 300 },
	{ label: "Ilhas (Az./ Mad./P. Santo)", value: 6, price: carPricePercentage}
];
