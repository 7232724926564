import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import moment from "moment";
import { MdClose } from "react-icons/md";

import styles from "./index.module.scss";
import { post } from "@services/axiosCalls";
import { API } from "@services/urlConstants";
import { putStatus } from "@services/Vehicle/vehicle.service";
import { errorToast, successToast } from "@helpers/toastFunctions";
import STATUS_VEHICLE from "@constants/statusVehicle";

const ModalDelivery = ({
	modalDelivery,
	setModalDelivery,
	date,
	recallDocuments,
	setModalUnsigned
}) => {
	const [inProgress, setInProgress] = useState(false);

	const handleAction = async (status) => {
		try {
			setInProgress(true);
			if (modalDelivery.document.url || modalDelivery.document.documentNameCloud) {
				await post(API.ADD_CAR_DELIVERY_DATE, {
					date: moment(date).format("YYYY/MM/DD HH:mm:ss"),
					id: modalDelivery.document.documentId
				});
				await putStatus(modalDelivery.document.vehicle_id_stock, status);

				successToast(<span>Fecha actualizada</span>);
				recallDocuments();
				setModalDelivery(false);
			} else {
				setModalUnsigned(true);
			}
		} catch(error) {
      if (error.response.status === 409) {
       return errorToast(`No es posible realizar la Entrega, el vehículo ya se ha “Retirado” del stock`);
      }
      errorToast(`No es posible realizar la Entrega`)
    }finally {
			setInProgress(false);
		}
	};

	return (
		<>
			<Modal
				size="large"
				centered
				className="modal-lg"
				isOpen={modalDelivery?.type === "today"}
			>
				<ModalBody className="p-4">
					<center>
						<strong>Has marcado el día de HOY como fecha de entrega.</strong>
					</center>
					<br />
					<strong>Retirar Vehículo:</strong> se procederá a la retirada inmediata del vehículo del
					stock (web, portales, etc).
					<br />
					<strong>Programar entrega:</strong> se programará la entrega para hoy, el vehículo
					aparecerá con la entrega "Pte. de confirmación"
				</ModalBody>
				<ModalFooter>
					<Button
						color="primary"
						onClick={() => handleAction(STATUS_VEHICLE.sold)}
						disabled={inProgress}
					>
						Retirar Vehículo
					</Button>
					<Button
						color="secondary"
						onClick={() => handleAction(STATUS_VEHICLE.delivery_pending)}
						disabled={inProgress}
					>
						Programar entrega
					</Button>
				</ModalFooter>
				<div
					className={styles.close}
					onClick={() => setModalDelivery(false)}
				>
					<MdClose
						title="Cerrar"
						size={30}
					/>
				</div>
			</Modal>
			<Modal
				size="large"
				centered
				className="modal-lg"
				isOpen={modalDelivery?.type === "past"}
			>
				<ModalHeader>Retirada de vehículo</ModalHeader>
				<ModalBody className="p-4">
					<center>
						La fecha de entrega es anterior al día actual. Se procederá a la retirada inmediata del
						vehículo del stock (web, portales, etc).
						<br />
						¿Está seguro de que desea retirar el vehículo?
					</center>
				</ModalBody>

				<ModalFooter>
					<Button
						color="primary"
						onClick={() => handleAction(STATUS_VEHICLE.sold)}
						disabled={inProgress}
					>
						Retirar Vehículo
					</Button>
					<Button
						color="secondary"
						onClick={() => setModalDelivery(false)}
					>
						Cancelar
					</Button>
				</ModalFooter>
				<div
					onClick={() => setModalDelivery(false)}
					className={styles.close}
				>
					<MdClose
						title="Cerrar"
						size={30}
					/>
				</div>
			</Modal>
		</>
	);
};

export default ModalDelivery;
