import React, { useState, useRef, useEffect, forwardRef } from "react";

import styles from "@components/AutocompleteEmail/index.module.scss";
import domains from "@constants/Domains";

const EmailAutocomplete = forwardRef(({ onChange, value = "", ...props }, ref) => {
	const [suggestions, setSuggestions] = useState([]);
	const [overlayVisible, setOverlayVisible] = useState(false);
	const [inputValue, setInputValue] = useState(value);

	const wrapperRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				setOverlayVisible(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleChange = (e) => {
		const newValue = e.target.value;
		setInputValue(newValue);
		onChange(newValue);

		const atIndex = newValue.indexOf("@");
		if (atIndex === -1) {
			setSuggestions([]);
			setOverlayVisible(false);
		} else {
			const baseEmail = newValue.slice(0, atIndex);
			const enteredDomain = newValue.slice(atIndex);

			const filteredSuggestions = domains
				.filter((domain) => domain.startsWith(enteredDomain))
				.map((domain) => baseEmail + domain);

			setSuggestions(filteredSuggestions);
			setOverlayVisible(filteredSuggestions.length > 0);
		}
	};

	const handleSuggestionClick = (suggestion) => {
		setInputValue(suggestion);
		onChange(suggestion);
		setSuggestions([]);
		setOverlayVisible(false);
	};

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	return (
		<div
			className={styles.customInputWrapper}
			ref={wrapperRef}
		>
			<input
				{...props}
				ref={ref}
				type="text"
				value={inputValue}
				onChange={handleChange}
			/>
			{overlayVisible && suggestions.length > 0 && (
				<ul className={styles.suggestionsList}>
					{suggestions.map((suggestion, index) => (
						<li
							key={index}
							onClick={() => handleSuggestionClick(suggestion)}
							className={styles.suggestionItem}
						>
							{suggestion}
						</li>
					))}
				</ul>
			)}
		</div>
	);
});

export default EmailAutocomplete;
